export enum LocalStorageKeys {
  SignUpData = "sign_up_registration_data",
  OTPVerificationToken = "otp_verification_token",
  AuthToken = "daktaritok",
  UserData = "daktarius",
}

export enum HealthCareType {
  Professional = 1,
  Facility = 2,
}

export enum ComponentType {
  BeforeLogin = "before_login",
  AfterLogin = "after_login",
  ForBoth = "both",
}

export enum ProfileStatus {
  Pending = "pending",
  InProgress = "in_progress",
  Approve = "approve",
}