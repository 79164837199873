// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { formatListArray } from "../../../../components/src/components/utils/commonFunctions";
import { OptionType } from "../../../../components/src/components/utils/commonTypes";
import { countries, kenyaCounties } from "../../../../components/src/components/utils/countryCounty";
import { CountryData } from "react-phone-input-2";


export interface InitialValues1 {
  shift_title: string;
  facility_shift_role_id: string;
  facility_shift_specialtie_id: string;
  shift_type: string;
  shift_start_date: string;
  shift_end_date: string;
  shift_duration_start: string;
  shift_duration_end: string;
  compensation_type: string;
  rate: string;
  currency: string;
  additional_benefits: string;
};

export interface InitialValues2 {
  facility_name: string;
  location_type_id: string;
  facility_shift_address_id: string;
};

export interface InitialValues3 {
  facility_shift_qualification_id: string;
  facility_shift_experience_level_id: string;
  facility_shift_skill_needed_id: string;
  facility_shift_language_id: string;
  contact_person: string;
  shift_contact_method_id: string;
  contact_email: string;
  phone_number: string;
  dress_code: string;
  shift_description: string;
  safety_requirements: string[];
  shift_photo: string;
  valid_professional_license_or_certification_mandatory: string;
};

export interface FormValues extends InitialValues1, InitialValues2, InitialValues3 { }

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  formValues: FormValues
  professionalTitleList: OptionType[];
  specialityList: OptionType[];
  educationCertificationList: OptionType[];
  licensingAuthorityList: OptionType[];
  telehealthAvailabilityList: OptionType[];
  countryList: OptionType[];
  nationalityList: OptionType[];
  countyList: OptionType[];
  isEdit: boolean;
  countryCode: string;
  phoneFormat: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddEditShiftController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  initialValues1 = {
    shift_title: "",
    facility_shift_role_id: "",
    facility_shift_specialtie_id: "",
    shift_type: "",
    shift_start_date: "",
    shift_end_date: "",
    shift_duration_start: "03 : 24 PM",
    shift_duration_end: "",
    compensation_type: "",
    rate: "",
    currency: "",
    additional_benefits: "",
  };

  initialValues2 = {
    facility_name: "",
    location_type_id: "",
    facility_shift_address_id: "",
  };
  initialValues3 = {
    facility_shift_qualification_id: "",
    facility_shift_experience_level_id: "",
    facility_shift_skill_needed_id: "",
    facility_shift_language_id: "",
    contact_person: "",
    shift_contact_method_id: "",
    contact_email: "",
    phone_number: "",
    dress_code: "",
    shift_description: "",
    safety_requirements: [],
    shift_photo: "",
    valid_professional_license_or_certification_mandatory: "",
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      formValues: {
        ...this.initialValues1,
        ...this.initialValues2,
        ...this.initialValues3,
      },
      professionalTitleList: [],
      specialityList: [],
      educationCertificationList: [],
      licensingAuthorityList: [],
      telehealthAvailabilityList: [],
      countryList: [],
      nationalityList: [],
      countyList: [],
      isEdit: false,
      countryCode: "",
      phoneFormat: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {

    const countryList = formatListArray(countries, "name", "value");
    const nationalityList = formatListArray(countries, "nationality", "value");
    const countyList = formatListArray(kenyaCounties, "name", "value");
    this.setState({ countryList, countyList, nationalityList });
  }


  handleNext = (formValue: Partial<FormValues> | undefined) => {
    const { activeStep } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      formValues: { ...prevState.formValues, ...formValue }
    }));

    if (activeStep < configJSON.headerTextList.length - 1) {
      this.setState((prevState) => ({
        ...prevState,
        activeStep: prevState.activeStep + 1,
      }));
    } else {
      this.handleSubmit(formValue)
    }
  };

  getHeading2 = () => {
    const { activeStep } = this.state;
    return configJSON.headerTextList[activeStep]?.label
  };

  handleBack = (formValue: Partial<FormValues>) => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
      formValues: { ...prevState.formValues, ...formValue }
    }));
  };

  handleSubmit = async (formData: Partial<FormValues> | undefined) => {
    
  };


  handlePhoneChange = (_: string, country: CountryData) => {
      this.setState({
        countryCode: country.dialCode,
        phoneFormat: country.format,
      });
    };
  // Customizable Area End
}
