// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { LoginErrorResponse, LoginFormValues, LoginSuccessResponse } from "./types";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import createRequestMessage from "../../../../blocks/utilities/src/create-request-message";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { afterLoginPath, createLinkAndRedirect, isLoggedIn, setToken, setUser } from "../../../../components/src/components/utils/commonFunctions";
import { ProfileStatus } from "../../../../components/src/components/utils/enumConstant";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  formikHelpersRef: Partial<FormikHelpers<LoginFormValues>> = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      // token: getToken(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.loginApiCallId) {
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: async () => {
          const results = (responseJson as LoginSuccessResponse)
          const profile_status = responseJson?.data?.attributes.profile_status;
          if(profile_status === ProfileStatus.InProgress) { 
            this.props.navigation.navigate(configJSON.successPageRoute);
          } else {
            setUser(results);
            setToken(results.meta.token)
            const path = `/${afterLoginPath()}`
            createLinkAndRedirect(path)
          }
        },
        onFail: () => {
          if (this.formikHelpersRef.setErrors) this.formikHelpersRef.setErrors((responseJson as LoginErrorResponse).errors[0])
          if (this.formikHelpersRef.setSubmitting) this.formikHelpersRef.setSubmitting(false)
        }
      })
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    isLoggedIn(this.props)
  }

  initialValues = {
    email: "",
    password: ""
  } as LoginFormValues

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      // .matches(/[A-Z]/, "Password does not match the requirements")
      // .matches(/[@$!%*?&]/, "Password does not match the requirements")
      // .matches(/\d/, "Password does not match the requirements")
      // .min(8, "Password does not match the requirements")
      .required("Password is required"),
  });

  handleSubmit = (values: LoginFormValues, { setErrors, setSubmitting }: FormikHelpers<LoginFormValues>) => {

    this.formikHelpersRef = {
      setErrors,
      setSubmitting
    }

    const payload = {
      data: {
        type: "email_account",
        attributes: {
          email: values.email,
          password: values.password,
        },
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.loginApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.loginApiEndPoint,
      method: "POST",
      body: JSON.stringify(payload),
    });

  }

  redirectTo = (routeName: string) => {
    this.props.navigation.navigate(routeName);
  }

  // Customizable Area End
}
