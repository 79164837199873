// Customizable Area Start
import React from "react";
import {  ThemeProvider } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
} from "@mui/material";
import DashboardController, 
{ Props } from "./DashboardLayoutController.web";
import ContactUsModal from "../../../../blocks/contactus/src/components/ContactUsModal.web";
import AppTopHeader from "../../../../components/src/components/AppTopHeader.web";
import { DashboardTheme } from "../../../../components/src/components/GlobalData";

// Customizable Area End

export default class DashboardLayout extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  container = window !== undefined ? () => window.document.body : undefined;
  // Customizable Area End

  render() {
    const isCustomisableUserProfileScreen = this.props.navigation?.state?.routeName == "/CustomisableUserProfiles";
    return (
      // Customizable Area Start
      <ThemeProvider theme={DashboardTheme}>
        <CssBaseline />
        <AppTopHeader
          onProfileClick={() => this.redirectTo("CustomisableUserProfiles")}
          mobileOpen={this.state.settingSideBarDrawer}
          handleDrawerToggle={this.handleSettingsDrawerToggle}
          handleOpenContactUs={this.handleOpenContactUs}
          navigation={this.props.navigation}
          filtredServicesList={this.props.filtredServicesList}
          selectedService={this.props.selectedService}
          handleSetService={this.props.handleSetService}
        />
       
        <Box component="main" sx={{ pt: isCustomisableUserProfileScreen ? "88px" : "0px", width: { sm: (isCustomisableUserProfileScreen && window.innerWidth > 900) ?`calc(100% - 385px)` : "100%" } , 
        marginLeft: { sm: isCustomisableUserProfileScreen && window.innerWidth >  900 ? '385px' : "0px"}}}>
          {this.props.children}
        </Box>
        <ContactUsModal
          {...this.props}
          open={this.state.openContactUs}
          handleClose={this.handleCloseContactUs}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


// Customizable Area End
