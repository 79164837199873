export const logo = require("../assets/logo.png");
export const logo2 = require("../assets/logo.svg");
export const userGroupPhoto = require("../assets/success_group_photo.png");
export const uploadIcon = require("../assets/upload_icon.svg");
export const DotIcon = require("../assets/dot_icon.svg").default;

const assets = {
    logo,
    logo2,
    userGroupPhoto,
    uploadIcon
  };
  
export default assets;

