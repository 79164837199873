// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { ProfessionalSettingSchema1, ProfessionalSettingSchema2, ProfessionalSettingSchema3, ProfessionalSettingSchema4 } from "../../../../components/src/components/utils/validationSchema";
import { apiCall, convertAllFormData, createLinkAndRedirect, formatListArray, handleApiError, handleClearStorage, handleClearStorageAndRedirectToPage, handleExpiredToken } from "../../../../components/src/components/utils/commonFunctions";
import { ProfileStatus } from "../../../../components/src/components/utils/enumConstant";
import { FormikTouched, FormikErrors } from "formik";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { OptionType } from "../../../../components/src/components/utils/commonTypes";
import { countries, kenyaCounties } from "../../../../components/src/components/utils/countryCounty";


export interface InitialValues1 {
  profile_photo: string;
  full_name: string;
  date_of_birth: string;
  gender: string;
  nationality: string;
  country: string;
  county: string;
  city: string;
  address: string;
};

export interface InitialValues2 {
  professional_title_id: string;
  facility_id: string;
  education_certification_id: string;
  year_of_expirience: string;
  professional_indemnity: string;
  professional_licence_number: string;
  licensing_authority_id: string;
  licence_ex_date: string;
  kra_pin_number: string;
  nssf_number: string;
};

export interface InitialValues3 {
  professional_body_membership: string;
  shif_number: string;
};

export interface InitialValues4 {
  current_availability_status: string;
  shift_type_full_time: boolean;
  shift_type_part_time: boolean;
  shift_type_contract: boolean;
  county_preferences: string[];
  telehealth_availability_id: string;
  desired_hourly_shift_rate: string;
};

export interface FormValues extends InitialValues1, InitialValues2, InitialValues3, InitialValues4 { }

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  profilePicture: string;
  formValues: FormValues
  professionalTitleList: OptionType[];
  specialityList: OptionType[];
  educationCertificationList: OptionType[];
  licensingAuthorityList: OptionType[];
  telehealthAvailabilityList: OptionType[];
  countryList: OptionType[];
  nationalityList: OptionType[];
  countyList: OptionType[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfessionalSettingProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  professionalTitleApiCallId: string = "";
  specialityApiCallId: string = "";
  certificationsApiCallId: string = "";
  licensingAuthorityApiCallId: string = "";
  telehealthavailabilityApiCallId: string = "";
  updateProfileApiCallId: string = "";

  initialValues1 = {
    profile_photo: "",
    full_name: "",
    date_of_birth: "",
    gender: "",
    nationality: "",
    country: "",
    county: "",
    city: "",
    address: "",
  };

  initialValues2 = {
    professional_title_id: "",
    facility_id: "",
    education_certification_id: "",
    year_of_expirience: "",
    professional_indemnity: "",
    professional_licence_number: "",
    licensing_authority_id: "",
    licence_ex_date: "",
    kra_pin_number: "",
    nssf_number: "",
  };
  initialValues3 = {
    professional_body_membership: "",
    shif_number: "",
  };
  initialValues4 = {
    current_availability_status: "",
    shift_type_full_time: false,
    shift_type_part_time: false,
    shift_type_contract: false,

    county_preferences: [],
    telehealth_availability_id: "",
    desired_hourly_shift_rate: "",

    work_location_hospitals: false,
    work_location_clinics: false,
    work_location_home_based_care: false,
    work_location_home_telehealth: false,

    shift_type_day: false,
    shift_type_night: false,
    shift_type_rotational: false,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      profilePicture: "",
      formValues: {
        ...this.initialValues1,
        ...this.initialValues2,
        ...this.initialValues3,
        ...this.initialValues4,
      },
      professionalTitleList: [],
      specialityList: [],
      educationCertificationList: [],
      licensingAuthorityList: [],
      telehealthAvailabilityList: [],
      countryList: [],
      nationalityList: [],
      countyList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(response, this.props);
      if (apiRequestCallId && response) {
        if (apiRequestCallId === this.professionalTitleApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(response, "name", "id");
              this.setState({ professionalTitleList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch professional titles");
            }
          })
        } else if (apiRequestCallId === this.specialityApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(response, "name", "id");
              this.setState({ specialityList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch speciality");
            }
          })
        } else if (apiRequestCallId === this.certificationsApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(response, "name", "id");
              this.setState({ educationCertificationList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch certifications");
            }
          })
        } else if (apiRequestCallId === this.licensingAuthorityApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(response, "name", "id");
              this.setState({ licensingAuthorityList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch licensing authority");
            }
          })
        }else if (apiRequestCallId === this.telehealthavailabilityApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(response, "name", "id");
              this.setState({ telehealthAvailabilityList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch telehealth availability");
            }
          })
        } else if (apiRequestCallId === this.updateProfileApiCallId) {
          handleResponseMessage({
            responseJson: response,
            errorJson: null,
            onSuccess: () => {
              handleClearStorage();
              createLinkAndRedirect("/SuccessPage")
            },
            onFail: () => {
              handleApiError(response.errors)
            }
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getProfessionalTitles();
    this.getSpeciality();
    this.getCertifications();
    this.getLicensingAuthority();
    this.getTelehealthAvailability();

    const countryList = formatListArray(countries, "name", "value");
    const nationalityList = formatListArray(countries, "nationality", "value");
    const countyList = formatListArray(kenyaCounties, "name", "value");
    this.setState({ countryList, countyList, nationalityList });
  }

  getValidationSchema = () => {
    if (this.state.activeStep === 0) {
      return ProfessionalSettingSchema1;
    } else if (this.state.activeStep === 1) {
      return ProfessionalSettingSchema2;
    } else if (this.state.activeStep === 2) {
      return ProfessionalSettingSchema3;
    } else {
      return ProfessionalSettingSchema4;
    }
  };

  getProfessionalTitles = async () => {
    this.professionalTitleApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getProfessionalTitlesAPiEndPoint,
      token: true
    });
  }

  getSpeciality = async () => {
    this.specialityApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getSpecialityAPiEndPoint,
      token: true
    });
  }

  getCertifications = async () => {
    this.certificationsApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getCertificationsAPiEndPoint,
      token: true
    });
  }

  getLicensingAuthority = async () => {
    this.licensingAuthorityApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getLicensingAuthorityAPiEndPoint,
      token: true
    });
  }

  getTelehealthAvailability = async () => {
    this.telehealthavailabilityApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getTelehealthAvailabilityAPiEndPoint,
      token: true
    });
  }

  getSteps = () => {
    const steps = [
      {
        label: 'Basic',
        description: 'information'

      },
      {
        label: 'Licensing &',
        description: 'Credentials'

      },
      {
        label: 'Professional Body',
        description: 'Memberships (optional)'

      },
      {
        label: 'Availability & Work',
        description: 'Preferences'

      },
    ];
    return steps;
  }


  handleNext = (formValue: Partial<FormValues> | undefined) => {
    const { activeStep } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      formValues: { ...prevState.formValues, ...formValue }
    }));

    if (activeStep < this.getSteps().length - 1) {
      this.setState((prevState) => ({
        ...prevState,
        activeStep: prevState.activeStep + 1,
      }));
    } else {
      this.handleSubmit(formValue)
    }
  };

  getHeading2 = () => {
    const { activeStep } = this.state;
    return configJSON.pHeader[activeStep]?.label
  };

  handleBack = (formValue: Partial<FormValues>) => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
      formValues: { ...prevState.formValues, ...formValue }
    }));
  };

  handleSubmit = async (formData: Partial<FormValues> | undefined) => {
    this.updateProfileApiCallId = await apiCall({
      method: configJSON.updateFormAPiMethod,
      body: convertAllFormData({ ...formData, profile_status: ProfileStatus.InProgress }, "account"),
      endPoint: configJSON.updateUserDetailsAPiEndPoint,
      token: true,
    });
  };

  handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, setTouched: (touched: FormikTouched<any>, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>) => {
    const file = event.target.files?.[0];
    const name = event.target.name
    const img = new Image();
    setTouched({ [name]: true })
    if (file) {
      let allowFile = (file.size <= (1024 * 1024));
      if (allowFile) {
        const fileType = file.type;
        if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
          setFieldValue([name], file);
          this.setState({
            profilePicture: URL.createObjectURL(file),
          });
        }
      }
    }
  };

  
  handleLogOut = () => {
    handleClearStorageAndRedirectToPage(this.props, "/", "reload")
  };

  // Customizable Area End
}
