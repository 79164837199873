// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box, CssBaseline, Container, Button, Typography, Stepper, Step, StepLabel, Grid, FormControl, FormLabel, RadioGroup, FormGroup } from "@mui/material";
import ProfessionalSettingProfileController, { Props, configJSON } from "./ProfessionalSettingProfileController.web";
import { Field, Form, Formik, FormikProps } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomRadio from "../../../../components/src/components/customFormComponents/CustomRadio.web";
import CustomSelect from "../../../../components/src/components/customFormComponents/CustomSelect.web";
import dayjs from "dayjs";
import CustomCheckbox from "../../../../components/src/components/customFormComponents/CustomCheckBox.web";
import { genderList, yearsOfExperienceList } from "../../../../components/src/components/utils/constant";
import { DotIcon } from "../assets";

const theme = createTheme({
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#27292A",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: 700,
          boxShadow: "none",
          lineHeight: "1.4",
          padding: "10px 18px",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
            color: "#FFF",
            backgroundColor: "#B6D4E3"
          }
        },
        outlined: {
          color: "#2EAB65",
        },
      },
    },
  },
});
// Customizable Area End

export default class ProfessionalSettingProfile extends ProfessionalSettingProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ProfessionalFooter = (formikProps: FormikProps<any>) => {
    const { activeStep } = this.state;
    const stepDetails = configJSON.pHeader[activeStep]
    const { values, isValid, isSubmitting } = formikProps;

    return (
      <FooterBox>
        {stepDetails?.optional && <Box className="last_step_footer">
          <Typography
            className="last_step_footer_text"
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: configJSON.pHeader[activeStep].optionalText }}>
          </Typography>
        </Box>}
        <section className="main_footer_section">
          <Box className="footer_section">
            <Container>
              <Box className="footer_wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                <div style={{ minWidth: "101px" }}>
                  <Button
                    variant="contained"
                    onClick={this.handleBack.bind(this, values)}
                    data-test-id="back_btn"
                    className="stepperButton stapper_back_button"
                    startIcon={<ArrowBackIcon />}
                    style={{ display: activeStep === 0 ? "none" : "" }}
                  >
                    {configJSON.backButton}
                  </Button>
                </div>
                <CustomStepper activeStep={activeStep}>
                  {this.getSteps().map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode; } = {};

                    return (
                      <Step disabled key={label.label} {...stepProps}>
                        <StepLabel {
                          ...labelProps}
                        >
                          {label.label} <br /> {label.description}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </CustomStepper>
                <Button
                  variant="contained"
                  data-test-id="next_btn"
                  type={"submit"}
                  className="stepperButton stapper_continue_button"
                  disabled={(isSubmitting || !isValid) && activeStep !== this.getSteps().length - 1}
                >
                  {(activeStep === this.getSteps().length - 1) ? configJSON.submitButton : configJSON.continueButton}
                </Button>
              </Box>
            </Container>
          </Box>
        </section>
      </FooterBox>
    )
  }

  ProfessionalHeader = () => {
    return (
      <HeaderBox>
        <section className="main_hero_section">
          <nav className="nav_section">
            <Container>
              <Box className="navbar_wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                <img
                  src={require('../../assets/logo.svg').default}
                  alt="branding"
                  className="branding_logo"
                />

                <Box className="center_header_wrapper" sx={{ mb: { xs: 1, md: 2 } }}>
                  <Typography variant="h1" className="center_header_text1">{configJSON.headerLabel1}</Typography>
                  <Box className="center_header_text2">
                    <img src={DotIcon} />
                  </Box>
                  <Typography variant="h1" className="center_header_text3">{this.getHeading2()}</Typography>

                </Box>
                <Button
                  className="logout_label"
                  data-test-id="logout-btn"
                  disableElevation
                  onClick={this.handleLogOut}
                >
                  {configJSON.logOutLabel}
                </Button>
              </Box>
            </Container>
          </nav>
        </section>
      </HeaderBox>
    )
  }

  ProfessionalMainForm = (formikProps: FormikProps<any>) => {
    const { activeStep } = this.state

    if (activeStep === 0) {
      return this.stepForm1(formikProps);
    } else if (activeStep === 1) {
      return this.stepForm2();
    } else if (activeStep === 2) {
      return this.stepForm3();
    } else if (activeStep === 3) {
      return this.stepForm4(formikProps);
    }

  }

  stepForm1 = (formikProps: any) => {
    const { profilePicture } = this.state;
    const { values, setFieldValue, errors, touched, setTouched } = formikProps;
    const { countyList, countryList, nationalityList } = this.state
    const nonKenya = values.country !== 'Kenya'

    return (
      <BoxWrapper>
        <Grid container columnSpacing={3}>

          <Grid item xs={12}>
            <UploadContainer>
              <label>
                <Box className={"UploadBox"}>
                  <Box className="uploadImage">
                    <input
                      key={profilePicture}
                      className="HiddenInput"
                      onChange={(event) => { this.handleProfileChange(event, setFieldValue, setTouched) }}
                      type="file"
                      accept="image/*"
                      name="profile_photo"
                      id="profile_photo"
                    />
                    <Box className="innerContent">
                      {profilePicture ? (
                        <img
                          src={profilePicture}
                          alt="Profile Picture"
                          className="filePreview"
                          data-test-id="profilePictureImage"
                        />
                      ) : (
                        <React.Fragment>
                          <img
                            src={require('../../assets/upload_icon.svg').default}
                            alt="defultProfileIcon"
                            className="uploadImage"
                            data-test-id="uploadImg"
                          />
                          <Typography
                            variant="body1"
                            className="uploadText"
                            data-test-id="uploadImageText"
                          >
                            {configJSON.uploadImageText}
                          </Typography>
                        </React.Fragment>
                      )}
                    </Box>
                  </Box>
                </Box>
              </label>
              <Box>
                {(touched.profile_photo && errors.profile_photo) ? (
                  <Typography variant="body2" style={{ fontWeight: 400, color: "#DC2626" }}>
                    <span>{errors["profile_photo"]}</span> <br />
                    Profile photo size should be 300x300px <br />
                    and no larger than 1 MB.
                  </Typography>
                ) :
                  (
                    <Typography variant="body2" style={{ fontWeight: 400 }}>
                      Profile photo size should be 300x300px <br />
                      and no larger than 1 MB.
                    </Typography>
                  )}
              </Box>
            </UploadContainer>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInput
              label={configJSON.fullNameLabel}
              name={configJSON.fullName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={configJSON.dateOfBirth}
              label={configJSON.dateOfBirthLabel}
              type="date"
              fullWidth
              dateProps={
                {
                  withIcon: false,
                  minDate: dayjs().subtract(18, 'years'),
                  maxDate: dayjs(),
                  format: configJSON.dateFormate,
                }
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.gender}
              label={configJSON.genderLabel}
              fullWidth
              options={genderList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.nationality}
              label={configJSON.nationalityLabel}
              fullWidth
              options={nationalityList}
              isAutocomplete={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              fullWidth
              name={configJSON.country}
              label={configJSON.countryLabel}
              options={countryList}
              isAutocomplete={true}
              onChange={(event: any) => {
                const country = event?.value;
                if (country !== 'Kenya') {
                  setFieldValue('county', '');
                  setFieldValue('county_preferences', []);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  isAutocomplete={true}
                  name={configJSON.county}
                  label={configJSON.countyLabel}
                  options={countyList}
                  disabled={nonKenya}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  name={configJSON.city}
                  label={configJSON.cityLabel}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomInput
              fullWidth
              name={configJSON.address}
              label={configJSON.addressLabel}
            />
          </Grid>
        </Grid>
      </BoxWrapper >
    )
  }

  stepForm2 = () => {
    const { professionalTitleList, specialityList, educationCertificationList, licensingAuthorityList } = this.state;
    return (
      <BoxWrapper sx={{ alignContent: { sx: "start", md: "center" } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={"professional_title_id"}
              label={"Professional title"}
              fullWidth
              options={professionalTitleList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={"speciality_id"}
              label={"Speciality"}
              fullWidth
              optional
              options={specialityList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={"education_certification_id"}
              label={"Education / Certifications"}
              fullWidth
              options={educationCertificationList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={"year_of_expirience"}
              label={"Years of experience"}
              fullWidth
              options={yearsOfExperienceList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"professional_indemnity"}
              label={"Professional indemnity"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"professional_licence_number"}
              label={"Professional License number"}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={"licensing_authority_id"}
              label={"Licensing authority"}
              fullWidth
              options={licensingAuthorityList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"licence_ex_date"}
              label={"License expiry date"}
              type="date"
              fullWidth
              dateProps={
                {
                  withIcon: false,
                  format: configJSON.dateFormate,
                  minDate: dayjs(),
                }
              }
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} sx={{ mt: { sx: 0, md: 3 } }}>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"kra_pin_number"}
              label={"KRA PIN Number"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"nssf_number"}
              label={"NSSF Number"}
              fullWidth
            />
          </Grid>
        </Grid>
      </BoxWrapper>
    )
  }

  stepForm3 = () => {
    return (
      <BoxWrapper sx={{ alignContent: { sx: "start", md: "center" } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"professional_body_membership"}
              label={"Professional body memberships"}
              fullWidth
              optional
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={"shif_number"}
              label={"SHIF number"}
              fullWidth
              optional
            />
          </Grid>
        </Grid>
      </BoxWrapper>
    )
  }

  stepForm4 = (formikProps: any) => {
    const { countyList, telehealthAvailabilityList } = this.state
    const { values, errors, touched } = formikProps;
    const nonKenya = values.country !== 'Kenya'
    return (
      <BoxWrapper sx={{ alignContent: { sx: "start", md: "center" } }}>
        <Box className="form_outer_box">
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <RadioFormControl>
                  <Box className="label_with_error">
                    <FormLabel id={"current_availability_status"}>{"Current availability status"}</FormLabel>
                    <Typography className="error_label">
                      {touched.current_availability_status && errors.current_availability_status}
                    </Typography>
                  </Box>
                  <Field name={"current_availability_status"}>
                    {({ field, form }: any) => (
                      <RadioGroup
                        {...field}
                        aria-labelledby={"current_availability_status"}
                        name={"current_availability_status"}
                        sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", sm: "row" } }}
                        onChange={(event) => {
                          form.setFieldValue("current_availability_status", event.target.value);
                        }}
                      >
                        {configJSON.current_availability_statusList.map((option: { label: string, value: string | number, style: React.CSSProperties | undefined }) => (
                          <Box style={{ ...option.style }} >
                            <CustomRadio key={option.value} value={option.value} label={option.label} />
                          </Box>
                        ))}
                      </RadioGroup>
                    )}
                  </Field>
                </RadioFormControl>
              </Grid>

              <Grid item xs={12}>
                <RadioFormControl>
                  <FormLabel id={"shift_type_availability"}>
                    Shift type availability
                  </FormLabel>
                  <FormGroup sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap" }}>
                    {configJSON.shift_type_availabilityList.map((option: { label: string, value: string | number, style: React.CSSProperties | undefined }) => (
                      <Field name={option.value}>
                        {({ field, form }: any) => (
                          <Box style={{ ...option.style }} >
                            <CustomCheckbox
                              {...field}
                              name={option.value}
                              value={option.value}
                              label={option.label}
                              checked={field.value}
                              onChange={(event) => {
                                form.setFieldValue(field.name, event.target.checked);
                              }}
                            />
                          </Box>
                        )}
                      </Field>
                    ))}
                  </FormGroup>
                </RadioFormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="form_outer_box">
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>

              <Grid item xs={12}>
                <RadioFormControl>
                  <Box className="label_with_error">
                    <FormLabel id={"Preferred_work_setting"}>
                      Preferred work settings
                    </FormLabel>
                    <Typography className="error_label">
                      {touched.work_location_hospitals && errors.work_location_hospitals}
                    </Typography>
                  </Box>

                  <FormGroup sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap" }}>
                    {configJSON.Preferred_work_settingsList.map((option: { label: string, value: string | number, style: React.CSSProperties | undefined }) => (
                      <Field name={option.value}>
                        {({ field, form }: any) => (
                          <Box style={{ ...option.style }} >
                            <CustomCheckbox
                              {...field}
                              key={option.value}
                              value={option.value}
                              name={option.value}
                              label={option.label}
                              checked={field.value}
                              onChange={(event) => {
                                form.setFieldValue(field.name, event.target.checked);
                              }}
                            />
                          </Box>
                        )}
                      </Field>
                    ))}
                  </FormGroup>
                </RadioFormControl>

              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomSelect
                  name={"county_preferences"}
                  label={"County Preferences"}
                  fullWidth
                  options={countyList}
                  isAutocomplete={true}
                  isMulti={true}
                  limitTags={2}
                  disabled={nonKenya}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  name={"telehealth_availability_id"}
                  label={"Telehealth availability"}
                  fullWidth
                  options={telehealthAvailabilityList}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="form_outer_box">
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>

              <Grid item xs={12}>
                <RadioFormControl>
                  <Box className="label_with_error">
                    <FormLabel id={"Preferred_work_setting"}>
                      Shift type preference
                    </FormLabel>
                    <Typography className="error_label">
                      {touched.shift_type_day && errors.shift_type_day}
                    </Typography>
                  </Box>

                  <FormGroup sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap" }}>
                    {configJSON.shift_type_preferenceList.map((option: { label: string, value: string | number, style: React.CSSProperties | undefined }) => (
                      <Field name={option.value}>
                        {({ field, form }: any) => (
                          <Box style={{ ...option.style }} >
                            <CustomCheckbox
                              {...field}
                              key={option.value}
                              value={option.value}
                              label={option.label}
                              checked={field.value}
                              name={option.value}
                              onChange={(event) => {
                                form.setFieldValue(field.name, event.target.checked);
                              }}
                            />
                          </Box>
                        )}
                      </Field>
                    ))}
                  </FormGroup>
                </RadioFormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomInput
                  name={"desired_hourly_shift_rate"}
                  label={"Desired Hourly/Shift Rate"}
                  fullWidth
                  optional
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BoxWrapper>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Formik
          initialValues={this.state.formValues}
          onSubmit={this.handleNext}
          validationSchema={this.getValidationSchema()}
          data-test-id="formik"
          validateOnMount
          enableReinitialize
          validateOnBlur={true}
          key={this.state.activeStep}
        >
          {(formikProps) => (
            <StyledForm>
              {this.ProfessionalHeader()}
              {this.ProfessionalMainForm(formikProps)}
              {this.ProfessionalFooter(formikProps)}
            </StyledForm>
          )}
        </Formik>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledForm = styled(Form)({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  flexDirection: "column",
  backgroundColor: "#F1F4F5",
  "& .label_with_error": {
    display: "flex",
    justifyContent: "space-between",
    "& .error_label": {
      color: "#DC2626",
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
    }
  }
});

const HeaderBox = styled(Box)({
  overflowY: "auto",
  "& .main_hero_section": {
    position: "relative",
    overflow: "hidden",
    "& .nav_section": {
      backgroundColor: "#D1E1E9",
      "& .navbar_wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .branding_logo": {
          width: "83px",
          height: "52px",
          aspectRatio: "1.58",
        },
        "& .center_header_wrapper": {
          alignItems: "center",
          display: "inline-flex",
          gap: "12px",
          margin: 0,
          "& .center_header_text1": {
            fontSize: "28px",
            lineHeight: "36px",
          },
          "& .center_header_text2": {
            width: "21px",
            textAlign: "center",
          },
          "& .center_header_text3": {
            fontWeight: 400,
          },
        },
        "& .logout_label": {
          color: "#27292A",
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "16px",
          cursor: "pointer",
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
    "& .content_wrapper": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& .hero_title": {
        maxWidth: "580px",
        textWrap: "wrap",
        textAlign: "center",
        "& .branding": {
          fontWeight: 800,
          color: "#2EAB65",
        },
      },
      "& .hero_tagline": {
        textAlign: "center",
        maxWidth: "625px",
        textWrap: "wrap",
      },
    },
  },
});

const FooterBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  "& .last_step_footer": {
    backgroundColor: "#EFFDFF",
    borderTop: "1px solid #E9EDF4",
    padding: "20px",
    "& .last_step_footer_text": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#73B2D1",
      gap: "5px",
      "& span": {
        fontWeight: 800,
      },
    },
  },
  "& .main_footer_section": {
    position: "relative",
    overflow: "hidden",
    "& .footer_section": {
      "& .footer_wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "28px 0",
        gap: "32px",
        flexWrap: "wrap",
        "& .stapper_back_button": {
          color: "#27292A",
          backgroundColor: "#F1F4F5",
          border: "1px solid #E9EDF4",
        },
        "& .stepperButton": {
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "24px",
          boxShadow: "none",
        },
        "& .stapper_continue_button": {
          minWidth: "110px",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
          }
        },
      },
    },
  },
});

const CustomStepper = styled(Stepper)({
  gap: "42px",
  flexWrap: "wrap",
  maxWidth: "779px",
  "@media (max-width: 768px)": {
    gap: "20px",
    justifyContent: "space-between",
    flex: 1,
  },

  "& .MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStep-root": {
    padding: "0px",
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "16px",
    }
  },
  "& .MuiStepIcon-root": {
    height: "32px",
    width: "32px",
    color: "#CDD7E6",
    "& .MuiStepIcon-text": {
      fill: "#A9B1BC",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    "&.Mui-active": {
      color: "transparent",
      border: "1px solid #27292A",
      borderRadius: "50%",
      "& .MuiStepIcon-text": {
        fill: "#27292A",
      },
    },
    "&.Mui-completed": {
      color: "#73B2D1",
    },
    "& .MuiStepLabel-label": {
      fontWeight: 400,
    }
  },
  "& .MuiStepLabel-label": {
    "&.Mui-active": {
      fontWeight: 400,
    },
  },
});

const BoxWrapper = styled(Container)({
  flex: 1,
  overflowY: "auto",
  maxWidth: "784px !important",
  paddingTop: "64px",
  paddingBottom: "10px",

  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0px",
    height: "0px",
    display: "none",
  },
  "& .form_outer_box": {
    padding: "8px",
    marginTop: "16px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    "& .user_details_box": {
      backgroundColor: "#F1F4F5",
      borderRadius: "8px 8px 0px 0px",
      padding: "16px 12px 20px",
      marginBottom: "12px",
    },
    "& .form_inner_box": {
      padding: "12px 16px",
      "& .safe_care_certi": {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "15px",
        flexWrap: "wrap",
        "& .MuiFormGroup-root": {
          margin: "0px",
          "& .MuiFormControlLabel-root": {
            height: "20px",
          }
        }
      }
    }
  }
});

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "24px",
  marginBottom: "32px",
  "& .UploadBox": {
    width: "132px",
    height: "132px",
    borderRadius: "50%",
    border: "2px dashed #2EAB65",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#E4FAEE",
    "& .uploadImage": {
      placeSelf: "center",
      "& .innerContent": {
        display: "flex",
        flexDirection: "column",
        "& .filePreview": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        "& .uploadText": {
          marginTop: "8px",
          fontSize: "14px",
          color: "#27292A",
          lineHeight: "21px",
          fontWeight: 400,
        },
      },
      "& .MuiFormControl-root": {
        width: "100%",
        zIndex: "100",
        height: "100%",
        "& .MuiInputBase-formControl": {
          borderRadius: "50%",
          border: "none",
          height: "100%",
          width: "100%",
          "& fieldset": {
            display: "none",
          },
          "& input": {
            position: "relative",
            padding: "0",
            opacity: "0",
            zIndex: "99",
            width: "100%",
            height: "100%",
            cursor: "pointer",
          },
        },
      },
    },
  },
  "& .HiddenInput ": {
    display: "none",
  }
}));

const RadioFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiFormLabel-root": {
    color: "#27292A !important",
    fontWeight: 700,
  },
  "& .MuiFormGroup-root	": {
  },
  "& .primaryContactType_number": {
    flex: 1
  },
});
// Customizable Area End
