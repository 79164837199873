import React, { Component } from "react";
import { Field, FieldProps } from "formik";
import { Box, FormControl, FormHelperText, styled, Switch, SwitchProps } from "@mui/material";

type CustomSwitchProps = SwitchProps & {
  name: string;
  onChange?: (event: any) => void;
};

class CustomSwitch extends Component<CustomSwitchProps> {
  renderSwitchField = ({ field, meta }: FieldProps) => {
    const { onChange, ...rest } = this.props;

    return (
      <SwitchStyleWrapper>
        <FormControl error={Boolean(meta.touched && meta.error)}>
          <StyledSwitch {...field} {...rest} checked={field.value} onChange={(event) => field.onChange(event)}/>
          <FormHelperText>{meta.touched && meta.error ? meta.error : ""}</FormHelperText>
        </FormControl>
      </SwitchStyleWrapper>
    );
  };

  render() {
    const { name } = this.props;
    return <Field name={name} type="checkbox">{(fieldProps: any) => this.renderSwitchField(fieldProps)}</Field>;
  }
}

// Styled switch component
const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#73B2D1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SwitchStyleWrapper = styled(Box)({
  "& .MuiFormControl-root": {
    display: "flex",
    alignItems: "center",
    //minHeight: "58px",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "0",
    marginTop: "4px",
    color: "#d32f2f",
  },
});

export default CustomSwitch;
