// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import assets from "../assets";
import SuccessPageController, { Props, configJSON } from "./SuccessPageController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
    background: {
      default: "#D1E1E9"
    }
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "16px",
      color: "#27292A",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "800",
          boxShadow: "none",
          padding: "10px 14px",
          lineHeight: "1.4",
          maxWidth: "453px",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
          },
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
            color: "#fff",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class SuccessPage extends SuccessPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper maxWidth="sm">
          <Box data-test-id="logo"
            component="img"
            src={require('../../assets/logo.svg').default}
            alt="logo"
          />
          <Box component="img" className="main__image" src={assets.userGroupPhoto} alt="group" sx={{ mt: 4, mb: 4 }} />

          <Typography variant="h1" align="center" sx={{ mt: 1, mb: 1 }} dangerouslySetInnerHTML={{ __html: configJSON.successPageTitle }}></Typography>
          <Typography align="center" dangerouslySetInnerHTML={{ __html: configJSON.successPageDesc }}></Typography>

          <Button sx={{ mt: 2.5 }} variant="contained" fullWidth onClick={() => this.handleLogout()}>{configJSON.successBtnLabel}</Button>
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  minHeight: "fit-content",
  padding: "20px",

  "@media (max-width: 1200px)": {
    "& .main__image": {
      width: "380px",
    },
  },
  "@media (max-width: 600px)": {
    "& .main__image": {
      width: "300px"
    },
  }
});
// Customizable Area End
