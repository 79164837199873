// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box, CssBaseline, Container, Button, Typography, Stepper, Step, StepLabel, Grid, FormControl, FormLabel, RadioGroup } from "@mui/material";
import FacilitySettingProfileController, { Props, configJSON } from "./FacilitySettingProfileController.web";
import { Field, Form, Formik, FormikProps } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomRadio from "../../../../components/src/components/customFormComponents/CustomRadio.web";
import CustomSelect from "../../../../components/src/components/customFormComponents/CustomSelect.web";
import { facilityLevelRatingList, facilitySafeCareCertiList } from "../../../../components/src/components/utils/constant";
import { DotIcon } from "../assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          padding: "10px 18px",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
            color: "#FFF",
            backgroundColor: "#B6D4E3"
          }
        }
      },
    },
  },
});
// Customizable Area End

export default class FacilitySettingProfile extends FacilitySettingProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <HeaderBox>
        <section className="main__hero__section">
          <nav className="nav__section">
            <Container>
              <Box className="navbar__wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                <img
                  className="branding__logo"
                  src={require('../../assets/logo.svg').default}
                  alt="branding"
                />
                <Box className="center_header_wrapper" sx={{ mb: { xs: 1, md: 2 } }}>
                  <Typography variant="h1" className="center_header_text1">{configJSON.headerLabel1}</Typography>
                  <Box className="center_header_text2">
                    <img src={DotIcon} />
                  </Box>
                  <Typography variant="h1" className="center_header_text3">{this.getFacilityHeading2()}</Typography>

                </Box>
                <Button
                  className="logout__label"
                  data-test-id="logout-btn"
                  disableElevation
                  onClick={this.handleLogOut}
                >
                  {configJSON.logOutLabel}
                </Button>
              </Box>
            </Container>
          </nav>
        </section>
      </HeaderBox>
    )
  }

  renderFooter = (formikProps: FormikProps<any>) => {
    const { values, isValid, isSubmitting } = formikProps;
    const { activeStep } = this.state;

    return (
      <FooterBox>
        {this.isLastStep() && <Box className="last_step_footer">
          <Typography variant="subtitle2" className="last_step_footer_text">
            We are almost finished. You
            <span style={{ fontWeight: 800 }}> can skip</span>
            this step and click Submit.
          </Typography>
        </Box>}
        <section className="main__footer__section">
          <Box className="footer__section">
            <Container>
              <Box className="footer__wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                <div style={{ minWidth: "101px" }}>
                  <Button
                    onClick={this.handleBack.bind(this, values)}
                    variant="contained"
                    data-test-id="back_btn"
                    className="stepperButton stapper_back_button"
                    startIcon={<ArrowBackIcon />}
                    style={{ display: activeStep === 0 ? "none" : "" }}
                  >
                    {configJSON.backButton}
                  </Button>
                </div>
                <CustomStepper activeStep={activeStep}>
                  {this.getSteps().map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};

                    return (
                      <Step disabled key={label.label} {...stepProps}>
                        <StepLabel {
                          ...labelProps}
                        >
                          {label.label} <br /> {label.description}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </CustomStepper>
                <Button
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                  type={"submit"}
                  data-test-id="next_btn"
                  className="stepperButton stapper_continue_button"

                >
                  {(activeStep === this.getSteps().length - 1) ? configJSON.submitButton : configJSON.continueButton}
                </Button>
              </Box>
            </Container>
          </Box>
        </section>
      </FooterBox>
    )
  }

  renderMainForm = (formikProps: FormikProps<any>) => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      return this.facilityForm1();
    } else if (activeStep === 1) {
      return this.facilityForm2(formikProps);
    } else if (activeStep === 2) {
      return this.facilityForm3();
    }

  }

  facilityForm1 = () => {
    const { professionalTitleList, facilityTypeList, userData } = this.state;
    return (
      <BoxWrapper>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomInput
              label={configJSON.facilityNameLabel}
              name={configJSON.facilityName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>

            <Grid container columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={configJSON.facilityTypeLabel}
                  name={configJSON.facilityType}
                  fullWidth
                  options={facilityTypeList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={configJSON.facilityLevelRatingLabel}
                  name={configJSON.facilityLevelRating}
                  fullWidth
                  options={facilityLevelRatingList}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInput
              label={configJSON.facilityKraNumberLable}
              name={configJSON.facilityKraNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              label={configJSON.facilityKmdpCodeLabel}
              name={configJSON.facilityKmdpCode}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box className="form_outer_box">
          <Box className="user_details_box">
            <Grid container columnSpacing={3}>

              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{configJSON.primaryContactTitle}</Typography>

              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">{userData?.email}</Typography>
                <Typography variant="h6">{`+${userData?.country_code} ${userData?.phone_number}`}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>

              <Grid item xs={12} sm={6}>
                <CustomInput
                  label={configJSON.fullNameLabel}
                  name={configJSON.fullName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={configJSON.professionalTitleLabel}
                  name={configJSON.professionalTitle}
                  fullWidth
                  options={professionalTitleList}
                />
              </Grid>

              <Grid item xs={12}>
                <RadioFormControl>
                  <FormLabel id={configJSON.primaryContactType}>{configJSON.primaryContactTypeLabel}</FormLabel>
                  <Field name={configJSON.primaryContactType}>
                    {({ field, form }: any) => (
                      <RadioGroup
                        {...field}
                        aria-labelledby={configJSON.primaryContactType}
                        name={configJSON.primaryContactType}
                        onChange={(event) => {
                          form.setFieldValue(configJSON.primaryContactType, event.target.value);
                        }}
                      >
                        {configJSON.primaryContactTypeList.map((option: { label: string, value: string | number }) => (
                          <CustomRadio
                            key={option.value}
                            value={option.value}
                            label={option.label}
                          />
                        ))}
                        <div className="primaryContactType_number">
                          <CustomInput
                            name={configJSON.primaryContactId}
                            fullWidth
                          />
                        </div>
                      </RadioGroup>
                    )}
                  </Field>
                </RadioFormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BoxWrapper>
    )
  }

  facilityForm2 = (formikProps: FormikProps<any>) => {
    const { values, setFieldValue } = formikProps;
    const { countyList, countryList } = this.state
    const nonKenya = values.country !== 'Kenya'

    return (
      <BoxWrapper>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <CustomSelect
              name={configJSON.country}
              label={configJSON.countryLabel}
              fullWidth
              options={countryList}
              isAutocomplete={true}
              onChange={(event: any) => {
                const country = event?.value;
                if (country !== 'Kenya') {
                  setFieldValue('county', '');
                }
              }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              name={configJSON.county}
              label={configJSON.countyLabel}
              fullWidth
              options={countyList}
              isAutocomplete={true}
              disabled={nonKenya}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              name={configJSON.city}
              label={configJSON.cityLabel}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInput
              name={configJSON.address}
              label={configJSON.addressLabel}
              fullWidth
            />
          </Grid>
        </Grid>
      </BoxWrapper>
    )
  }

  facilityForm3 = () => {
    return (
      <BoxWrapper>
        <Box className="form_outer_box">
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <RadioFormControl>
                  <FormLabel id={configJSON.accreditationStatus}>{configJSON.accreditationLabel}</FormLabel>
                  <Field name={configJSON.accreditationStatus}>
                    {({ field, form }: any) => (
                      <RadioGroup
                        {...field}
                        onChange={(event) => {
                          form.setFieldValue(configJSON.accreditationStatus, event.target.value);
                        }}
                        aria-labelledby={configJSON.accreditationStatus}
                        name={configJSON.accreditationStatus}
                        sx={{ gap: { xs: 0, sm: "98px" } }}
                      >
                        {configJSON.accreditationStatusList.map((option: { label: string, value: string | number }) => (
                          <CustomRadio key={option.value} value={option.value} label={option.label} />
                        ))}
                      </RadioGroup>
                    )}
                  </Field>
                </RadioFormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="form_outer_box">
          <Box className="form_inner_box">
            <Grid container columnSpacing={3}>
              <Grid item xs={12} sx={{ mb: { xs: 0.5, md: 1 } }}>
                <RadioFormControl className="safe_care_certi">
                  <FormLabel id={configJSON.registerFacilitySafeCareCerti}>{configJSON.registerFacilitySafeCareCertiLabel}</FormLabel>
                  <Field name={configJSON.registerFacilitySafeCareCerti}>
                    {({ field, form }: any) => (
                      <RadioGroup
                        {...field}
                        onChange={(event) => {
                          form.setFieldValue(configJSON.registerFacilitySafeCareCerti, event.target.value);

                          if (event.target.value === configJSON.registerFacilitySafeCareCertiList[1].value) {
                            form.setFieldValue(configJSON.facilitySafeCareCerti, "");
                          }
                        }}
                        aria-labelledby={configJSON.registerFacilitySafeCareCerti}
                        name={configJSON.registerFacilitySafeCareCerti}
                      >
                        {configJSON.registerFacilitySafeCareCertiList.map((option: { label: string, value: string | number }) => (
                          <CustomRadio key={option.value} value={option.value} label={option.label} />
                        ))}
                      </RadioGroup>
                    )}
                  </Field>
                </RadioFormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name={configJSON.registerFacilitySafeCareCerti}>
                  {({ field, form }: any) => (
                    <CustomSelect
                      key={String(new Date())}
                      name={configJSON.facilitySafeCareCerti}
                      label={configJSON.facilitySafeCareCertiLabel}
                      fullWidth
                      options={facilitySafeCareCertiList}
                      disabled={form.values[configJSON.registerFacilitySafeCareCerti] ? form.values[configJSON.registerFacilitySafeCareCerti] === configJSON.registerFacilitySafeCareCertiList[1].value : true}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BoxWrapper>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Formik
          data-test-id="formik"
          initialValues={this.state.formValues}
          onSubmit={this.handleNext}
          validateOnMount
          validationSchema={this.getValidationSchema()}
          enableReinitialize
          validateOnBlur={true}
          key={this.state.activeStep}
        >
          {(formikProps) => (
            <StyledForm>
              {this.renderHeader()}
              {this.renderMainForm(formikProps)}
              {this.renderFooter(formikProps)}
            </StyledForm>
          )}
        </Formik>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledForm = styled(Form)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100%",
  backgroundColor: "#F1F4F5",
});

const FooterBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  "& .last_step_footer": {
    padding: "20px",
    backgroundColor: "#EFFDFF",
    borderTop: "1px solid #E9EDF4",
    "& .last_step_footer_text": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
      color: "#73B2D1",
    },
  },
  "& .main__footer__section": {
    position: "relative",
    overflow: "hidden",
    "& .footer__section": {
      "& .footer__wrapper": {
        padding: "28px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "32px",
        "& .stapper_back_button": {
          color: "#27292A",
          backgroundColor: "#F1F4F5",
          border: "1px solid #E9EDF4",
        },
        "& .stepperButton": {
          boxShadow: "none",
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "24px",
        },
        "& .stapper_continue_button": {
          minWidth: "110px",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
          }
        },
      },
    },
  },
});

const HeaderBox = styled(Box)({
  overflowY: "auto",
  "& .main__hero__section": {
    overflow: "hidden",
    position: "relative",
    "& .nav__section": {
      backgroundColor: "#D1E1E9",
      "& .navbar__wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .branding__logo": {
          width: "83px",
          height: "52px",
          aspectRatio: "1.58",
        },
        "& .center_header_wrapper": {
          margin: 0,
          alignItems: "center",
          display: "inline-flex",
          gap: "12px",
          "& .center_header_text1": {
            fontSize: "28px",
            lineHeight: "36px",
          },
          "& .center_header_text2": {
            width: "21px",
            textAlign: "center",
          },
          "& .center_header_text3": {
            fontWeight: 400,
          },
        },
        "& .logout__label": {
          fontSize: "16px",
          lineHeight: "16px",
          fontWeight: 800,
          color: "#27292A",
          cursor: "pointer",
          boxShadow: "none",
          textTransform: "none",
        },
      },
    },
    "& .content__wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .hero__title": {
        textWrap: "wrap",
        textAlign: "center",
        maxWidth: "580px",
        "& .branding": {
          fontWeight: 800,
          color: "#2EAB65",
        },
      },
      "& .hero__tagline": {
        textAlign: "center",
        textWrap: "wrap",
        maxWidth: "625px",
      },
    },
  },
});

const CustomStepper = styled(Stepper)({
  maxWidth: "779px",
  gap: "112px",
  flexWrap: "wrap",
  "@media (max-width: 768px)": {
    flex: 1,
    gap: "20px",
    justifyContent: "space-between",
  },

  "& .MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStep-root": {
    padding: "0px",
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "16px",
    }
  },
  "& .MuiStepIcon-root": {
    color: "#CDD7E6",
    height: "32px",
    width: "32px",
    "& .MuiStepIcon-text": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      fill: "#A9B1BC",
    },
    "&.Mui-active": {
      border: "1px solid #27292A",
      borderRadius: "50%",
      color: "transparent",
      "& .MuiStepIcon-text": {
        fill: "#27292A",
      },
    },
    "&.Mui-completed": {
      color: "#73B2D1",
    },
    "& .MuiStepLabel-label": {
      fontWeight: 400,
    }
  },
  "& .MuiStepLabel-label": {
    "&.Mui-active": {
      fontWeight: 400,
    },
  },
});

const BoxWrapper = styled(Container)({
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0px",
    height: "0px",
    display: "none",
  },

  flex: 1,
  maxWidth: "784px !important",
  paddingTop: "64px",
  paddingBottom: "64px",
  overflowY: "auto",
  "& .form_outer_box": {
    padding: "8px",
    marginTop: "16px",
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    "& .user_details_box": {
      borderRadius: "8px 8px 0px 0px",
      padding: "16px 12px 20px",
      marginBottom: "12px",
      backgroundColor: "#F1F4F5",
    },
    "& .form_inner_box": {
      padding: "12px 16px",
      "& .safe_care_certi": {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "15px",
        "& .MuiFormGroup-root": {
          margin: "0px",
          "& .MuiFormControlLabel-root": {
            height: "20px",
          }
        }
      }
    }
  }
});

const RadioFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiFormGroup-root	": {
    flexDirection: "row",
    marginTop: "8px",
  },
  "& .MuiFormLabel-root": {
    color: "#27292A !important",
    fontWeight: 700,
  },
  "& .primaryContactType_number": {
    flex: 1,
    "& div	": {
      marginBottom: "0px",
      minHeight: "auto",
    },
  }
});
// Customizable Area End
