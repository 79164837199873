import React from "react";
// Customizable Area Start
import { Alert, Avatar, Box, Button, Checkbox, Chip, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { Profile,CancelIcon,CloudUploadIcon, FacilityIcon, CheckboxIcon} from "./assets";
import DashboardLayout from "./components/DashboardLayout.web";
import { CheckBox, Divider} from "react-native-elements";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
      light: "#4DD8B5",
      dark: "#007A5E",
    },
    text: {
      primary: "#27292A",
      secondary: "#27292A",
    },
    background: {
      default: "#F0F4F7",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      color: "#0F172A",
      fontSize: "28px",
    },
    h3: {
      fontSize: "18px",
      color: "#0F172A",
      fontWeight: 700,
    },
    h4: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    h5: {
      fontSize: "14px",
    },
    subtitle1: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      color: "#27292A",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1440px !important",
          paddingRight: "16px",
          paddingLeft: "16px",
          "@media (min-width: 960px)": {
            paddingLeft: "40px",
            paddingRight: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: "700",
          textTransform: "none",
          boxShadow: "none",
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        text: {
          borderRadius: "none",
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          padding: "4px 8px",
          color: "#27292A",
          fontSize: "12px",
          fontWeight: 500,
          maxHeight: "28px",
        },
        label: {
          padding: 0,
          lineHeight: "18px",
        },
        filled: {
          backgroundColor: "#F1F4F5",
          border: "none",
        },
        outlined: {
          backgroundColor: "#EFFDFF",
          border: "solid 1px #B6D4E3",
        },
        icon: {
          marginLeft: 0,
          marginRight: "4px",
        },
      },
    },
  },
});

  const arrSkill = [ " Doctors provide routine patient care, conduct rounds, perform scheduled surgeries, and attend outpatient clinics. This is the most common shift for primary care physicians, specialists, and surgeons.", 
    "Implement and monitor patient care plans. Monitor, record and communicate patient condition as appropriate.",
   "Serve as a primary coordinator of all disciplines for well-coordinated patient care.",
   "Note and carry out physician and nursing orders.",
   "Assess and coordinate patient's discharge planning needs with members of the healthcare team."]

const arrSkillsNeeded = ["Strong communication",
  "Problem-solving", 
  "Patient care skills",
  "Emergency care"]

const additionalBenifit = [" Meals provided",
"Transport reimbursement",
"Overtime pay",
"Medical, dental, and vision plans with prescription coverage",
"403(b) retirement program with employer match",
"Generous paid time off",
"4 weeks of paid parental leave",
"Associate Assistance Program"]
// Customizable Area End
import ViewJobController, {
  Props,
} from "./ViewJobController.web";

export default class ViewJob extends ViewJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    // Customizable Area End
  }
  // Customizable Area Start
 renderJobCard(){  
  return (
    //Merge Engine DefaultContainer
    <Box sx={{background:"#F8F8F8"}}>
  <ViewJobWrapper>
          <Button startIcon={<ArrowBackIcon/>} sx={{ mb: 2, color:'black',fontWeight:'800',fontSize:'16px',fontFamily:'Manrope'  }} onClick={() => {this.props.navigation.goBack()}}>
            Back
          </Button>
          <Box className='view_job_box' sx={{left:30}}>
            <Box display="flex" gap={0.5} justifyContent='space-between'>
                <Box ml={0.75} data-test-id="profile-pic" display="flex">
                  <Avatar alt="Remy Sharp" src={Profile} />
                  <Typography variant="h6" color="#4768BE" paddingLeft= '10px' fontWeight='600' fontFamily='Manrope'>
              {this.state.application?.facilityName}
            </Typography>
                </Box>
            
            <Box display="flex" >
            <Button className="apply_button" onClick={()=> {this.setState({isAskForApply:true})}} variant="contained" >Apply</Button>
              </Box>
              </Box>
              <Box display="flex" gap={0.5}> 
            <Typography variant="h4" sx={{ fontWeight: "700", mt: 0.9,fontSize:'28px',lineHeight:'40px' }}>
              {"Occupational Therapist"}
            </Typography>
            <Chip variant='outlined' color="success" label={"Viewed"} sx={{ borderRadius:'17.25px',mt: 2,fontSize:'15',marginLeft: '10px',height: '35', width:'108',background:"#D1FAE5",color:'#059669' ,borderColor:'#059669' }} />
            </Box>
            <Typography  color="textSecondary" fontSize="small" sx={{ mt: 0}}>
              {"Nairobi , Kenya (Full Time)"}
            </Typography>
            <Typography variant="subtitle1" sx={{ mt:2,gap: '15px'}}><strong>Department:</strong> {"Orthopaedics"} </Typography>
            <Typography variant="subtitle1" sx={{ mt:0.5,gap: '15px'}}><strong> Schedule Date: </strong> {"28/1/2025 (Full Time)"}</Typography>
            <Typography variant="subtitle1" sx={{ mt:0.5,gap: '15px'}}><strong> Schedule Time: </strong> {"8:00 AM - 4:00 PM"}</Typography>
            <Typography variant="subtitle1" sx={{ mt:0.5,gap: '15px'}}> <strong> Location: </strong>{"3rd Parklands Avenue, Limuru Road, Nairobi, 00220 ,Kenya"}</Typography>
            <Typography variant="h4" sx={{ mt:2, gap: '15px',fontSize:'16px' }}><strong>Shift Description </strong></Typography>  
              <Box sx={{mb: 3}}>
              <ul>
              {arrSkill.map((skill, index) => (
                <li style={{fontWeight:'normal',font:'Manrope'}} key={index}>{skill}</li>
              ))}
            </ul>
            </Box>

            <Divider/>

            <Typography variant="h4" sx={{ mt: 3 }}><strong>Requirements</strong> </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}><strong>Minimum Qualifications:</strong> </Typography>
            <ul>
              <li style={{fontWeight:'normal',fontSize:'14px'}}>Bachelor's in Medicine from an accredited school/college of nursing OR Required professional licensure at time of hire.</li>
            </ul>
            <Typography variant="subtitle1"><strong>Experience Level:</strong>  </Typography>
            <Box>
              <ul>
                <li style={{fontWeight:'normal',fontSize:'14px'}}>0-2 Years of Experience</li>
            </ul>
            </Box>
            <Typography variant="subtitle1"><strong>Dress Code:</strong></Typography>
            <Box>
              <ul>
                <li style={{fontWeight:'normal'}}>Scrubs, Formal, Lab coat required</li>
            </ul>
            </Box>
            <Typography variant="subtitle1"><strong>Skills Needed:</strong></Typography>
            <Box >
              <ul>
              {arrSkillsNeeded.map((skill, index) => (
                <li key={index} style={{fontWeight:'normal'}}>{skill}</li>
              ))}
            </ul>
            </Box>
            <Typography variant="subtitle1"><strong>COVID-19 Safety Requirements:</strong></Typography>
            <Box>
              <ul>
                <li style={{fontWeight:'normal'}} >Vaccination , PPE usage , Social Distance , Filtration</li>
            </ul>
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2 ,mb: 2 }}><strong> Attachments: </strong></Typography>
            {/* <Button  sx={{background:'#B6D4E3', color:'black'}}>📎 UAB University Hospital .pdf</Button> */}
            <Chip label='UAB University Hospital .pdf' icon={<Box component="img" pr={0.25} src={FacilityIcon} />} sx={{background: "#EFFDFF"}} variant="outlined"/>
            
            <Divider style={{marginTop:30}}/>
            <Typography variant="h4" sx={{ mt: 3,mb:2 }}><strong>Benefits</strong></Typography>
            <Typography variant="subtitle1"><strong>Compensation:</strong></Typography>
            <Box>
              <ul>
                <li style={{fontWeight:'normal'}} >KES 2,000 per/h</li>
            </ul>
            </Box>
            <Typography variant="subtitle1"><strong>Additional Benefits:</strong></Typography>
            <Box >
              <ul>
              {additionalBenifit.map((skill, index) => (
                <li style={{fontWeight:'normal'}} key={index}>{skill}</li>
              ))}
            </ul>
            </Box>
          </Box>
        </ViewJobWrapper>
        </Box>
  )
 }
confirmApply = () => {
  return (
       <Dialog open={this.state.isAskForApply} onClose={this.handleClose}>
        <DialogTitle sx={{fontSize:'24px',fontFamily:'manrope',fontStyle:'bold'}}><strong> Apply Shifts </strong></DialogTitle>
        <Divider />
        <DialogContent style={{fontWeight:'400',fontSize:'18px'}}>
           Are you certain you want to proceed with applying for this shifts?
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={this.handleClose} variant='contained'  className="DialogCancelButton" sx={{background:"#A9B1BC",
  color:'white',
  borderRadius:'8px',
  fontSize:'16px',
  fontWeight:'800',
  width:130,
  height:44}}><strong>Cancel</strong></Button>
        <Button variant='contained' className="DialogApplyButton" sx={{background:'#2EAB65',
  color:'#FFF',
  borderRadius:'8px',
  fontSize:'16px',
  fontWeight:'800',
  width:130,
  height:44}} onClick={this.handleApply}><strong>Apply</strong> </Button>
        </DialogActions>
      </Dialog>
  )
}
setChecked() {
this.setState({checkConfirmApply : !this.state.checkConfirmApply})
}
fullNameUpdate = (event:any) => {  
  this.setState({fullName:event?.target?.value})
}
professionalTitleUpdate = (event:any) => {  
  this.setState({professionalTitle:event?.target?.value})
}
educationUpdate = (event:any) => {  
  this.setState({education:event?.target?.value})
}
experienceUpdate = (event:any) => {  
  this.setState({experience:event?.target?.value})
}
renderApplyPopup = () => {
  return (
    <Dialog open={this.state.isApplyPopupVisible} onClose={this.handleClose} maxWidth="sm" fullWidth>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle sx={{ fontSize: "24px", fontWeight: "700" }}>Apply Shifts</DialogTitle>
        <IconButton onClick={this.handleClose}>
         <Avatar src={CancelIcon} className="applicant__avatar" sx={{height:'32px',width:'32px'}} />
        </IconButton>
      </Box>
      <Divider style={{marginBottom:20}}/>
      <DialogContent sx={{ padding: "0 24px 16px" }}>
      <Typography sx={{fontSize:'14px',lineHeight:'20px',fontWeight:'700'}}>
        Full Name
      </Typography>
        <TextField className="nametextField" fullWidth sx={{marginBottom:'20',marginTop:'5'}} onChange={(event)=> this.fullNameUpdate(event)}  value={this.state.fullName}  />
        <Typography sx={{fontSize:'14px',lineHeight:'20px',fontWeight:'700'}}>
        Professional Title
      </Typography>
        <TextField className="professionaltextField" fullWidth sx={{marginBottom:'20',marginTop:'5'}} onChange={(event)=> this.professionalTitleUpdate(event)}value={this.state.professionalTitle}  />
        <Typography sx={{fontSize:'14px',lineHeight:'20px',fontWeight:'700'}}>
        Education / Certifications
      </Typography>
        <TextField className="educationtextField" fullWidth sx={{marginBottom:'20',marginTop:'5'}} onChange={(event)=> this.educationUpdate(event)} value={this.state.education}  />
        <Typography sx={{fontSize:'14px',lineHeight:'20px',fontWeight:'700'}}>
        Years of Experience
      </Typography>
        <TextField className="exeperincetextField" fullWidth sx={{marginBottom:'20',marginTop:'5'}}  onChange={(event)=> this.experienceUpdate(event)} value={this.state.experience}  />
        <Typography sx={{fontSize:'14px',lineHeight:'20px',fontWeight:'700'}}>
        Attachment
      </Typography>

        <Box
          sx={{
            border: "2px dashed #ccc",
            borderRadius: "8px",
            padding: "16px",
            textAlign: "center",
            marginTop: "8",
          }}
        >
          <Box sx={{display:'flex',justifyContent: 'center',alignItems: 'center', gap:0.5}}>
          <Avatar src={CloudUploadIcon} className="applicant__avatar"  sx={{ fontSize: "40px", color: "#888", }} />
          </Box>
          <Box sx={{display:'flex',justifyContent: 'center',alignItems: 'center',gap:0.5}}>
          <Typography  sx={{ cursor: "pointer", fontWeight: "500", color:"#4768BE" }}>
            {"Click to Upload"}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {"or drag and drop"}
          </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary">
            (Max. File size: 25 MB)
          </Typography>
        </Box>

        <FormControlLabel
          control={<Checkbox  sx={{fontSize:'14px',height:'20px',width:'20px',color:'#A9B1BC',marginRight:'5'}} className="Check_box" checkedIcon={<Avatar src={CheckboxIcon} sx={{height:'20px',width:'20px',borderRadius:'0px'}}  />} checked={this.state.checkConfirmApply}  onChange={() => this.setChecked()} />}
          label="Check if you are sure that you want to apply for this position"
          sx={{ marginTop: "16px"}}
        />
      </DialogContent>
      <DialogActions>
          <Button onClick={this.handleClose} variant='contained' sx={{background:"#A9B1BC",
  color:'white',
  borderRadius:'8px',
  fontSize:'16px',
  fontWeight:'800',
  width:130,
  height:40}}><strong>Cancel</strong></Button>
        <Button variant='contained' sx={{color:'#FFF',
  borderRadius:'8px',
  fontSize:'16px',
  fontWeight:'800',
  width:130,
  height:40,
  background:this.state.checkConfirmApply ? "#2EAB65":'#B6D4E3'}} onClick={this.handleApplyForm}><strong>Apply</strong> </Button>
        </DialogActions>
    </Dialog>
  );
}
 handleClose = () => {
  this.setState({isAskForApply : false})
  this.setState({isApplyPopupVisible : false})

 }
 handleApply = () => {
  this.setState({isAskForApply : false})
  this.setState({isApplyPopupVisible : true})
 }
 handleApplyForm = () => {
  this.setState({isApplyPopupVisible : false})
  this.setState({isAlertVisible : true})
  localStorage.setItem("showSuccessFailureBar", "success")
  this.props.navigation.goBack()
 }
 renderAlertPopUp() {
  return (
    <Snackbar
      open={this.state.isAlertVisible}
      autoHideDuration={3000} // Auto-hide after 3 seconds
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={()=>{this.setState({isAlertVisible:false})}}
    >
      <Alert
        severity="success"
        variant="filled"
        icon={<CheckCircleIcon fontSize="inherit" sx={{color:'#34D399'}} />}
        sx={{ backgroundColor: "white", color: "black", borderRadius: "8px", boxShadow: 3 }}
      >
        {"You have successfully applied for the position of Occupational Therapist."}
      </Alert>
    </Snackbar>
  );
}
 
  // Customizable Area End

  render() {
    // Customizable Area Start    
    return (
      //Merge Engine DefaultContainer
         <>
              <DashboardLayout navigation={this.props.navigation}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Container>{(this.state.application != null) && this.renderJobCard() }</Container>
                  {this.renderApplyPopup()}
                  {this.confirmApply()}
                  {this.renderAlertPopUp()}
                </ThemeProvider>
              </DashboardLayout>
            </>
    )
      //Merge Engine End DefaultContainer
    
    // Customizable Area End
  }
}

// Customizable Area Start
const ViewJobWrapper = styled(Box)({
  background:"#F8F8F8",
  position:'absolute',
  left:30,
  right:30,
  "& .view_job_box": {
    padding: 0, 
    borderRadius: 2,
    left:30,
  },
  "& .apply_button": {
    color: "#FFF",
    borderRadius: "6px",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: 700,
    padding: "4px 10px",
    width:'130px',
    height:'44px',
    right:30,
  }
 
})
const DialogApplyButton = styled(Button) ({
  background:'#2EAB65',
  color:'#FFF',
  fontWeight:'800',
  borderRadius:'8px',
  fontSize:'16px',
  width:130,
  height:40
})
const DialogCancelButton = styled(Button) ({
  background:"#A9B1BC",
  color:'white',
  fontWeight:'800',
  borderRadius:'8px',
  fontSize:'16px',
  width:130,
  height:40
})

const Check_box = styled(CheckBox) ({
 "Check_box : .checked" :  {
  background:"red",
}
})

// Customizable Area End
