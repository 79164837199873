import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { JobApplication, UserType } from "../../utilities/src/enums";


// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  application : JobApplication;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userType: UserType,
  application: JobApplication | null;
  isAskForApply : boolean
  isApplyPopupVisible : boolean
  checkConfirmApply : boolean
  isAlertVisible : boolean
  fullName : string
  professionalTitle : string
  education : string
  experience : string
  // Customizable Area End
}
interface SS {}

export default class ViewJobController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      userType: UserType.Professional,
      application : null,
      isAskForApply : false,
      isApplyPopupVisible : false,
      checkConfirmApply : false,
      isAlertVisible: false,
      fullName : "",
      experience: "",
      professionalTitle : "",
      education : ""

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start      
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const userInfo = message.getData(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        this.setState({application : userInfo.jobDetails})
        }
        
  
  
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
