import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);


export const FacilitySettingSchema1 = Yup.object().shape({
    facility_name: Yup.string()
        .required("Field is required")
        .max(100, "Facility name should not be more than 100 characters long")
        .trim(),
    facility_type_id: Yup.string()
        .required("Field is required")
        .trim(),
    facility_level_rating_id: Yup.string()
        .required("Field is required")
        .trim(),
    kra_pin_number: Yup.string()
        .required("Field is required")
        .trim(),
    kmpdc_facility_code: Yup.string()
        .required("Field is required")
        .trim(),
    full_name: Yup.string()
        .required("Field is required")
        .trim(),
    professional_title_id: Yup.string()
        .required("Field is required")
        .trim(),
    primary_contact_register_type: Yup.string()
        .required("Field is required")
        .trim(),
    primary_contact_register: Yup.string()
        .required("Field is required")
        .trim(),
});

export const FacilitySettingSchema2 = Yup.object().shape({
    country: Yup.string()
        .required("Field is required")
        .trim(),
    county: Yup.string()
        .when('country', {
            is: (data) => data && data === "Kenya",
            then: Yup.string()
                .required('Field is required')
                .trim()
        }),
    city: Yup.string()
        .required("Field is required")
        .trim(),
    address: Yup.string()
        .required("Field is required")
        .trim()
        .matches(/^[a-zA-Z0-9\s,\.]*$/, "Invalid address"),
});

export const FacilitySettingSchema3 = Yup.object().shape({
    nhif_shif_accreditation_status: Yup.string()
        .trim(),
    facility_safe_care_certification_level_dropdown: Yup.string()
        .trim(),
    facility_safe_care_certification_level: Yup.string()
        .trim(),
});

export const ProfessionalSettingSchema1 = Yup.object().shape({
    profile_photo: Yup.mixed()
        .required("Field is required")
        .test(
            "fileSize",
            "The image exceeded the maximum size.",
            (file) => !file || (file.size <= 1048576)
        )
        .test(
            "fileDimensions",
            "Profile photo size should be 300x300px.",
            async (file) => {
                if (!file) return false;
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        const img = new Image();
                        img.src = reader.result as string;

                        img.onload = () => {
                            resolve(img.width === 300 && img.height === 300);
                        };
                    };
                });
            }
        ),
    full_name: Yup.string().trim().required("Field is required"),
    date_of_birth: Yup.string()
        .required("Field is required")
        .test(
            "valid-date",
            "Please enter a valid date in DD/MM/YYYY format",
            (value) => value && dayjs(value, "DD/MM/YYYY", true).isValid()
        )
        .test(
            "past-date",
            "Date of birth cannot be in the future",
            (value) =>
                value && dayjs(value, "DD/MM/YYYY").isBefore(dayjs())
        ),
    gender: Yup.string().trim().required("Field is required"),
    nationality: Yup.string().trim().required("Field is required"),
    country: Yup.string().trim().required("Field is required"),
    county: Yup.string()
        .when('country', {
            is: (data) => data && data === "Kenya",
            then: Yup.string()
                .required('Field is required')
                .trim()
        }),
    city: Yup.string().trim().required("Field is required"),
    address: Yup.string().trim().required("Field is required"),
});

export const ProfessionalSettingSchema2 = Yup.object().shape({
    professional_title_id: Yup.string()
        .required("Field is required")
        .trim(),
    education_certification_id: Yup.string().trim().required("Field is required"),
    year_of_expirience: Yup.string().trim().required("Field is required"),
    professional_indemnity: Yup.string().trim().required("Field is required"),
    professional_licence_number: Yup.string().trim().required("Field is required"),
    licensing_authority_id: Yup.string().trim().required("Field is required"),
    licence_ex_date: Yup.string()
        .required("Field is required")
        .test(
            "valid-date",
            "Please enter a valid Expiry date in DD/MM/YYYY format",
            (value) => value && dayjs(value, "DD/MM/YYYY", true).isValid()
        )
        .test(
            "past-date",
            "Expiry date can not be in the past",
            (value) =>
                value && dayjs(value, "DD/MM/YYYY").isSameOrAfter(dayjs(), "day")
        ),
    kra_pin_number: Yup.string()
        .trim()
        .required("Field is required")
        .matches(/^\d+$/, "Number is invalid"),
    nssf_number: Yup.string().trim().required("Choosing an option is required"),
});

export const ProfessionalSettingSchema3 = Yup.object().shape({

});

export const ProfessionalSettingSchema4 = Yup.object().shape({
    current_availability_status: Yup.string().trim(),

    shift_type_full_time: Yup.boolean(),
    shift_type_part_time: Yup.boolean(),
    shift_type_contract: Yup.boolean(),

    work_location_hospitals: Yup.boolean(),
    work_location_clinics: Yup.boolean(),
    work_location_home_based_care: Yup.boolean(),
    work_location_home_telehealth: Yup.boolean(),

    shift_type_day: Yup.boolean(),
    shift_type_night: Yup.boolean(),
    shift_type_rotational: Yup.boolean(),

    facility_safe_care_certification_level: Yup.string()
        .trim(),
    telehealth_availability_id: Yup.string().trim().required("Field is required"),
    county_preferences: Yup.string()
        .when('country', {
            is: (data) => data && data === "Kenya",
            then: Yup.string()
                .required('Field is required')
                .trim()
        }),

}).test(
    "at-least-one-shift-type",
    "At least one shift type must be selected",
    function (values) {
        const { current_availability_status, shift_type_full_time, shift_type_part_time, shift_type_contract } = values;

        if (!current_availability_status ||
            (!shift_type_full_time &&
                !shift_type_part_time &&
                !shift_type_contract)
        ) {
            return this.createError({
                path: "current_availability_status",
                message: "Choosing an option is required",
            });
        }

        return true;
    }
).test(
    "at-least-one-prefered-work-settings",
    "At least one preferred work settings must be selected",
    function (values) {
        const { work_location_hospitals, work_location_clinics, work_location_home_based_care, work_location_home_telehealth } = values;

        if (!work_location_hospitals &&
            !work_location_clinics &&
            !work_location_home_based_care &&
            !work_location_home_telehealth
        ) {
            return this.createError({
                path: "work_location_hospitals",
                message: "Choosing at least an option is required",
            });
        }

        return true;
    }
).test(
    "at-least-one-shift-type-time",
    "At least one shift type time must be selected",
    function (values) {
        const { shift_type_day, shift_type_night, shift_type_rotational } = values;

        if (!shift_type_day &&
            !shift_type_night &&
            !shift_type_rotational
        ) {
            return this.createError({
                path: "shift_type_day",
                message: "Choosing at least an option is required",
            });
        }

        return true;
    }
)

export const ProfessionalSettingsSchema = Yup.object().shape({
    profile_photo: Yup.mixed()
    .required("Field is required")
    .test(
        "fileSize",
        "The image exceeded the maximum size.",
        (file) => !file || (file.size <= 1048576)
    )
    .test(
        "fileDimensions",
        "Profile photo size should be 300x300px.",
        async (file) => {
            if (!file) return false;
            return new Promise((resolve) => {
                const reader = new FileReader();
               // reader.readAsDataURL(file);

                reader.onload = () => {
                    const img = new Image();
                    img.src = reader.result as string;

                    img.onload = () => {
                        resolve(img.width === 300 && img.height === 300);
                    };
                };
            });
        }
    ),
    full_name: Yup.string().trim().required("Field is required"),
    date_of_birth: Yup.string()
        .required("Field is required")
        .test(
            "valid-date",
            "Please enter a valid date in DD/MM/YYYY format",
            (value) => value && dayjs(value, "DD/MM/YYYY", true).isValid()
        )
        .test(
            "past-date",
            "Date of birth cannot be in the future",
            (value) =>
                value && dayjs(value, "DD/MM/YYYY").isBefore(dayjs())
        ),
    gender: Yup.string().trim().required("Field is required"),
    nationality: Yup.string().trim().required("Field is required"),
    country: Yup.string().trim().required("Field is required"),
    county: Yup.string()
        .when('country', {
            is: (data) => data && data === "Kenya",
            then: Yup.string()
                .required('Field is required')
                .trim()
        }),
    city: Yup.string().trim().required("Field is required"),
    address: Yup.string().trim().required("Field is required"),
    education_certification_id: Yup.string().trim().required("Field is required"),
    year_of_expirience: Yup.string().trim().required("Field is required"),
    professional_indemnity: Yup.string().trim().required("Field is required"),
    professional_licence_number: Yup.string().trim().required("Field is required"),
    licensing_authority_id: Yup.string().trim().required("Field is required"),
    licence_ex_date: Yup.string()
        .required("Field is required")
        .test(
            "valid-date",
            "Please enter a valid Expiry date in DD/MM/YYYY format",
            (value) => value && dayjs(value, "DD/MM/YYYY", true).isValid()
        )
        .test(
            "past-date",
            "Expiry date can not be in the past",
            (value) =>
                value && dayjs(value, "DD/MM/YYYY").isSameOrAfter(dayjs(), "day")
        ),
    kra_pin_number: Yup.string()
        .trim()
        .required("Field is required")
        .matches(/^\d+$/, "Number is invalid"),
    nssf_number: Yup.string().trim().required("Choosing an option is required"),
})
export const FacilitySettingsSchema = Yup.object().shape({
   
})

export const ContactUsSchema = Yup.object().shape({
    name: Yup.string()
        .required("Field is required")
        .max(100, "Text name should not be more than 100 characters long")
        .trim(),
    email: Yup.string()
        .required("Field is required")
        .max(100, "Text name should not be more than 100 characters long")
        .trim(),
    request_subject_id: Yup.number()
        .required("Field is required")
        .nullable(),
    description: Yup.string()
        .required("Field is required")
        .trim(),
    contact_file: Yup.mixed()
        .test(
            "fileSize",
            "The file size should be less than 10 MB",
            (file) => !file || (file.size <= 10 * 1024 * 1024)
        )
});