// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box, CssBaseline, Container, Button, Typography, Stepper, Step, StepLabel, Grid, FormControl, FormGroup, FormLabel } from "@mui/material";
import AddEditShiftController, { Props, configJSON } from "./AddEditShiftController.web";
import { Field, Form, Formik, FormikProps } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomSelect from "../../../../components/src/components/customFormComponents/CustomSelect.web";
import dayjs from "dayjs";
import { facilityLevelRatingList, globalTheme, safetyRequirementsList, yearsOfExperienceList } from "../../../../components/src/components/utils/constant";
import { DotIcon, Logo } from "../assets";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomCheckbox from "../../../../components/src/components/customFormComponents/CustomCheckBox.web";
import DraggableFileUploader from "../../../../components/src/components/customFormComponents/DraggableFIleUploader.web";

const newTheme = createTheme({
  ...globalTheme
});
// Customizable Area End

export default class AddEditShift extends AddEditShiftController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ShiftFooter = (formikProps: FormikProps<any>) => {
    const { activeStep, isEdit } = this.state;
    const stepDetails = configJSON.headerTextList[activeStep]
    const { values, isValid, isSubmitting } = formikProps;

    return (
      <FooterBox>
        {stepDetails?.optional && <Box className="last_step_footer">
          <Typography
            className="last_step_footer_text"
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: configJSON.headerTextList[activeStep].optionalText }}>
          </Typography>
        </Box>}
        <section
          className="main_footer_section"
        >
          <Box className="footer_section">
            <Container>
              <Box
                className="footer_wrapper"
                sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}
              >
                <div style={{ minWidth: "101px" }}>
                  <Button
                    style={{ display: activeStep === 0 ? "none" : "" }}
                    variant="contained"
                    onClick={this.handleBack.bind(this, values)}
                    data-test-id="back_btn"
                    className="stepperButton stapper_back_button"
                    startIcon={<ArrowBackIcon />}
                  >
                    {configJSON.backButton}
                  </Button>
                </div>
                <CustomStepper activeStep={activeStep}>
                  {configJSON.headerTextList.map((label: { label: boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.Key | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode; } = {};

                    return (
                      <Step disabled key={String(label.label)} {...stepProps}>
                        <StepLabel {
                          ...labelProps}
                        >
                          {label.label} <br /> {label.description}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </CustomStepper>
                <Button
                  variant="contained"
                  data-test-id="next_btn"
                  type={"submit"}
                  className="stepperButton stapper_continue_button"
                  disabled={(isSubmitting || !isValid) && activeStep !== configJSON.headerTextList.length - 1}
                >
                  {(activeStep === configJSON.headerTextList.length - 1) ?
                    (isEdit ?
                      configJSON.editSubmitButton :
                      configJSON.addSubmitButton) :
                    configJSON.continueButton}
                </Button>
              </Box>
            </Container>
          </Box>
        </section>
      </FooterBox>
    )
  }

  ShiftHeader = () => {
    const { isEdit } = this.state;

    return (
      <HeaderBox>
        <section className="main_hero_section">
          <nav className="nav_section">
            <Container>
              <Box className="navbar_wrapper" sx={{ pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 } }}>
                <img
                  src={Logo}
                  alt="branding"
                  className="branding_logo"
                />

                <Box className="center_header_wrapper" sx={{ mb: { xs: 1, md: 2 } }}>
                  <Typography variant="h1" className="center_header_text1">{`${isEdit ? "Edit" : "Add"} ${configJSON.headerLabel1}`}</Typography>
                  <Box className="center_header_text2">
                    <img src={DotIcon} />
                  </Box>
                  <Typography variant="h1" className="center_header_text3">{this.getHeading2()}</Typography>

                </Box>
                <Button
                  className="back_label"
                  data-test-id="back-btn"
                  disableElevation
                  onClick={this.props.navigation.goBack}
                >
                  {configJSON.backLabel}
                </Button>
              </Box>
            </Container>
          </nav>
        </section>
      </HeaderBox>
    )
  }

  ShiftMainForm = (formikProps: FormikProps<any>) => {
    const { activeStep } = this.state

    if (activeStep === 0) {
      return this.stepForm1();
    } else if (activeStep === 1) {
      return this.stepForm2();
    } else if (activeStep === 2) {
      return this.stepForm3(formikProps);
    }

  }

  stepForm1 = () => {
    return (
      <BoxWrapper>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomInput
              label={configJSON.shiftTitleLabel}
              name={configJSON.shiftTitle}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.role}
              label={configJSON.roleLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.specialty}
              label={configJSON.specialtyLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.shiftType}
              label={configJSON.shiftTypeLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInput
              name={configJSON.shiftStartDate}
              label={configJSON.shiftStartDateLabel}
              type="date"
              fullWidth
              dateProps={
                {
                  minDate: dayjs().subtract(18, 'years'),
                  maxDate: dayjs(),
                  format: configJSON.dateFormate,
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              name={configJSON.shiftEndDate}
              label={configJSON.shiftEndDateLabel}
              type="date"
              fullWidth
              dateProps={
                {
                  minDate: dayjs().subtract(18, 'years'),
                  maxDate: dayjs(),
                  format: configJSON.dateFormate,
                }
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box className="label_with_suggestion">
              <FormLabel id={configJSON.shiftDurationStart}>{configJSON.shiftDurationLabel}</FormLabel>
              <Typography variant="body2" className="suggestion_label">
                {configJSON.shiftDurationSuggestionLabel}
              </Typography>
            </Box>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  name={configJSON.shiftDurationStart}
                  type="time"
                  fullWidth
                  timeProps={
                    {
                      // withIcon: true,
                      // format: 'hh: MM A',
                    }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  name={configJSON.shiftDurationEnd}
                  fullWidth
                  type="time"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box className="label_with_suggestion">
              <FormLabel id={configJSON.compensationType}>{configJSON.compensationLabel}</FormLabel>
              <Typography variant="body2" className="suggestion_label">
                {configJSON.compensationSuggestionLabel}
              </Typography>
            </Box>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  name={configJSON.compensationType}
                  fullWidth
                  options={facilityLevelRatingList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  name={configJSON.rate}
                  fullWidth
                  options={facilityLevelRatingList}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  name={configJSON.currency}
                  fullWidth
                  options={facilityLevelRatingList}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomInput
              label={configJSON.additionalBenefitsLabel}
              name={configJSON.additionalBenefits}
              fullWidth
              optional
            />
          </Grid>
        </Grid>
      </BoxWrapper >
    )
  }

  stepForm2 = () => {
    return (
      <BoxWrapper>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <CustomInput
              name={configJSON.facilityName}
              label={configJSON.facilityNameLabel}
              fullWidth
              optional
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              name={configJSON.locationType}
              label={configJSON.locationTypeLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              name={configJSON.facilityAddress}
              label={configJSON.facilityAddressLabel}
              fullWidth
            />
          </Grid>
        </Grid>
      </BoxWrapper>
    )
  }

  stepForm3 = (formikProps: any) => {
    const { values, setFieldTouched, setFieldValue, errors, touched } = formikProps;

    return (
      <BoxWrapper sx={{ alignContent: { sx: "start", md: "center" } }}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.minimumQualifications}
              label={configJSON.minimumQualificationsLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.experienceLevel}
              label={configJSON.experienceLevelLabel}
              fullWidth
              options={yearsOfExperienceList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.skillsNeeded}
              label={configJSON.skillsNeededLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.languageProficiency}
              label={configJSON.languageProficiencyLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.contactPerson}
              label={configJSON.contactPersonLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              name={configJSON.preferredContactMethod}
              label={configJSON.preferredContactMethodLabel}
              fullWidth
              options={facilityLevelRatingList}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInput
              name={configJSON.contactEmail}
              label={configJSON.contactEmailLabel}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={0.5}>
              <FormLabel id={configJSON.phoneNumber}>{configJSON.phoneNumberLabel}</FormLabel>
            </Box>
            <Box mb={1}>
              <ReactPhoneInput
                data-test-id="phone-number"
                value={values.phone_number}
                isValid={!errors.phone_number}
                onlyCountries={['in', 'lk', 'ae', 'ke']}
                country="ke"
                inputProps={{
                  name: configJSON.phoneNumber,
                  required: true,
                }}
                onChange={(phone_number, country: CountryData) => {
                  setFieldValue(configJSON.phoneNumber, phone_number);
                  this.handlePhoneChange(phone_number, country)
                }}
                enableSearch
                onBlur={() => setFieldTouched("phone", true)}
                masks={{
                  ae: ".. ... ....",
                  ke: "... ......",
                  lk: ".. ... ....",
                  in: "..... ....."
                }}
              />
              {touched.phone && errors.phone && <Typography className="error__message">{errors.phone}</Typography>}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              name={configJSON.dressCode}
              label={configJSON.dressCodeLabel}
              fullWidth
              optional
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInput
              name={configJSON.ShiftDescription}
              label={configJSON.ShiftDescriptionLabel}
              fullWidth
              multiline
              maxRows={5.2}
              minRows={3.5}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px"
                },
                "& .MuiInputBase-input": {
                  lineHeight: "24px",
                  marginBottom: ""
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckboxFormControl>
              <Box className="label_with_error">
                <FormLabel id={configJSON.SafetyRequirements}>
                  {`${configJSON.SafetyRequirementsLabel} (Optional)`}
                </FormLabel>
              </Box>

              <FormGroup
                sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap" }}
              >
                {safetyRequirementsList.map((option: { label: string, value: string | number, style: React.CSSProperties | undefined }) => (
                  <Field name={option.value}>
                    {({ field, form }: any) => (
                      <Box style={{ ...option.style }} >
                        <CustomCheckbox
                          {...field}
                          value={option.value}
                          name={option.value}
                          label={option.label}
                          checked={field.value}
                          onChange={(event) => {
                            form.setFieldValue(field.name, event.target.checked);
                          }}
                          key={option.value}
                        />
                      </Box>
                    )}
                  </Field>
                ))}
              </FormGroup>
            </CheckboxFormControl>

          </Grid>

          <Grid item xs={12}>
            <Box className="img_uploader_box">
              <Box mb={0.5}>
                <FormLabel id={configJSON.Attachments}>
                  {`${configJSON.AttachmentsLabel} (Optional)`}
                </FormLabel>
              </Box>
              <DraggableFileUploader />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <CheckboxFormControl>
              <FormGroup sx={{ mt: "3px", gap: { xs: 0, sm: "16px" }, flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap" }}>
                <Field name={configJSON.valid_professional_license_or_certification_mandatory}>
                  {({ field, form }: any) => (
                    <CustomCheckbox
                      {...field}
                      name={configJSON.valid_professional_license_or_certification_mandatory}
                      label={configJSON.valid_professional_license_or_certification_mandatory_Label}
                      checked={field.value}
                      onChange={(event) => {
                        form.setFieldValue(field.name, event.target.checked);
                      }}
                    />
                  )}
                </Field>
              </FormGroup>
            </CheckboxFormControl>

          </Grid>
        </Grid>
      </BoxWrapper>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={newTheme}>
        <CssBaseline />
        <Formik
          initialValues={this.state.formValues}
          onSubmit={this.handleNext}
          data-test-id="formik"
          validateOnMount
          enableReinitialize
          validateOnBlur={true}
          key={this.state.activeStep}
        >
          {(formikProps) => (
            <StyledForm>
              {this.ShiftHeader()}
              {this.ShiftMainForm(formikProps)}
              {this.ShiftFooter(formikProps)}
            </StyledForm>
          )}
        </Formik>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledForm = styled(Form)({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  flexDirection: "column",
  backgroundColor: "#F1F4F5",
  "& .label_with_error": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .img_uploader_box": {
    margin: "18px 0"
  },
  "& .label_with_suggestion": {
    display: "flex",
    justifyContent: "space-between",
    "& .suggestion_label": {
      color: "#73B2D1",
    }
  }
});

const FooterBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  "& .main_footer_section": {
    position: "relative",
    overflow: "hidden",
    "& .footer_section": {
      "& .footer_wrapper": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "28px 0",
        gap: "32px",
        "& .stapper_back_button": {
          color: "#27292A",
          backgroundColor: "#F1F4F5",
          border: "1px solid #E9EDF4",
        },
        "& .stepperButton": {
          boxShadow: "none",
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "24px",
        },
        "& .stapper_continue_button": {
          minWidth: "110px",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
          }
        },
      },
    },
    "& .last_step_footer": {
      backgroundColor: "#EFFDFF",
      borderTop: "1px solid #E9EDF4",
      padding: "20px",
      "& .last_step_footer_text": {
        color: "#73B2D1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        "& span": {
          fontWeight: 800,
        },
      },
    },
  },
});

const HeaderBox = styled(Box)({
  overflowY: "auto",
  "& .main_hero_section": {
    position: "relative",
    overflow: "hidden",
    "& .content_wrapper": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& .hero_tagline": {
        textWrap: "wrap",
        textAlign: "center",
        maxWidth: "625px",
      },
      "& .hero_title": {
        textWrap: "wrap",
        textAlign: "center",
        maxWidth: "580px",
        "& .branding": {
          fontWeight: 800,
          color: "#2EAB65",
        },
      },
    },
    "& .nav_section": {
      backgroundColor: "#D1E1E9",
      "& .navbar_wrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .branding_logo": {
          aspectRatio: "1.58",
          width: "83px",
          height: "52px",
        },
        "& .center_header_wrapper": {
          margin: 0,
          alignItems: "center",
          display: "inline-flex",
          gap: "12px",
          "& .center_header_text1": {
            fontSize: "28px",
            lineHeight: "36px",
          },
          "& .center_header_text2": {
            width: "21px",
            textAlign: "center",
          },
          "& .center_header_text3": {
            fontWeight: 400,
          },
        },
        "& .back_label": {
          fontWeight: 800,
          fontSize: "16px",
          lineHeight: "16px",
          cursor: "pointer",
          textTransform: "none",
          boxShadow: "none",
          color: "#27292A",
        },
      },
    },
  },
});

const CustomStepper = styled(Stepper)({
  maxWidth: "779px",
  gap: "42px",
  flexWrap: "wrap",
  "@media (max-width: 768px)": {
    gap: "20px",
    justifyContent: "space-between",
    flex: 1,
  },
  "& .MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStep-root": {
    padding: "0px",
    "& .MuiStepLabel-iconContainer": {
      paddingRight: "16px",
    }
  },
  "& .MuiStepLabel-label": {
    "&.Mui-active": {
      fontWeight: 400,
    },
  },
  "& .MuiStepIcon-root": {
    color: "#CDD7E6",
    height: "32px",
    width: "32px",
    "& .MuiStepIcon-text": {
      fill: "#A9B1BC",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    "&.Mui-active": {
      border: "1px solid #27292A",
      color: "transparent",
      borderRadius: "50%",
      "& .MuiStepIcon-text": {
        fill: "#27292A",
      },
    },
    "&.Mui-completed": {
      color: "#73B2D1",
    },
    "& .MuiStepLabel-label": {
      fontWeight: 400,
    }
  },
});

const CheckboxFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiFormLabel-root": {
    color: "#27292A !important",
    fontWeight: 700,
  },
  "& .MuiFormGroup-root	": {
  },
  "& .primaryContactType_number": {
    flex: 1
  },
});

const BoxWrapper = styled(Container)({
  maxWidth: "784px !important",
  flex: 1,
  overflowY: "auto",
  paddingTop: "64px",
  paddingBottom: "10px",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: "0px",
    height: "0px",
    display: "none",
  },
  "& .react-tel-input": {
    "& .flag-dropdown ": {
      border: "solid 1px #E2E8F0",
      borderRadius: "8px",
      width: "76px",
      "& .selected-flag": {
        background: "#FFF",
        width: "100%",
        borderRadius: "8px",
        padding: "14px 13px",
        zoom: 1.25
      },
    },
    "& .form-control": {
      fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
      padding: "10px 12px 12px",
      lineHeight: "unset",
      height: "unset",
      width: "calc(100% - 92px)",
      marginLeft: "92px",
      border: "solid 1px #E2E8F0",
      borderRadius: "8px"
    }
  },
});
// Customizable Area End
