// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { CountryData } from "react-phone-input-2";
import createRequestMessage from "../../../../../packages/blocks/utilities/src/create-request-message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { ErrorSignUpResponse, formValues, OtpDataToStore, RegistrationDataToStore, SuccessSignUpRespose } from "./types";
import { FormikHelpers} from "formik"
import { HealthCareType, LocalStorageKeys } from '../../../../components/src/components/utils/enumConstant';
import { isLoggedIn } from "../../../../components/src/components/utils/commonFunctions";


// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFacilitySelected: boolean;
  passwordRules: {
    hasCapital: boolean | null;
    hasSpecial: boolean | null;
    hasNumber: boolean | null;
    hasMinLength: boolean | null;
  };
  countryCode: string;
  phoneFormat: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SignUpStepOneController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSignUpApiCall: string = "";
  setErrorsRef: FormikHelpers<formValues>['setErrors'] | null = null
  setSubmittingRef: FormikHelpers<formValues>['setSubmitting'] | null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isFacilitySelected: false,
      passwordRules: {
        hasCapital: null,
        hasSpecial: null,
        hasNumber: null,
        hasMinLength: null,
      },
      countryCode: "",
      phoneFormat: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.userSignUpApiCall && !responseJson?.errors) {
      const token = (responseJson as SuccessSignUpRespose).meta.otp_response.token;
      const phoneNumber = (responseJson as SuccessSignUpRespose).data.attributes.full_phone_number;

      const data = {
        token,
        phoneNumber,
      } as OtpDataToStore;

      setStorageData(LocalStorageKeys.OTPVerificationToken, JSON.stringify(data)).then(() => {
        this.props.navigation.navigate("PhoneVerification");
      });
      
    } else {
      const errorResponse = responseJson as ErrorSignUpResponse;

      if (this.setErrorsRef) this.setErrorsRef(errorResponse.errors);
      if (this.setSubmittingRef) this.setSubmittingRef(false);
    }



    // Customizable Area End
  }

  // Customizable Area Start

  initialValues = {
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
    fullName: "",
  };

  professionalSignupSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone Number is required")
      .test("valid-phone", "Phone number does not match the country code", (value) => {
        if (!value || !this.state.phoneFormat) return false;

        // Extract local phone number (excluding country code)
        const countryCodeLength = this.state.countryCode.length;
        const localPhoneNumber = value.slice(countryCodeLength);

        // Calculate expected phone number length based on format
        const expectedLength = (this.state.phoneFormat.slice(this.state.countryCode.length + 1).match(/\./g) || []).length;

        // Validate phone number length
        return localPhoneNumber.length === expectedLength;
      }),
    password: Yup.string()
      .matches(/[A-Z]/, "Password does not match the requirements")
      .matches(/[@$!%*?&]/, "Password does not match the requirements")
      .matches(/\d/, "Password does not match the requirements")
      .min(8, "Password does not match the requirements")
      .required("Create Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Create Password does not match Password confirmation")
      .required("Password Confirmation is required"),
    termsAccepted: Yup.boolean().oneOf([true], "Please confirm this checkbox to continue"),
  });

  facilitySignupSchema = this.professionalSignupSchema.shape({
    fullName: Yup.string()
      .min(2, "Full name must be at least 2 characters")
      .required("Full Name is required"),
  });

  async componentDidMount() {
    isLoggedIn(this.props)
    const storedData = (await getStorageData(LocalStorageKeys.SignUpData, true)) as RegistrationDataToStore
    const { selectedRole } = storedData;
    this.setState({ isFacilitySelected: selectedRole === HealthCareType.Facility });
    
  }

  redirectToLogin = () => this.props.navigation.navigate("Login")

  handleSubmit = (values: formValues, { setErrors, setSubmitting }: FormikHelpers<formValues>) => {

    this.setErrorsRef = setErrors;
    this.setSubmittingRef = setSubmitting;
    
    const payload = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": values.email,
          "full_phone_number": values.phone,
          "password": values.password,
          "role_id": this.state.isFacilitySelected ? HealthCareType.Facility : HealthCareType.Professional,
          "terms_and_conditions": true,
          ...(this.state.isFacilitySelected && { full_name: values.fullName }),
        }
      }
    }

    const requestMessage  = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.userSignUpApiCall = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "account_block/accounts",
      method: "POST",
      body: JSON.stringify(payload),
    });
  };

  handlePhoneChange = (_: string, country: CountryData) => {
    this.setState({
      countryCode: country.dialCode,
      phoneFormat: country.format,
    });
  };

  handlePasswordChange = (password: string) => {
    this.setState((prevState) => ({
      ...prevState,
      passwordRules: {
        hasCapital: /[A-Z]/.test(password),
        hasSpecial: /[@$!%*?&]/.test(password),
        hasNumber: /\d/.test(password),
        hasMinLength: password.length >= 8,
      },
    }));
  };

  redirectTo = (screenName: string) => this.props.navigation.navigate(screenName);
  // Customizable Area End
}
