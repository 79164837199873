import React, { Component } from "react";
import { FormControlLabel, Radio, RadioProps, styled } from "@mui/material";

type CustomRadioProps = RadioProps & {
  label: string;
  name?: string;
};

interface CustomRadioState {
}

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

class CustomRadio extends Component<CustomRadioProps, CustomRadioState> {
  constructor(props: CustomRadioProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, value } = this.props;
    return <CustomFormControlLabel value={value} control={<BpRadio />} label={label} />;
  }
}

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#27292A",
    fontWeight: "400",
  },
})

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: "#ffffff", 
  border: "1px solid #A9B1BC",
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  boxShadow: "unset",
  backgroundColor: '#73B2D1',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#73B2D1',
  },
});

export default CustomRadio;
