// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Typography, Box, Button, Drawer, CircularProgress, CssBaseline, IconButton, Popover, MenuItem } from "@mui/material";
import ContactUsModalController, { Props, configJSON } from "./ContactUsModalController.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import { globalTheme } from "../../../../components/src/components/utils/constant";
import CustomSelect from "../../../../components/src/components/customFormComponents/CustomSelect.web";
import { FileDoc, FileUpload } from "../assets";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatFileSize } from "../../../../components/src/components/utils/commonFunctions";
import CustomAlert from "../../../../components/src/components/customFormComponents/CustomAlert.web";
import { ContactUsSchema } from "../../../../components/src/components/utils/validationSchema";

const newTheme = createTheme({
    ...globalTheme
});
// Customizable Area End

export default class ContactUsModal extends ContactUsModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { open } = this.props;
        const { isFacility, requestingSubjectList, openToast } = this.state
        return (
            <ThemeProvider theme={newTheme}>
                <CssBaseline />

                <CustomAlert
                    open={openToast}
                    onClose={this.handleCloseAlert}
                    message={configJSON.contactUsSuccessMessage}
                    action={true}
                />
                <CustomDrawer
                    open={open}
                    onClose={this.handleCloseModal}
                    aria-labelledby="edit-modal-title"
                    aria-describedby="edit-modal-description"
                    data-test-id="dialog_box"
                    anchor="right"
                    key={String(this.props.open)}
                >

                    <Box className="form__header">
                        <Typography variant="subtitle2" data-test-id="supportTitle-label">
                            {configJSON.supportTitle}
                        </Typography>
                    </Box>
                    <Formik
                        data-test-id="formik"
                        initialValues={this.initialValues}
                        validationSchema={ContactUsSchema}
                        onSubmit={this.handleSubmitContact}
                        validateOnMount
                        enableReinitialize
                        validateOnBlur={true}
                    >
                        {({ setFieldValue, setTouched, values, isValid, isSubmitting, dirty }) => (
                            <StyledForm>
                                <Box className="form__main__data">
                                    <CustomInput
                                        label={isFacility ? configJSON.fullNameLabel : configJSON.fullNameLabelProfessional}
                                        name={configJSON.fullName}
                                        fullWidth
                                    />

                                    <CustomInput
                                        label={isFacility ? configJSON.emailLabel : configJSON.emailLabelProfessional}
                                        name={configJSON.email}
                                        fullWidth
                                        type="email"
                                    />

                                    <CustomSelect
                                        label={configJSON.requestingSubjectLabel}
                                        name={configJSON.requestingSubject}
                                        fullWidth
                                        options={requestingSubjectList}
                                    />

                                    <CustomInput
                                        label={configJSON.requestDescriptionLabel}
                                        name={configJSON.requestDescription}
                                        fullWidth
                                        multiline
                                        maxRows={5.2}
                                        minRows={3.5}
                                        placeholder={configJSON.requestDescriptionPlaceholder}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                padding: "0px"
                                            },
                                            "& .MuiInputBase-input": {
                                                lineHeight: "24px",
                                            }
                                        }}
                                    />

                                    {this.state.fileDocument && (
                                        <Box className="display_upload_doc">
                                            <Box className="upload_doc_img" >
                                                <img
                                                    src={FileDoc}
                                                    alt="file-icon"
                                                />
                                                <Box className="file_name_box">
                                                    <Typography data-test-id="uploadFileText" className="file_name" variant="caption">
                                                        {values?.contact_file?.name}
                                                    </Typography>
                                                    <Typography className="file_name" variant="caption" color={"#64748B !important"}>
                                                        ({formatFileSize(values.contact_file?.size ?? 0)})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <IconButton size="small" data-test-id="morevert_icon" onClick={this.handleOpenMenu}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Popover
                                                open={Boolean(this.state.anchorEl)}
                                                anchorEl={this.state.anchorEl}
                                                data-test-id="image_menu"
                                                onClose={this.handleCloseMenu}
                                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                        backgroundColor: "#ffffff",
                                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                                                        borderRadius: "8px",
                                                        minWidth: "160px",
                                                    },
                                                }}
                                            >
                                                <Box sx={{ p: 1 }}>
                                                    <MenuItem
                                                        data-test-id="delete_file"
                                                        onClick={() => this.handleDeleteFile(configJSON.contactFile, setFieldValue)}
                                                        sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px", color: "#DC2626" }}
                                                    >
                                                        Delete File
                                                    </MenuItem>
                                                </Box>
                                            </Popover>
                                        </Box>
                                    )}

                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="text"
                                        tabIndex={-1}
                                        startIcon={<img
                                            src={FileUpload}
                                        />}
                                        className="file_upload_btn"
                                        disabled={Boolean(values.contact_file)}
                                    >
                                        {configJSON.attachFile}
                                        <VisuallyHiddenInput
                                            type="file"
                                            id="file_uploader"
                                            name={configJSON.contactFile}
                                            required={false}
                                            key={String(new Date())}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleFileUpload(event, setFieldValue, setTouched) }}
                                        />
                                    </Button>
                                </Box>

                                <footer className="form__footer">
                                    <Button
                                        data-test-id="cancelButton"
                                        variant="contained"
                                        className="cancelButton"
                                        fullWidth
                                        onClick={this.handleCloseModal}
                                    >
                                        {configJSON.cancelBtn}
                                    </Button>

                                    <Button
                                        data-test-id="submit"
                                        type="submit"
                                        disabled={isSubmitting || !dirty || !isValid}
                                        variant="contained"
                                        fullWidth
                                        className="submitButton"
                                    >
                                        {isSubmitting ? <CircularProgress size="20px" color="inherit" /> : (configJSON.submitBtn)}
                                    </Button>
                                </footer>
                            </StyledForm>
                        )}
                    </Formik>

                </CustomDrawer>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CustomDrawer = styled(Drawer)({
    flex: 1,
    overflowY: "auto",
    "& .MuiDrawer-paper	": {
        maxWidth: "504px !important",
        width: "100%"
    },
    "& .form__header": {
        padding: "23px 32px",
        borderBottom: "1px solid #D6DEEA"
    }
});
const StyledForm = styled(Form)({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    padding: "23px 32px 0px",
    margin: "0px",
    "& .display_upload_doc": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F8F8F8",
        padding: "6px 3px 6px",
        borderRadius: "8px",
        width: "100%",
        height: "54px",
        gap: "12px",
        "& .upload_doc_img": {
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflow: "hidden",
            "& img": {
                width: "42px",
                height: "42px",
            },
            "& .file_name_box": {
                display: "flex",
                flexDirection: "column",
                "& .file_name": {
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: "#0F172A",
                }
            }
        },
    },
    "& .file_upload_btn": {
        paddingLeft: "4px",
    },
    "& .form__footer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0 28px",
        gap: "16px",
    },
    "& .cancelButton": {
        width: "107px",
        minWidth: "107px",
        backgroundColor: "#F1F4F5 !important",
        color: "#2EAB65",
        "&:disabled": {
            backgroundColor: "#B6D4E3",
        }
    },
    "& .submitButton": {
        height: "56px",
    },
});
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
// Customizable Area End