export enum UserType {
  Professional = 1,
  Facility = 2,
}
export type JobApplication = {
  id: number;
  role: string;
  location: string;
  time: string;
  status: "New" | "Viewed" | "Applied" | "Rejected" | "Canceled"
  description: string;
  facilityName: string;
}
