import React, { Component } from 'react';
import { Field, FieldProps } from 'formik';
import {
  Box,
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type CustomSelectProps = any & {
  label: string;
  optional?: boolean;
  optionalText?: string;
  options: {
    disable?: boolean; value: string | number; label: string | number;
  }[];
  isAutocomplete?: boolean;
  isMulti?: boolean;
  onChange?: (event: any) => void;
};

class CustomSelect extends Component<CustomSelectProps> {
  renderSelectField = ({ field, form, meta }: FieldProps) => {
    const { limitTags, label, options, isAutocomplete, isMulti, onChange, ...rest } = this.props;

    return (
      <SelectStyleWrapper>
        <Typography variant="body1" mb={0.5} className="label">
          {label} {(this.props.optional && <span style={{ fontWeight: 400 }}>{` ${this.props.optionalText ?? "(optional)"}`}</span>)}
        </Typography>

        {isAutocomplete ? (
          <Autocomplete
            {...field}
            {...rest}
            onInputChange={(_, newInputValue, reason) => {
              if (reason === "clear" || newInputValue === "") {
                form.setFieldValue(field.name, isMulti ? [] : "");
              }
            }}
            multiple={isMulti}
            options={options}
            className={`custom-select custom-autocomplete ${field.value === "" && "placeholderText"}`}
            getOptionDisabled={(option) =>
              option.disable
            }
            disableClearable
            autoHighlight
            disableCloseOnSelect={isMulti}
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.label ?? ''
            }
            value={
              isMulti
                ? options.filter((opt: any) => field.value?.includes(opt.value))
                : options.find((opt: any) => opt.value === field.value) || null
            }
            onChange={(_, newValue) => {
              onChange && onChange(newValue)
              form.setFieldValue(
                field.name,
                isMulti
                  ? newValue.map((item: any) => item.value)
                  : newValue?.value || ''
              )
            }

            }

            renderTags={(tagValue) => {
              const maxDisplay = limitTags ?? 2;
              const visibleTags = tagValue.slice(0, maxDisplay).map((option: any) => option.label);
              const remainingCount = tagValue.length - maxDisplay;

              return (
                <span className="multiple-text-values">
                  {remainingCount > 0
                    ? `${visibleTags.join(", ")} +${remainingCount}`
                    : visibleTags.join(", ")}
                </span>
              );
            }}

            limitTags={limitTags ?? 2}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ''}
              />
            )}
          />
        ) : (
          <FormControl fullWidth error={Boolean(meta.touched && meta.error)}>
            <Select
              {...field}
              {...rest}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={`custom-select ${field.value === "" && "placeholderText"}`}
              IconComponent={KeyboardArrowDownIcon}
              placeholder='abcd'
            >
              {options.map((option: any) => (
                <MenuItem key={option.value} value={option.value} disabled={option?.disable || false}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{meta.touched && meta.error ? meta.error : ""}</FormHelperText>
          </FormControl>
        )}

      </SelectStyleWrapper>
    );
  };

  render() {
    const { name } = this.props;
    return <Field name={name}>{this.renderSelectField}</Field>;
  }
}

const SelectStyleWrapper = styled(Box)({
  "& .label": {
    fontWeight: 700,
    lineHeight: "20px",
    marginBottom: "4px",
  },
  "& .MuiFormControl-root": {
    minHeight: "64px",
    marginBottom: "8px",
  },
  "& .custom-select": {
    height: "45px",
    padding: "10px 12px",
    borderRadius: "8px",
    backgroundColor: "#FFF",
    "&.placeholderText": {
      "& .MuiSelect-select": {
        opacity: "0.5",
      },
      "& .MuiAutocomplete-input": {
        opacity: "0.5",
      },
    },
    "&.custom-autocomplete": {
      padding: "0",
      marginBottom: "27px",
      "& .MuiOutlinedInput-root": {
        padding: "0px",
        "& .MuiAutocomplete-input": {
          padding: "11px 12px",
          fontSize: "16px",
          lineHeight: 1,
          color: "#0F172A",
          fontWeight: "400",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E2E8F0",
        },
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2EAB65",
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f !important",
      },

      "& .multiple-text-values": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: 1,
        paddingLeft: "11px",
      }
    },
    "& .MuiSelect-select": {
      padding: "14px 32px 6px 0px",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#FFF",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E2E8F0",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E8F0",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E8F0",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E2E8F0",
    },
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f !important",
    },
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "0",
    marginTop: "0",
    color: "#d32f2f"
  },
});

export default CustomSelect;