// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import* as Yup from "yup";
import { FormikHelpers } from "formik";
import createRequestMessage from "../../../../blocks/utilities/src/create-request-message";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { ForgotPasswordErrorResponse, ForgotPasswordFormValues, ForgotPasswordSuccessResponse } from "./types";
import { isLoggedIn } from "../../../../components/src/components/utils/commonFunctions";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  message: string,
  openToast: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  forgotPasswordApiCallId: string = "";
  formikHelpersRef: Partial<FormikHelpers<ForgotPasswordFormValues>> = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openToast: false,
      message: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if(apiRequestCallId === this.forgotPasswordApiCallId) {
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const results = responseJson as ForgotPasswordSuccessResponse;
          this.setState({ openToast: true, message: results.message });
          if (this.formikHelpersRef.resetForm) this.formikHelpersRef.resetForm({ values: this.initialValues });
        },
        onFail: () => {
          if (this.formikHelpersRef.setErrors) this.formikHelpersRef.setErrors((responseJson as ForgotPasswordErrorResponse).errors[0]);
          if (this.formikHelpersRef.setSubmitting) this.formikHelpersRef.setSubmitting(false);
        },
      });
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    isLoggedIn(this.props)
  }

  initialValues = {
    email: "",
  } as ForgotPasswordFormValues

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  closeToastNotification = () => this.setState({ message: "", openToast: false})

  handleSubmit = (values: ForgotPasswordFormValues, { setErrors, setSubmitting, resetForm }: FormikHelpers<ForgotPasswordFormValues>) => {

    this.formikHelpersRef = {
      setErrors,
      setSubmitting,
      resetForm
    }

    const payload = {
      data: {
        type: "otp",
        attributes: {
          email: values.email,
        },
      },
    };

    const requestMessage  = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.forgotPasswordApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.forgotPasswordApiEndPoint,
      method: "POST",
      body: JSON.stringify(payload),
    });

  }
  // Customizable Area End
}
