import React, { Component } from "react";
import { Box, Typography, Button } from "@mui/material";
import { toast } from "react-toastify";
import { height, lineHeight, styled } from "@mui/system";
import { FileUploadIcon } from "../assets";

interface FileUploadProps {
  maxFileSize?: number;
}

interface FileUploadState {
  selectedFile: File | null;
}

class DraggableFileUploader extends Component<FileUploadProps, FileUploadState> {
  maxFileSize = this.props.maxFileSize ?? (25 * 1024 * 1024);

  constructor(props: {}) {
    super(props);
    this.state = { selectedFile: null };
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size <= this.maxFileSize) {
      this.setState({ selectedFile: file });
    } else {
      toast.error("File size exceeds 25MB limit.");
    }
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.size <= this.maxFileSize) {
      this.setState({ selectedFile: file });
    } else {
      toast.error("File size exceeds 25MB limit.");
    }
  };

  render() {
    return (
      <CustomBox
        onDragOver={(e) => e.preventDefault()}
        onDrop={this.handleDrop}
      >
        <img 
          src={FileUploadIcon}
          className="upload-icon"
        />
        <Typography variant="h5" sx={{ mt: 1.5 }}>
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="text"
              className="upload-btn"
            >
              Click to Upload
            </Button>
          </label> or drag and drop
        </Typography>
        <Typography variant="caption">
          (Max. File size: 25MB)
        </Typography>
        <input
          type="file"
          style={{ display: "none" }}
          id="file-upload"
          onChange={this.handleFileChange}
        />
        {this.state.selectedFile && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {this.state.selectedFile.name}
          </Typography>
        )}
      </CustomBox>
    );
  }
}

const CustomBox = styled(Box)({
  border: "2px dashed #ccc",
  borderRadius: "8px",
  padding: "16px",
  textAlign: "center",
  backgroundColor: "#ffffff",
  "& .upload-icon" : {
    height: "44px",
    weight: "44px",
  },
  "& .upload-btn" : {
    padding: "0px",
    color: "#4768BE",
    lineHeight: "20px",
  }
});

export default DraggableFileUploader;
