import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Avatar,
  Grid,
  Divider,
  Button,
  IconButton,
  MenuItem,
  Popover
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import { settingIcon} from './assets';
import { 
  Formik, 
  Form, 
  FormikProps } from 'formik';
import dayjs from "dayjs";

const MainContent = styled("main")(({ theme }) => ({
  background:'#fff',
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: -88,
}));
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#27292A",
    },
    
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "28px",
      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "left",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";
import CustomInput from "../../../components/src/components/customFormComponents/CustomInput.web";
import CustomSelect from "../../../components/src/components/customFormComponents/CustomSelect.web";
import CustomCheckBox2 from "../../../components/src/components/customFormComponents/CustomCheckBox2.web";
import CustomSwitch from "../../../components/src/components/customFormComponents/CustomSwitch.web";
import SaveConfirmation from "../../../components/src/components/SaveConfirmationPopup.web";
import { 
  genderList, 
  yearsOfExperienceList 
} from "../../../components/src/components/utils/constant";
import { ProfessionalSettingsSchema } from "../../../components/src/components/utils/validationSchema";
import DashboardLayout from "../../../blocks/dashboard/src/components/DashboardLayout.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const workPreferences = [{ key: 'shift_type_full_time', name: "Full-time" }, { key: 'shift_type_part_time', name: "Part-time" },
    { key: 'shift_type_contract', name: "Contract" }];
    const workLocations = [{ key: 'work_location_hospitals', name: "Hospitals" },
    { key: 'work_location_clinics', name: "Clinics" }, { key: 'work_location_home_based_care', name: "Home-Based Care" },
    { key: 'work_location_home_telehealth', name: "Telehealth" }];
    const shiftPreferences = [{ key: 'shift_type_day', name: "Day" },
    { key: 'shift_type_night', name: "Night" }, { key: 'shift_type_rotational', name: "Rotational" }];
    // Customizable Area Start
    const RenderProfileSection = (formikProps: any) => {
      const { values, setFieldValue, errors, touched, setTouched } = formikProps;
      const nonKenya = values.country !== 'Kenya'
      return (
        <>
          <Box data-test-id="profile-section" sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: '#fff', minHeight: '88vh', }}>
            <Box
              sx={{
                backgroundColor: '#D1E1E9',
                padding: 2,
                borderRadius: 3,
                display: 'flex',
                justifyContent: 'space-between',
                height: 136
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <Avatar
                  src={this.state.profilePicture}
                  alt="Profile Picture"
                  sx={{ width: {sm: 96, xs: 50}, height: {sm: 96, xs: 50}, marginRight: 2 }}
                />
                <Box>
                  <Typography variant="h6" fontWeight={700} fontSize={"28px"} fontFamily={"Manrope"} lineHeight={"44px"}>
                    {values.full_name}
                  </Typography>
                  <Typography variant="h6" color="#30353B;">
                    {this.state.professional_title}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <IconButton aria-label="delete" color="primary" onClick={this.handleOpenSettings}>
                <img src={settingIcon}/>
              </IconButton>
              </Box>
              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseSettings}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#ffffff", 
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", 
                    borderRadius: "8px", 
                    minWidth: "160px", 
                  },
                }}
              >
                <Box sx={{ p: 1 }}>
                  <MenuItem onClick={this.triggerFileInput} sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                  Change photo</MenuItem>
                  <MenuItem onClick={this.handleCloseSettings} sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px", color: "red" }}>
                    Delete an account
                  </MenuItem>
                </Box>
              </Popover>
            </Box>
            <Box>
                  {(touched.profile_photo && errors.profile_photo) && (
                    <Typography  style={{ fontWeight: 400, color: "#DC2626", fontSize: "9px",lineHeight: '10px' }}>
                       <span>{errors["profile_photo"]}</span> <br />
                    </Typography>
                  )}
                </Box>
                  <Box sx={{ marginTop: 4 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <CustomInput
                         label={configJSON.fullNameLabel}
                         name={configJSON.fullName}
                         fullWidth
                      />
                      </Grid>
                      <Grid item xs={12} sm={6}> 
                  <CustomInput
                    name={configJSON.dateOfBirth}
                    label={configJSON.dateOfBirthLabel}
                    type="date"
                    fullWidth
                    dateProps={
                      {
                        withIcon: false,
                        minDate: dayjs().subtract(18, 'years'),
                        maxDate: dayjs(),
                        format: configJSON.dateFormate,
                      }
                    }
                  />
                      </Grid> 
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    name={configJSON.gender}
                    label={configJSON.genderLabel}
                    fullWidth
                    options={genderList}
                  />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                  <CustomSelect
                    name={configJSON.nationality}
                    label={configJSON.nationalityLabel}
                    fullWidth
                    options={this.state.nationalityList}
                  />
                      </Grid>
                      <Grid container spacing={2} style={{
                              marginTop: '10px'
                        }} sx={{ml: 1}}>
                        <Grid item xs={12} sm={6} >
                    <CustomSelect
                      fullWidth
                      name={configJSON.country}
                      label={configJSON.countryLabel}
                      options={this.state.countryList}
                      isAutocomplete={true}
                      onChange={(event: any) => {
                        const country = event?.value;
                        if (country !== 'Kenya') {
                          setFieldValue('county', '');
                          setFieldValue('county_preferences', []);
                        }
                      }}
                    />
                        </Grid>
                  <Grid item xs={12} sm={3} >
                    <CustomSelect
                      name={configJSON.county}
                      label={configJSON.countyLabel}
                      options={this.state.countyList}
                      isAutocomplete={true}
                      disabled={nonKenya}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <CustomInput
                      fullWidth
                      name={configJSON.city}
                      label={configJSON.cityLabel}
                    />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                  <CustomInput
                    fullWidth
                    name={configJSON.address}
                    label={configJSON.addressLabel}
                  />
                        <Typography style={{fontSize:'12px',fontWeight:400,color:'#768085',marginTop:-10}}>This address will be used for the calculation of the distance within your suitable radius you set that match the location of vacancies facilities</Typography>
                      </Grid>
                    </Grid>
                  </Box>
            <input
              type="file"
              name="profile_photo"
              id="profile-image-input"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(event) => { this.handleProfileChange(event, setFieldValue, setTouched) }}
            />
          </Box>
        </>
      )
    }
    const RenderLicensingCredentials = (formikProps: FormikProps<any>) => {
      return (    
          <BoxWrapper>
                      <Box>
                       <Typography variant="h1" align="left" data-test-id="welcome-label" mb={4}>
                       Licensing & Credentials
                       </Typography>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} >
                        <CustomSelect name={"professional_title_id"} 
                        label={"Professional title"} fullWidth options={this.state.professionalTitleList} />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <CustomSelect name={"speciality_id"} 
                        label={"Speciality (Optional)"} fullWidth options={this.state.specialityList} />
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} >
                        <CustomSelect name={"education_certification_id"} 
                        label={"Education / Certifications"} fullWidth options={this.state.eduCertiList} />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <CustomSelect name={"year_of_expirience"} 
                        label={"Years of experience "} fullWidth options={yearsOfExperienceList} />
                      </Grid>
                  </Grid>
                       <Grid container spacing={2}>
                         <Grid item xs={12} sm={6}>
                         <CustomInput  name="professional_indemnity" label="Professional indemnity" fullWidth />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <CustomInput  name="professional_licence_number" label="licenseNumner" fullWidth  />
                         </Grid>
                       </Grid>
                       <Grid container spacing={2}>
                         <Grid item xs={12} sm={6}>
                      <CustomSelect
                        name={'licensing_authority_id'}
                        label={'Licensing authority'}
                        fullWidth
                        options={this.state.licensingAuthList}
                      />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                      <CustomInput
                    name={'licence_ex_date'}
                    label={'License expiry date'}
                    type="date"
                    fullWidth
                    dateProps={
                      {
                        withIcon: false,
                       // minDate: dayjs().subtract(18, 'years'),
                        maxDate: dayjs(),
                        format: configJSON.dateFormate,
                      }
                    }
                  />
                         </Grid> 
                         <Grid sx ={{my: 1}} xs={12}>
                         <Divider/>
                         </Grid>
                         <Grid item sx ={{my: 2}} xs={12} sm={6}>
                         <CustomInput  label="KRA PIN Number" name="kra_pin_number" fullWidth  />
                         </Grid>
                         <Grid item  sx ={{my: 2}} xs={12} sm={6}>
                         <CustomInput  label="NSSF Number" name="nssf_number" fullWidth  />
                         </Grid>
                       </Grid>  
                     </Box>
        </BoxWrapper>
      )
    } 
    const RenderAvailability = (formikProps: FormikProps<any>) => {
      const {values} = formikProps
      const nonKenya = values.country !== 'Kenya'
      return (
        <BoxWrapper>
                  <Typography variant="h1" align="left" data-test-id="welcome-label" mb={4}>
                  Availability & Work Preferences
                </Typography>
                <Box sx={{ p: 3, mb: 2, backgroundColor: "#F1F4F5" }} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h5"  sx={{  fontWeight: 'bold', fontSize: "20px", fontFamily: 'Manrope' }}>
                    Current availability status</Typography>
                  <Box display="flex" alignItems="center" gap={2} sx={{minHeight: '40px'}}>
                    <Typography variant="h6">Available for work</Typography>
                    <CustomSwitch name="current_availability_status" />
                  </Box>
                </Box>
                <Box sx={{ p: 3, mb: 2, backgroundColor: "#F1F4F5" }}>
                  <Typography fontWeight="bold" mb={1}>Shift type availability</Typography>
                  {workPreferences.map((type, index) => (
                    <CustomCheckBox2
                   // key={type.key} 
                    name={type.key} 
                    label={type.name}
                   />
                  ))}
                </Box>
                <Box sx={{ p: 3, mb: 2, backgroundColor: "#F1F4F5" }}>
                  <Typography fontWeight="bold" mb={1}>Preferred work location settings</Typography>
                  {workLocations.map((location, index) => (
                   <CustomCheckBox2
                   key={location.key} 
                   name={location.key} 
                   label={location.name} 
                  />
                  ))}
                  <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6} >
                        <CustomSelect name={'county_preferences'} 
                        label={'County preferences'} 
                        fullWidth 
                        options={this.state.countyList}  
                        isAutocomplete={true}
                        isMulti={true}
                        disabled={nonKenya}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomSelect 
                        name={'telehealth_availability_id'} 
                        label={'Telehealth availability'} 
                        fullWidth 
                        options={this.state.telehealthAvailability}  />
                      </Grid>
                  </Grid>
                </Box>
                <Box sx={{ p: 3, mb: 2, backgroundColor: "#F1F4F5" }}>
                  <Typography fontWeight="bold" mb={1}>Shift type preference</Typography>
                  {shiftPreferences.map((pref, index) => (
                    <CustomCheckBox2
                    key={pref.key} 
                    name={pref.key} 
                    label={pref.name} 
                   />
                  ))}
                </Box>
                <Box sx={{ p: 3, backgroundColor: "#F1F4F5" }}>
                  <CustomInput  fullWidth  label="Desired Hourly/Shift Rate (optional) " name="Desired Hourly/Shift Rate (optional) "/>
                </Box>
        </BoxWrapper>
      )
    }
    const RenderNotificationPrefernces = () => {
      return (
        <BoxWrapper>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: 2.5, background: "#F1F4F5", borderRadius: 2 }}>
        <Typography variant="h1" align="left" data-test-id="welcome-label">
            Notification preferences
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body2" sx={{ color: '#0F172A', fontWeight: 400 }}>
            I wish to receive notifications
          </Typography>
          <CustomSwitch name="hello" checked={true} onChange={() => {}} />
            </Box>
        </Grid>
        <Box sx={{ml: 2}}>
        <Box sx={{ marginTop: 3, pr:2 }}>
          <Typography variant="body1" sx={{}}>
            Always send an email notification
          </Typography>
          <Typography variant="body2" sx={{  marginBottom: 1, fontWeight: 400, ineHeight: "21px" }}>
            Receive emails even if you are active on the app
          </Typography>
          <Grid container alignItems="center" justifyContent="space-between">
          <Box sx={{ display: 'flex', gap: 5, marginTop: 1 }}>
               <CustomCheckBox2
                    key={'New matched vacancies'} 
                    name={'New matched vacancies'} 
                    label={'New matched vacancies'} 
                    labelSize="14px"
                   />
              <CustomCheckBox2
                    name={'Vacancies requests'} 
                    label={'Vacancies requests'} 
                    labelSize="14px"
                   />
            </Box>
            <CustomSwitch name="hello" checked={true} onChange={() => {}} />
          </Grid>
        </Box>
          <Box sx={{ marginTop: 2, borderTop: "1px solid #CBD5E1", paddingTop: 2, pb: 3, pr:2, borderBottom: "1px solid #CBD5E1", }}>        
          <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: 1 }}>
            <Grid> 
          <Typography variant="body1" sx={{ fontWeight: 700,  }}>
            Security alerts
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 400, lineHeight: "21px" }}>
            Get notified about important security alerts, such as password reset
          </Typography>
          </Grid>
            <CustomSwitch  name="hello"checked={true} onChange={() => {}} />
          </Grid>
        </Box>
        </Box>
        </BoxWrapper>
      )
    }
    const RenderProfessionalBodyMemberships = () => {
      return (    
          <BoxWrapper>
                     <Box className="form__main__data">
                       <Typography variant="h1" align="left" data-test-id="welcome-label" mb={4}>
                       Professional Body Memberships
                       </Typography>
                       <Grid container spacing={2}>
                         <Grid item xs={12} sm={6}>
                         <CustomInput  label="Professional body memberships (optional)" name="professional_body_membership" fullWidth  />
                         </Grid>
                         <Grid item xs={12} sm={6}>
                         <CustomInput  label="SHIF number (optional)" name="shif_number" fullWidth  />
                         </Grid>
                       </Grid>
                     </Box>      
        </BoxWrapper>
      )
    }
    const RenderSecurity = () => {
      const securityItems = [
        { title: "Email address", value: "christina.theodoridou@example.com", action: "Modify" },
        { title: "Phone number", value: "+254 0645533111", action: "Modify" },
        { title: "Password", value: "Last update 12/09/2024", action: "Reset" },
      ];
       return (
        <BoxWrapper>
                  <Typography variant="h1" align="left" data-test-id="welcome-label" mb={4}>
                  Security
                </Typography>
                {securityItems.map((item, index) => (
        <Grid
          key={index}
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: "#F0F4F7",
            borderRadius: "12px",
            padding: 2,
            marginBottom: 1
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 700}}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 400, color:'#768085' }}>
              {item.value}
            </Typography>
          </Box>
          <Button sx={{ color: "#1F9D55", fontWeight: 600, textTransform: "none" }}>{item.action}</Button>
        </Grid>
      ))}
        </BoxWrapper>
      )
    }
    const handleSubmit = (values: any) => {
      this.setState({
        formData: values, // Update the state with the form values
      });
    };
    const {currentSettingsTab}=this.state

   
    return (
      <>   
        <Formik
            data-test-id="formik"
            initialValues={this.state.formData}
            validationSchema={ProfessionalSettingsSchema}
            enableReinitialize={true}
            onSubmit={this.handleSubmit}
          >
            {(formikProps) => {
               if (!this.state.showSaveModal && formikProps.isValid && formikProps.dirty) {
                this.setState({ showSaveModal: true });
              }
            return(
              <Form className="form__wrapper">
                <ThemeProvider theme={theme}>
                  <DashboardLayout
                    navigation={this.props.navigation}
                    filtredServicesList={this.state.filtredServicesList}
                    selectedService={this.state.selectedService}
                    handleSetService={this.handleSetService}
                  >
                    <Box sx={{ display: 'flex', flex: 1, }}>
                      <MainContent >
                        {currentSettingsTab === 0 && RenderProfileSection(formikProps)}
                        {currentSettingsTab === 1 && RenderLicensingCredentials(formikProps)}
                        {currentSettingsTab === 2 && RenderProfessionalBodyMemberships()}
                        {currentSettingsTab === 3 && RenderAvailability(formikProps)}
                        {currentSettingsTab === 4 && RenderNotificationPrefernces()}
                        {currentSettingsTab === 5 && RenderSecurity()}
                      </MainContent>
                    </Box></DashboardLayout>
                </ThemeProvider>

                <SaveConfirmation
                  open={formikProps.dirty}
                  onClose={this.handleCloseModal}
                  formikVal={formikProps?.values}
                  handleSave={() => this.handleSubmit(formikProps.values, formikProps.resetForm)}
                  onReset={() => this.handleDiscardChanges(formikProps.resetForm)}
                  successSnackbarOpenProps={this.state.isDataSaved}
                />
              </Form>
            )}}
        </Formik>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  width: "100% !important",
  maxWidth: "none !important",
});
// Customizable Area End
