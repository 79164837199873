import React, { Component, MouseEvent } from "react";
import {
  Box,
  Typography,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/system";

// Define the expected prop types
interface ContactDetails {
  name: any;
  primary?: boolean;
  phone: string;
  email: string;
  kraPin: string;
}

interface ContactDetailsCardState {
  anchorEl: HTMLElement | null;
}

// Styled component for the contact card header
const ContactCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#D1E1E9",
  height: "56px",
  padding: theme.spacing(2),
  borderRadius: "8px 8px 0 0",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

class ContactDetailsCard extends Component<ContactDetails, ContactDetailsCardState> {
  constructor(props: ContactDetails) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpenSettings = (event: MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { name, primary, phone, email, kraPin } = this.props;
    const { anchorEl } = this.state;

    return (
      <Box sx={{ backgroundColor: "#F1F4F5", borderRadius: 2, my: 2, pt: 0.8, pl: 0.8, pr: 0.8 }}>
        <ContactCard>
          {/* Name and Primary Badge */}
          <Box display="flex" alignItems="center">
            <Typography fontSize="16px">{name}</Typography>
            {primary && (
              <Box sx={{ backgroundColor: "#73B2D1", borderRadius: "26px", px: 1.5, ml: 1, py: 0.4 }}>
                <Typography fontSize={12} fontWeight={700} color="white">
                  Primary
                </Typography>
              </Box>
            )}
          </Box>

          {/* Modify Button & Actions */}
          <Box display="flex" alignItems="center">
            {primary ? (
              <Typography sx={{ color: "#2EAB65", cursor: "pointer", fontWeight: "800", fontSize: "16px" }}>
                Modify
              </Typography>
            ) : (
              <>
                <IconButton onClick={this.handleOpenSettings}>
                  <MoreVertIcon />
                </IconButton>
                <Popover
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={this.handleClose} sx={{fontSize: "14px", fontWeight: "400", py: 1, px: 2}}>Modify</MenuItem>
                  <MenuItem onClick={this.handleClose} sx={{fontSize: "14px", fontWeight: "400", py: 1, px: 2}}>Set as primary</MenuItem>
                  <MenuItem onClick={this.handleClose} sx={{fontSize: "14px", fontWeight: "400", py: 1, px: 2}}>Send an invitation to email</MenuItem>
                  <MenuItem onClick={this.handleClose} sx={{ color: "red", fontSize: "14px" , fontWeight: "400", py: 1, px: 2}}>
                    Delete contact
                  </MenuItem>
                </Popover>
              </>
            )}
          </Box>
        </ContactCard>

        {/* Contact Information */}
        <Box sx={{ padding: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontSize="14px" fontWeight={700}>
              Phone number
            </Typography>
            <Typography fontSize="14px" fontWeight={400} sx={{ pl: 1 }}>
              {phone}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontSize="14px" fontWeight={700}>
              Email
            </Typography>
            <Typography fontSize="14px" fontWeight={400} sx={{ pl: 1 }}>
              {email}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontSize="14px" fontWeight={700}>
              Facility Administrator KRA PIN
            </Typography>
            <Typography fontSize="14px" fontWeight={400} sx={{ pl: 1 }}>
              {kraPin}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default ContactDetailsCard;
