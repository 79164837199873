import { getToken } from "../../../../components/src/components/utils/commonFunctions";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  textDetails: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      textDetails: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchPrivacyPolicy();
    // Customizable Area End
  }

  // Customizable Area Start
  pdfDownload = () => {
    const iframeElem = document.createElement("iframe") as HTMLIFrameElement;
    iframeElem.style.display = "none";
    document.body.appendChild(iframeElem);

    if (!iframeElem.contentWindow) return;
    const iframeDocument =
      iframeElem.contentDocument || iframeElem.contentWindow.document;

    const contentDiv = iframeDocument.createElement("div");

    const printContent = document.querySelector(
      '[data-text-pdf="true"]'
    ) as HTMLElement;

    if (!printContent) return;
    contentDiv.innerHTML = printContent.innerHTML;

    const styleIframe = iframeDocument.createElement("style");
    styleIframe.textContent = `
      body {
        font-family: Arial, sans-serif; 
        font-size: 12px; 
        margin: 1in; 
      }
    `;

    iframeDocument.head.appendChild(styleIframe);
    iframeDocument.body.appendChild(contentDiv);
    iframeElem.contentWindow.print();
    document.body.removeChild(iframeElem);
  };

  redirect = (
    routeName: "SignUpStepOne" | "TermsConditionsWeb" | "PrivacyPolicyWeb" | "CustomisableUserProfiles"
  ) => {
    const newMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    newMessage.addData(getName(MessageEnum.NavigationTargetMessage), routeName);
    newMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(newMessage);
  };
  handleBackPress =()=> {
    if(this.isLoggedIn()){
      this.redirect("CustomisableUserProfiles")
    }else {   
     this.redirect("SignUpStepOne")
    }
   }

   isLoggedIn =()=> {
      return getToken()
    }
  
  fetchPrivacyPolicy = () => {
    this.setState({ isLoading: true });

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/privacy_policies"
    );
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
    }
    this.setState({
      textDetails: responseJson.data.description,
      isLoading: false,
    });
    // Customizable Area End
  }
}
