import React from "react";
// Customizable Area Start
import { Alert, Avatar,Select, MenuItem, Box, Button, Card, Chip, Container, CssBaseline, Grid, IconButton, Paper, Snackbar, styled, ToggleButton, ToggleButtonGroup, Typography, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserType } from "../../../../packages/blocks/utilities/src/enums";
import { FacilityIcon, Location, Profile } from "./assets";
import DashboardLayout from "./components/DashboardLayout.web";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
      light: "#4DD8B5",
      dark: "#007A5E",
    },
    text: {
      primary: "#27292A",
      secondary: "#27292A",
    },
    background: {
      default: "#F0F4F7",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: "28px",
      color: "#0F172A",
    },
    h3: {
      fontSize: "18px",
      fontWeight: 700,
      color: "#0F172A",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    h5: {
      fontSize: "14px",
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#27292A",
    },
    subtitle2: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 700,
      textTransform: "capitalize",
      "&.new": {
        color: "#73B2D1",
      },
      "&.viewed, &.canceled": {
        color: "#768085",
      },
      "&.rejected": {
        color: "#DC2626"
      },
      "&.applied": {
        color: "#2EAB65"
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1440px !important",
          paddingLeft: "16px",
          paddingRight: "16px",
          "@media (min-width: 960px)": {
            paddingLeft: "40px",
            paddingRight: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        text: {
          borderRadius: "none",
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          padding: "4px 8px",
          fontSize: "12px",
          color: "#27292A",
          fontWeight: 500,
          maxHeight: "28px",
        },
        label: {
          padding: 0,
          lineHeight: "18px",
        },
        filled: {
          backgroundColor: "#F1F4F5",
          border: "none",
        },
        outlined: {
          backgroundColor: "#EFFDFF",
          border: "solid 1px #B6D4E3",
        },
        icon: {
          marginLeft: 0,
          marginRight: "4px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "solid 1px #D6DEEA",
        },
      },
    },
  },
});

const shifts = [
  {
    "id": 1,
    "name": "Occupational Therapist",
    "timing": "Full-time",
    "type": "Night / Day / Rotational",
    "rateType": "Shift",
    "rate": "$200",
    "postedDate": "1d ago"
  },
  {
    "id": 2,
    "name": "Physical Therapist",
    "timing": "Full-time",
    "type": "Night / Day / Rotational",
    "rateType": "Hourly",
    "rate": "D $40 / N $60",
    "postedDate": "1d ago"
  },
  {
    "id": 3,
    "name": "Dentist",
    "timing": "Full-time",
    "type": "Night / Day / Rotational",
    "rateType": "Shift",
    "rate": "$200",
    "postedDate": "1d ago"
  },
  {
    "id": 4,
    "name": "Dentist",
    "timing": "Full-time",
    "type": "Night / Day / Rotational",
    "rateType": "Shift",
    "rate": "$200",
    "postedDate": "1d ago"
  }
]

const applicants = [
  {
    "id": 1,
    "name": "Liam Walker",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "New",
  },
  {
    "id": 2,
    "name": "Christopher Thompson",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "Viewed",
  },
  {
    "id": 3,
    "name": "James Turner",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "Viewed",
  },
  {
    "id": 4,
    "name": "Isabella Bennett",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "Viewed",
  },
  {
    "id": 5,
    "name": "Isabella Bennett",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "Viewed",
  },
  {
    "id": 6,
    "name": "Isabella Bennett",
    "role": "ER Nurse",
    "location": "Kenya",
    "time": "3d ago",
    "status": "Viewed",
  }
]

type JobApplication = {
  id: number;
  role: string;
  location: string;
  time: string;
  status: "New" | "Viewed" | "Applied" | "Rejected" | "Canceled"
  description: string;
  facilityName: string;
}

const newApplications: JobApplication[] = [
  {
    "id": 2,
    "role": "Paramedic",
    "location": "South Africa",
    "time": "1d ago",
    "status": "Viewed",
    "description": "Respond to emergency calls, provide first aid and pre-hospital care, and transport patients safely.",
    "facilityName": "Hope Ambulance Services"
  },
  {
    "id": 3,
    "role": "General Practitioner",
    "location": "United Kingdom",
    "time": "5d ago",
    "status": "New",
    "description": "Diagnose and treat illnesses, provide preventive care, and manage chronic conditions in a clinical setting.",
    "facilityName": "Greenwood Medical Centre"
  },
  {
    "id": 4,
    "role": "Pharmacist",
    "location": "Canada",
    "time": "2d ago",
    "status": "Canceled",
    "description": "Dispense medications, counsel patients on drug usage, and ensure compliance with regulations.",
    "facilityName": "Maple Leaf Pharmacy"
  },
  {
    "id": 5,
    "role": "Radiologist",
    "location": "India",
    "time": "4d ago",
    "status": "Rejected",
    "description": "Interpret medical imaging to diagnose and manage patient conditions, collaborate with healthcare teams.",
    "facilityName": "Sunrise Diagnostic Centre"
  },
  {
    "id": 6,
    "role": "Pediatric Nurse",
    "location": "Australia",
    "time": "3h ago",
    "status": "Applied",
    "description": "Provide compassionate care to children, administer medications, and educate families about health conditions.",
    "facilityName": "Bright Futures Children's Hospital"
  },
  {
    "id": 7,
    "role": "Surgical Technician",
    "location": "United States",
    "time": "6d ago",
    "status": "Applied",
    "description": "Assist surgeons during procedures, maintain a sterile environment, and prepare surgical instruments.",
    "facilityName": "Atlantic Surgical Centre"
  },
  {
    "id": 8,
    "role": "Lab Technician",
    "location": "Germany",
    "time": "8h ago",
    "status": "New",
    "description": "Perform diagnostic tests, analyze samples, and ensure accuracy in laboratory reports.",
    "facilityName": "Berlin Diagnostic Labs"
  },
  {
    "id": 9,
    "role": "Cardiologist",
    "location": "France",
    "time": "7d ago",
    "status": "Viewed",
    "description": "Specialize in diagnosing and treating heart conditions, perform tests, and recommend treatments.",
    "facilityName": "Heart Care Institute"
  },
  {
    "id": 10,
    "role": "Occupational Therapist",
    "location": "New Zealand",
    "time": "12h ago",
    "status": "New",
    "description": "Help patients develop skills to manage daily activities and regain independence after illness or injury.",
    "facilityName": "Kiwi Rehabilitation Centre"
  }
]




// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFacitityDashboard() {
    return (
      <FacilityWrapper className="FacilityWrapper">
        <Typography variant="h2" mb={5} mt={2}>
          {webConfigJSON.facilityDashboardTitle}
          <b>{webConfigJSON.facilityDashboardSubTitle}</b>
        </Typography>
        <Grid container spacing={2.5}>
          <Grid item xs={4}>
            <Card className="card" elevation={0}>
              <Box className="card__title" mb={1}>
                <Typography variant="h3">{webConfigJSON.sidebardCardTitle}</Typography>
                <Button className="new__btn" variant="text">
                  {webConfigJSON.addNewShiftLabel}
                </Button>
              </Box>

              {shifts.map((shift) => (
                <Box className="shift__card" key={shift.id} p={2}>
                  <Box className="primary__info">
                    <Box className="primary__content">
                      <Typography component="span" variant="h4">
                        {shift.name}
                        &nbsp;&nbsp;
                      </Typography>
                      <Typography component="span" className="posting__time">
                        {shift.postedDate}
                      </Typography>
                    </Box>
                    <IconButton className="more__btn">
                      <MoreVertIcon />
                    </IconButton>
                  </Box>

                  <Box className="chip__wrapper">
                    <Chip label={shift.timing} className="chip timing" />
                    <Chip label={shift.type} className="chip" />
                    <Chip
                      label={
                        <>
                          <span className="rate__type">{shift.rateType}</span>
                          &nbsp;
                          <span>{shift.rate}</span>
                        </>
                      }
                      className="chip"
                    />
                  </Box>
                </Box>
              ))}
              {this.renderPagination()}
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2.5}>
              {applicants.map((applicant) => (
                <Grid item xs={6} className="applicant__card__wrapper" key={applicant.id}>
                  <Box className="applicant__card">
                    <Box className="applicant__primary__info">
                      <Avatar src={Profile} className="applicant__avatar" />
                      <Box flex={1}>
                        <Typography variant="h4">{applicant.name}</Typography>
                        <Typography variant="subtitle1">{applicant.role}</Typography>
                      </Box>
                      <Typography component="span" variant="subtitle2" className={applicant.status}>
                        {applicant.status}
                      </Typography>
                    </Box>

                    <Box className="applicant__secondary__info">
                      <Box className="chip__wrapper">
                        <Chip
                          className="applicant__chip"
                          label={
                            <Box className="location">
                              <img src={Location} />
                              <Typography component="span" variant="subtitle2">
                                {applicant.location}
                              </Typography>
                            </Box>
                          }
                        />
                        <Chip className="applicant__chip" label={applicant.time} />
                      </Box>
                      <Button className="review__btn" variant="contained" color="primary" onClick={()=> this.handleReviewClick()}>{webConfigJSON.reviewBtnLabel}</Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FacilityWrapper>
    );
  }
  renderPagination(){
    return (
      <Box display="flex" justifyContent='end' alignItems='flex-end' gap={2} sx={{ p: 2, borderRadius: "8px" }}>
        <Select
          value={6}
          variant="outlined"
          size="small"
          sx={{ minWidth: 100 }}
        >
          <MenuItem value={6}>6 per page</MenuItem>
        </Select>
  
        <Box display="flex" alignItems="center" border="1px solid #ddd" borderRadius="6px" p="4px 10px">
          <TextField
            value={this.state.currentPage}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (value >= 1 && value <= 5) this.setState({currentPage: value});
            }}
            variant="outlined"
            size="small"
            sx={{ width: 40, textAlign: "center", "& input": { textAlign: "center", p: "4px" } }}
          />
          <Typography sx={{ mx: 1 }}>of {5}</Typography>
        </Box>
  
        <IconButton onClick={this.handlePrevious} disabled={this.state.currentPage === 1}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={this.handleNext} disabled={this.state.currentPage === 5}>
          <ChevronRight />
        </IconButton>
      </Box>
    );
  }

  handlePrevious = () => {
    if (this.state.currentPage > 1) this.setState({currentPage : this.state.currentPage - 1});
  };

   handleNext = () => {
    if (this.state.currentPage < 5) this.setState({currentPage : this.state.currentPage + 1});
  };

  renderProfessionalDashboard() {
    return (
      <ProfessionalWrapper>
        <Box  className={"top-header"} mb={5} mt={2}>
          <Typography flex={1} variant="h2" alignItems="center">
            <b>{webConfigJSON.greetings}</b>
            Christina
            <b>{webConfigJSON.professtionalTitle}</b>
          </Typography>

          <ToggleButtonGroup
            value={"new"}
            exclusive
            // onChange={(event, newStatus) => newStatus && setStatus(newStatus)}
            aria-label="status toggle"
            className="toggle__btn__group"
          >
            <StyledToggleButton value="new">New</StyledToggleButton>
            <StyledToggleButton value="applied">Applied</StyledToggleButton>
            <StyledToggleButton value="rejected">Rejected</StyledToggleButton>
            <StyledToggleButton value="cancelled">Cancelled</StyledToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Grid container spacing={2.5} pb={3}>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2.5}>
              {newApplications.map(application => this.professionalCard(application, true))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            {this.renderWorkPreferences()}
          </Grid>
        </Grid>
      </ProfessionalWrapper>
    );
  }

  renderWorkPreferences() {
    return (
      <WorkPreferencesWrapper elevation={0}>
        <Box className="header__title">
          <Typography variant="h3">{webConfigJSON.workPreferencesLabel}</Typography>
          <Button className="edit__btn" variant="text">
            {webConfigJSON.editLabel}
          </Button>
        </Box>

        <Box className="content__wrapper" display="flex" flexDirection="column" gap={3}>
          <Box component="section">
            <Typography variant="h5" className="section__title" marginBottom={0.75}>
              {webConfigJSON.yourLocation}
            </Typography>
            <Chip label="Kenya" icon={<img src={Location} />} />
          </Box>

          <Box component="section">
            <Typography variant="h5" className="section__title">
              {webConfigJSON.availibilityStatus}
            </Typography>
            <Typography variant="h4" className="preference__label">
              Available
            </Typography>
          </Box>

          <Box component="section">
            <Typography variant="h5" className="section__title" marginBottom={0.75}>
              {webConfigJSON.shiftAvailibilityStatus}
            </Typography>

            <Box display="flex" gap="8px" flexWrap="wrap">
              <Chip label="Full-time" />
              <Chip label="Part-time" />
              <Chip label="Contract" />
            </Box>
          </Box>

          <Box component="section">
            <Typography variant="h5" className="section__title" marginBottom={0.75}>
              {webConfigJSON.preferredWork}
            </Typography>

            <Box display="flex" gap="8px" flexWrap="wrap">
              <Chip label="Hospitals" />
              <Chip label="Clinics" />
              <Chip label="Home-Based Care" />
              <Chip label="Telehealth" />
            </Box>
          </Box>

          <Box component="section">
            <Typography variant="h5" className="preference__label">
              {webConfigJSON.travelRadius}
              {"5 km"}
            </Typography>

            <Typography variant="h5" className="preference__label">
              {webConfigJSON.telehealthAvailibility}
              {"9 AM - 6 PM"}
            </Typography>
          </Box>

          <Box component="section">
            <Typography variant="h5" className="section__title" marginBottom={0.75}>
              {webConfigJSON.shiftPreferences}
            </Typography>

            <Box display="flex" gap="8px" flexWrap="wrap">
              <Chip label="Day" />
              <Chip label="Night" />
              <Chip label="Rotational" />
            </Box>
          </Box>

          <Box component="section">
            <Typography variant="h5" className="section__title">
              {webConfigJSON.rateLabel}
              <Typography component="span" variant="subtitle2" fontWeight="400">{"(Optional) "}</Typography>
            </Typography>
            <Typography variant="h4" className="preference__label">
              $40
            </Typography>
          </Box>
        </Box>
      </WorkPreferencesWrapper>
    );
  }

  professionalCard(application: JobApplication, isNew: boolean) {
    return (
      <Grid item xs={12} md={6}>
        <Paper elevation={0} className="application__card__wrapper" onClick={() => this.handleCardClick(application)}>
          <Box className="primary__info">
            <Box className="primary__content">
              <Typography variant="h3" component="span" className="card__title">
                {application.role}
                &nbsp;&nbsp;
              </Typography>
              <Typography className="posting__time" component="span">
                {application.time}
              </Typography>
            </Box>
            {isNew && (
              <Typography component="span" variant="subtitle2" className={`application__status ${application.status}`}>
                {application.status}
              </Typography>
            )}
          </Box>

          <Typography variant="h5" className="application__description">
            {application.description}
          </Typography>

          <Box className="card__footer" mt={2.25}>
            <Box display="flex" gap="6px">
              <Chip label={application.facilityName} icon={<Box component="img" pr={0.25} src={FacilityIcon} />} variant="outlined"/>
              <Chip label={application.location} icon={<img src={Location} />} />
            </Box>
            {
              isNew ? (
                <Button className="apply__btn" variant="contained">{webConfigJSON.applyBtnLabel}</Button>
              ) : (
                <Typography component="span" variant="subtitle2" className={`application__status ${application.status}`}>
                {application.status}
              </Typography>
              )
            }
            
          </Box>
        </Paper>
      </Grid>
    );
  }
  renderAlertPopUp(isShow:boolean) {
    return (
      <Snackbar
        open={isShow}
        autoHideDuration={3000} // Auto-hide after 3 seconds
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={()=>{isShow= false}}
      >
        <Alert
          severity="success"
          variant="filled"
          icon={<CheckCircleIcon fontSize="inherit" sx={{color:'#34D399'}} />}
          sx={{ backgroundColor: "white", color: "black", borderRadius: "8px", boxShadow: 3 }}
        >
          {"You have successfully applied for the position of Occupational Therapist."}
        </Alert>
      </Snackbar>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <DashboardLayout navigation={this.props.navigation}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
           <Container>{this.state.userType === UserType.Facility ? this.renderFacitityDashboard() : this.renderProfessionalDashboard()}</Container>
           {this.renderPagination()}
          </ThemeProvider>
        </DashboardLayout>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const FacilityWrapper = styled(Box)({
  left:30,
  right:30,
  "& .card": {
    border: "1px solid #D6DEEA",
    borderRadius: "12px",
    padding: "12px",
    "& .card__title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 16px",
      backgroundColor: "#F0F4F7",
      borderRadius: "12px 12px 0 0",
      "& .new__btn": {
        paddingRight: "0"
      }
    },
    "& .shift__card": {
      border: "1px solid #D6DEEA",
      borderRadius: "12px",
      padding: "14px 12px 12px",
      marginBottom: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      "& .primary__info": {
        display: "flex",
        "& .primary__content": {
          flex: 1,
          "& .posting__time": {
            fontSize: "12px",
            position: "relative",
            fontWeight: 300,
            top: "-1px",
            color: "#27292A"
          }
        },
        "& .more__btn": {
          padding: 0
        }
      },
      "& .chip__wrapper": {
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        marginTop: "12px",
        "& .chip": {
          border: "solid 1px #F1F4F5",
          background: "#F1F4F5",
          "&.timing": {
            borderColor: "#B6D4E3",
            background: "#EFFDFF",
          },
          "& .rate__type": {
            color: "#768085"
          }
        }
      },
      "&.active": {
        border: "solid 1px #B6D4E3",
        backgroundColor: "#EFFDFF",
        "& .chip": {
          background: "#FFFFFF",
          borderColor: "#FFFFFF"
        }
      }
    }
  },
  "& .applicant__card__wrapper": {
    "& .applicant__card": {
      borderRadius: "16px",
      backgroundColor: "#FFFFFF",
      padding: "16px",
      border: "1px solid #D6DEEA",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      "& .applicant__primary__info": {
        display: "flex",
        gap: "12px",
        "& .applicant__avatar": {
          border: "solid 2px #E2E8F0",
          height: "48px",
          width: "48px"
        }
      },
      "& .applicant__secondary__info": {
        display: "flex",
        gap: "8px",
        marginTop: "12px",
        "& .chip__wrapper": {
          flex: 1,
          display: "flex",
          gap: "8px",
          "& .applicant__chip": {
            "& .location": {
              display: "flex",
              gap: "4px",
              alignItems: "center",
              "& > img": {
                height: "16px"
              }
            }
          }
        },
        "& .review__btn": {
          border: "none",
          borderRadius: "6px",
          color: "#FFF",
          fontSize: "14px",
          fonrWeight: 700,
          padding: "4px 8px",
          maxHeight: "28px",
          background: '#2EAB65'
        }
      }
    }
  }
})

const ProfessionalWrapper = styled(Box)({
  "& .toggle__btn__group": {
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#F8FAFC",
    padding: "4px",
    maxHeight: "48px",
  },
  "& .top-header": {
    display: "flex",
    "@media (max-width: 768px)": {
      flexDirection: "column"
    },
  },
  "& .application__card__wrapper": {
    borderRadius: "12px",
    padding: "14px 16px 16px",
    "& .primary__info": {
      display: "flex",
      justifyContent: "space-between",
      "& .primary__content": {
        "& .card__title": {
          lineHeight: "24px",
        },
        "& .posting__time": {
          position: "relative",
          top: "-1px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#768085",
          fontWeight: 500,
        },
      },
    },
    "& .application__description": {
      lineHeight: "20px",
      maxWidth: "93%",
      color: "#30353B",
      marginTop: "2px",
    },
    "& .application__status": {
      position: "relative",
      top: "4px",
    },
    "& .card__footer": {
      display: "flex",
      gap: "6px",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& .apply__btn": {
        color: "#FFF",
        borderRadius: "6px",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 700,
        padding: "4px 10px",
      },
    },
  },
});

const WorkPreferencesWrapper = styled(Paper)({
  borderRadius: "12px",
  "& .header__title": {
    backgroundColor: "#F1F4F5",
    borderRadius: "8px 8px 0 0",
    border: "none",
    padding: "12px",
    margin: "8px 8px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .edit__btn": {
      paddingRight: 0,
      minWidth: "unset"
    }
  },
  "& .content__wrapper": {
    padding: "24px 20px 32px",
    "& .section__title": {
      color: "#27292A",
      fontWeight: 700,
    },
    "& .preference__label": {
      color: "#30353B",
      fontWeight: 400
    }
  }
})

const StyledToggleButton = styled(ToggleButton)({
  borderRadius: "8px",
  padding: "8px 12px",
  textTransform: "none",
  fontWeight: "bold",
  border: "none",
  fontSize: "16px",
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: "#DEF5E7",
    color: "#2EAB65",
    border: "solid 2px #BFEED1",
    borderRadius: "6px"
  },
});


// Customizable Area End
