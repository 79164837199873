import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarProps {
  open: boolean;
  message: string;
  duration?: number;
  onClose: () => void;
  action?: React.ReactNode;
  anchorOrigin?: any;
}

const StyledSnackbar = styled(Snackbar)({
  "& .MuiPaper-root": {
    background: "#73B2D1",
    borderRadius: "8px",
    border: "none",
    color: "#FFF",
    maxHeight: "44px",
    boxShadow: "0px 4px 12px 0px #3B7E9F59",
    lineHeight: 1,
  },
  "& .MuiSnackbarContent-message": {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const CustomAlert: React.FC<SnackbarProps> = ({
  open,
  message,
  duration = 4000,
  onClose,
  action,
  anchorOrigin = { vertical: "top", horizontal: "center" }
}) => {
  return (
    <StyledSnackbar
    anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      message={message}
      action={
        action && (
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" sx={{color: "inherit !important"}} />
            </IconButton>
          </React.Fragment>
        )
      }
    />
  );
};

export default CustomAlert;
