import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { JobApplication, UserType } from "../../../../packages/blocks/utilities/src/enums";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userType: UserType
  isAlertVisible:boolean
  currentPage : number
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userType: UserType.Professional,
      isAlertVisible:false,
      currentPage : 1
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      // const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCardClick(application :JobApplication) {    
    const homeNavMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
    homeNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'ViewJob');
    homeNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {jobDetails : application });
    homeNavMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(homeNavMsg);
  }
  handleReviewClick() {    
    const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
    NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'RequestManagement');
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);   
    this.send(NavigateMsg); 

  }

  // Customizable Area End
}
