Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.getSubjectAPiEndPoint = "contacts/subject";
exports.addContactAPiEndPoint = "bx_block_contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.getRequestSubjectListEndpont = "contacts/subject";
exports.getContactDetailsEndpont = "bx_block_contact_us/contacts";

exports.supportTitle = "Support";


exports.fullName = "name";
exports.fullNameLabel = "Full name";
exports.fullNameLabelProfessional = "Primary contact name";

exports.email = "email";
exports.emailLabel = "Email";
exports.emailLabelProfessional = "Primary contact email";

exports.requestingSubject = "request_subject_id";
exports.requestingSubjectLabel = "Requesting subject";

exports.requestDescription = "description";
exports.requestDescriptionLabel = "Request description";
exports.requestDescriptionPlaceholder = "Please provide us more details how we can support you";

exports.contactFile = "contact_file";
exports.attachFile = "Attach file";

exports.cancelBtn = "Cancel";
exports.submitBtn = "Submit";

exports.contactUsSuccessMessage = "Your request to Support has been sent";
// Customizable Area End