import React, { Component } from "react";
import { Field, FieldProps } from "formik";
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormHelperText, styled } from "@mui/material";

type CustomCheckboxProps = CheckboxProps & {
  label?: string;
  name: string;
  labelSize?: string;
};

class CustomCheckBox2 extends Component<CustomCheckboxProps> {
  renderCheckboxField = ({ field, meta }: FieldProps) => {
    const { label, labelSize, ...rest } = this.props;
    return (
      <>
        <CustomFormControlLabel
          control={<BpCheckbox {...field} {...rest} checked={field.value} />}
          label={label}
          //labelSize={labelSize ? labelSize: 16}
        />
        {meta.touched && meta.error && (
          <FormHelperText error>{meta.error}</FormHelperText>
        )}
      </>
    );
  };

  render() {
    const { name } = this.props;
    return <Field name={name}>{(fieldProps: any) => this.renderCheckboxField(fieldProps)}</Field>;
  }
}

// Styled components
const CheckboxStyleWrapper = styled(`div`)({
  display: "flex",
  flexDirection: "row",
});

const CustomFormControlLabel = styled(FormControlLabel)<{ labelSize?: string }>(({ labelSize }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: labelSize ? labelSize : "16px",
    lineHeight: "24px",
    color: "#27292A",
    fontWeight: "400",
  },
}));

const BpCheckbox = (props: CheckboxProps) => (
  <Checkbox
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  />
);

const BpIcon = styled("span")(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: "4px",
  border: "1px solid #A9B1BC",
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    opacity: 0.5,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#73B2D1",
  borderColor: "#73B2D1",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "14.5px",
    display: "block",
    width: "11px",
    height: "6px",
    borderLeft: "2px solid white",
    borderBottom: "2px solid white",
    transform: "rotate(-45deg)",
  },
  "input:hover ~ &": {
    backgroundColor: "#5FA3C5",
  },
});

export default CustomCheckBox2;
