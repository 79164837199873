 // Customizable Area Start
export const DaktariLOGO = require("../assets/DaktariLOGO.png");
export const logout = require("../assets/logout.png");
export const view_ = require("../assets/view_.png");
export const image_ = require("../assets/image_.png");
export const notifications_none_24px = require("../assets/image_shopping_cart.png");
export const support_agent = require("../assets/support_agent.png");
export const nextArrowIcon = require("../assets/logout.png");
export const previousArrowIcon = require("../assets/logout.png");
export const nextArrowIconb = require("../assets/arowforward.png");
export const arrowback = require("../assets/arrowback.png");
export const settingIcon = require("../assets/button_icon.png");
export const facilityIcon = require("../assets/image_facility.png");
 // Customizable Area End