// Customizable Area Start
import React from "react";
import SettingProfileController, { Props } from "./SettingProfileController.web";
import FacilitySettingProfile from "./FacilitySettingProfile.web";
import ProfessionalSettingProfile from "./ProfessionalSettingProfile.web";

// Customizable Area End

export default class SettingProfile extends SettingProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      // <ThemeProvider theme={theme}>
      //   <CssBaseline />
        
      // </ThemeProvider>
      <>
       {
        this.state.isFacilitySelected ? 
        <FacilitySettingProfile {...this.props} /> : 
        <ProfessionalSettingProfile {...this.props} />
       }
      </>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
