Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.getUserDetailsAPiEndPoint = "account_block/accounts/show_details";
exports.getLicenseAuthorityEndPoint ="account_block/accounts/all_licensing_authority";
exports.getTelehealthAvailabilityEndPoint ="account_block/accounts/all_telehealth_availability";
exports.getEducationCertificationEndPoint ="account_block/accounts/all_education_certification";
exports.getSpecialitiesEndPoint ="account_block/accounts/specilities";
exports.getprofessionalTitlesEndPoint ="account_block/accounts/all_professional_title";
exports.updateUserDetailsAPiEndPoint ="account_block/accounts/update_profile"
exports.getFacilityTypeAPiEndPoint = "account_block/accounts/facility_type";

exports.dateFormate = "DD/MM/YYYY";

exports.fullName = "full_name";
exports.fullNameLabel = "Full name";

exports.facilityKraNumber = "kra_pin_number";
exports.facilityKraNumberLable = "Facility KRA Pin Number";

exports.facilityKmdpCode = "kmpdc_facility_code";
exports.facilityKmdpCodeLabel = "KMPDC Facility Code";

exports.professionalTitle = "professional_title_id";
exports.professionalTitleLabel = "Professional title";

exports.countryList = [
  {
    label: "India",
    value: "India",
  },
];

exports.country = "country";
exports.countryLabel = "Country";

exports.county = "county";
exports.countyLabel = "County";

exports.city = "city";
exports.cityLabel = "City";

exports.address = "address";
exports.addressLabel = "Address";


exports.pHeader2 = "Licensing & Credentials";
exports.pHeader3 = "Professional Body Memberships";
exports.pHeader4 = "Availability & Work Preferences";

exports.dateOfBirth = "date_of_birth";
exports.dateOfBirthLabel = "Date of birth";


exports.gender = "gender";
exports.genderLabel = "Gender";

exports.nationality = "nationality";
exports.nationalityLabel = "Nationality";
exports.nationalityList = [
  {
    label: "India",
    value: "India",
  },
];
exports.filtredServicesList= [
  { name: "General", id: 1 },
  { name: "Contact Details", id: 2 },
  { name: "Professional Body Memberships", id: 3 },
]
exports.facilityName = "facility_name";
exports.facilityNameLabel = "Facility Name";

exports.facilityType = "facility_type_id";
exports.facilityTypeLabel = "Facility Type";

exports.facilityLevelRating = "facility_level_rating_id";
exports.facilityLevelRatingLabel = "Facility Level Rating";

exports.facilityKraNumber = "kra_pin_number";
exports.facilityKraNumberLable = "Facility KRA Pin Number";

exports.facilitySafeCareCerti = "facility_safe_care_certification_level_dropdown";
exports.facilitySafeCareCertiLabel = "Facility SafeCare Certification level";

exports.facilitySafeCareCertiList = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];
exports.registerFacilitySafeCareCerti = "facility_safe_care_certification_level";
exports.registerFacilitySafeCareCertiLabel = "Does the facility you register has Safe Care Certification level?";

exports.registerFacilitySafeCareCertiList = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
exports.accreditationStatus = "nhif_shif_accreditation_status";
exports.accreditationLabel = "NHIF/SHIF Accreditation Status";

exports.accreditationStatusList = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No (for NHIF coverage)",
    value: "No (for NHIF coverage)",
  },
];
  // Customizable Area End
