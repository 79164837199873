// Customizable Area Start
import React from "react";
import CustomisableUserProfiles from "./CustomisableUserProfiles";
import CustomisableSettingsProfileController, 
{Props} from "./CustomisableSettingsProfileController.web";
import FacilitySettings from "./FacilitySettings.web";

// Customizable Area End

export default class CustomisableSettingsProfile extends CustomisableSettingsProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
       {
        this.state.isFacilitySelected ? 
        <FacilitySettings {...this.props} /> : 
        <CustomisableUserProfiles {...this.props} />
       }
      </>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
