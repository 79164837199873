import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { setStorageData, getStorageData, removeStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { HealthCareType, LocalStorageKeys, ProfileStatus } from "./enumConstant";
const ProfilePicture = require("../../components/blank-profile-picture.png");
export const obfuscateData = (data: any) => {
    const obfuscatedData = btoa(JSON.stringify(data));
    return obfuscatedData;
};

export const deobfuscateData = (obfuscatedData: any) => {
    const decodedData = atob(obfuscatedData);
    return JSON.parse(decodedData);
};

export const setToken = async (token: any) => {
    setStorageData(LocalStorageKeys.AuthToken, obfuscateData(token));
};

export const getToken = () => {
    const data = localStorage.getItem(LocalStorageKeys.AuthToken);
    if (data) {
        const deobfuscated = deobfuscateData(data);
        return deobfuscated;
    }
};

export const removeToken = () => {
    return removeStorageData(LocalStorageKeys.AuthToken);
};


export const getAccessToken = async () => {
    const token = await getStorageData(LocalStorageKeys.AuthToken);
    if (token) {
        const deobfuscated = deobfuscateData(token);
        return deobfuscated;
    }
};

export const getAccessUser = async () => {
    const userData = await getStorageData(LocalStorageKeys.UserData);

    if (userData) {
        try {
            if (userData) {
                const deobfuscated = deobfuscateData(userData);
                return deobfuscated;
            }
        } catch (error) {
            return null
        }
    } else {
        return null
    }
};

export const isLoggedIn = (props: any) => {
    const token = getToken();
    if (token) {
        return props.navigation.navigate("Dashboard");
    }
    return false;
};
export const setUser = (user: any) => {
    setStorageData(LocalStorageKeys.UserData, obfuscateData(user));
};

export const getUser = () => {
    const userData = localStorage.getItem(LocalStorageKeys.UserData)
    if (userData) {
        try {
            if (userData) {
                const deobfuscated = deobfuscateData(userData);
                return deobfuscated;
            }
        } catch (error) {
            return null
        }
    } else {
        return null
    }

};

export const removeUser = () => {
    return removeStorageData(LocalStorageKeys.UserData);
};

export const apiCall = async (data: {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: any;
    token?: boolean;
}): Promise<string> => {
    try {
        const { contentType, method, endPoint, body, token } = data;

        let authToken = "";
        if (token) {
            try {
                authToken = await getAccessToken();
            } catch (error) {
                console.error("Error fetching access token:", error);
            }
        }

        const header: any = { token: authToken };

        if (contentType) {
            header["content-type"] = contentType;
        }

        if (!MessageEnum || !getName) {
            throw new Error("MessageEnum or getName function is missing.");
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        if (body) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        }

        try {
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
            console.error("Error sending message:", error);
            throw new Error("API call failed while sending request.");
        }

        return requestMessage.messageId;
    } catch (error) {
        console.error("API Call Error:", error);
        throw new Error("Failed to resolve API call");
    }
};

export const getUserRoleID = () => {
    const user: any = getUser();
    return Number(user?.data?.attributes.role?.id);
};

export const isFacilityUser = () => {
    const selectedRole = getUserRoleID();
    return selectedRole === HealthCareType.Facility
};

export const getUserProfileStatus = () => {
    const user: any = getUser();
    return user?.data?.attributes.profile_status;
};
export const getUserProfilePhoto = () => {
    const user: any = getUser();
    if (user?.data?.attributes.profile_photo) {
        return user?.data?.attributes.profile_photo;
    } else {
        return ProfilePicture
    }
};

export const isProfilePending = () => {
    return !!(getUserProfileStatus() === ProfileStatus.Pending);
};
export const isProfileInprogress = () => {
    return !!(getUserProfileStatus() === ProfileStatus.InProgress);
};
export const isProfileApprove = () => {
    return !!(getUserProfileStatus() === ProfileStatus.Approve);
};
export const afterLoginPath = () => {
    if (isProfilePending()) {
        return "SettingProfile";
    } else if (isProfileApprove()) {
        return "Dashboard";
    }
};

export const getActivePath = (props: any) => {
    return props.navigation.history.location.pathname.slice(1);
};

export const handleErrors = (error: any, touch: any) => {
    return touch && Boolean(error);
};

export const handleHelperText = (text: any, touch: any) => {
    return touch && text;
};

export const onHandleNavigation = (props: any, path: string, dynamicPath?: string, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), dynamicPath);

    if (payload) {
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    }

    return message;
};

export const handleClearStorage = () => {
    localStorage.clear();
};
export const handleClearStorageAndRedirectToPage = (props: any, path: string, redirectType: "navigate" | "reload") => {
    handleClearStorage();
    redirectType === "navigate" ? props.navigation?.navigate(path) : createLinkAndRedirect(path) ;
};

export const handleExpiredToken = (response: any, props: any) => {
    if (
        response &&
        response.errors &&
        response.errors.length
    ) {
        if (response.errors[0].token) {
            toast.error(response.errors[0].token ?? "Invalid Token OR Expired Token");
            handleClearStorageAndRedirectToPage(props, "/", "reload")
        }
    }
    return false;
};

export const handleUndefinedData = (value: any, elseValue: any) => {
    if (Array.isArray(value)) {
        return value.length > 0 ? value : elseValue;
    } else {
        return !!value ? value : elseValue;
    }
}

export function isDiff(original: any, diff: any) {
    if (typeof original === 'string') return false;
    let t = (original === null || original === undefined) ? [] : original;
    if (t.length !== diff.length) return true;
    if (t.length === 0 && diff.length === 0) return false;

    for (let i = 0; i < t.length; i++) {
        if (!t.includes(diff[i])) return true;
    }
    return false;
}

export function getDiffs(original: any, diff: any): any {
    const diffKeys = Object.keys(diff);
    let obj: any = {};
    for (let key of diffKeys) {
        if (original[key] !== diff[key] && diff[key] !== undefined) {
            if (diff[key] instanceof Array) {
                if (isDiff(original[key], diff[key])) obj[key] = diff[key];
            } else if ((!original.hasOwnProperty(key) && diff[key] != null)
                || (original.hasOwnProperty(key) && (String(original[key]) !== String(diff[key])))) {
                obj[key] = diff[key];
            }
        }
    }
    return obj;
}


export const allowNumberWithSpaceValidation = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const pattern = /^[+\d-\s]+$/i;
    const isValid = pattern.test(String.fromCharCode(keyCode));
    if (!isValid) {
        e.preventDefault();
    }
};

export const mockApiCall = (instance: any, apiCallId: string, apiData: any, responseType?: number) => {
    const msgDeviceTokenAPI = new Message(
        getName(MessageEnum.RestAPIResponceMessage)
    );
    msgDeviceTokenAPI.addData(
        getName(MessageEnum.RestAPIResponceDataMessage),
        msgDeviceTokenAPI.messageId
    );
    msgDeviceTokenAPI.addData(
        getName(responseType ?? MessageEnum.RestAPIResponceSuccessMessage),
        apiData
    );
    instance[apiCallId] = msgDeviceTokenAPI.messageId;

    runEngine.sendMessage("Api Test", msgDeviceTokenAPI);
};

export const handleApiError = (errors: any) => {
    const isArrayOfStirng = typeof errors[0] === 'string';

    const errorMessages = isArrayOfStirng ? errors.join(", ") : errors?.map((error: any) => Object.values(error)).flat().join(", ");
    if (errorMessages.includes("Token has Expired") || errorMessages.includes("Invalid token") || errorMessages.includes("Login again to continue")) {
        handleClearStorage();
    }
    toast.error(errorMessages);
    return "";
};

export const formatListArray = (data: any, labelKey: string, valueKey: string) => {
    return data.map((item: any) => ({
        label: item[labelKey],
        value: item[valueKey],
        data: item,
    }))
};

export const convertFormData = (attrs: any) => {
    let formData = new FormData();
    Object.keys(attrs).forEach((key: any) => {
        if (attrs[key] instanceof File) {
            formData.append(
                `data[attributes][${key}]`,
                attrs[key],
                attrs[key].name
            );
        } else if (Array.isArray(attrs[key])) {
            const arrField: any = attrs[key];
            if (arrField.length) {
                arrField.forEach((document: any) => {
                    formData.append(`data[attributes][${key}][]`, document);
                });
            } else {
                formData.append(`data[attributes][${key}]`, arrField);
            }
        } else {
            formData.append(`data[attributes][${key}]`, attrs[key]);
        }
    });

    return formData;
}

export const convertAllFormData = (data: any, parentKey: any = null, formData: any = undefined) => {
    if (formData === undefined) {
        formData = new FormData();
    }
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            let propName = parentKey ? `${parentKey}[${key}]` : key;
            if (data[key] instanceof File) {
                formData.append(propName, data[key]);
            } else if (Array.isArray(data[key])) {
                if (Array.isArray(data[key]) && data[key].every((item: any) => item instanceof File)) {
                    for (let file of data[key]) {
                        formData.append(`${propName}[]`, file);
                    }
                } else if (data[key].every((item: any) => typeof item === 'object')) {
                    for (let i = 0; i < data[key].length; i++) {
                        convertAllFormData(data[key][i], `${propName}[${i}]`, formData);
                    }
                } else {
                    for (let value of data[key]) {
                        formData.append(`${propName}[]`, value);
                    }
                }
            } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                convertAllFormData(data[key], propName, formData);
            } else {
                formData.append(propName, data[key]);
            }
        }
    }
    return formData;
};

export const createLinkAndRedirect = (path: string) => {
    if (path) {
        const link = document.createElement("a");
        link.href = path;
        link.target = "_self";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';

    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

  export const isUserLoggedIn =()=> {
    return getToken()
  }

  export const handleBackPress =(navigation:any, redirectTo: any)=> {
   if(isUserLoggedIn()){
    redirectTo("CustomisableUserProfiles")
   }else {   
    redirectTo("SignUpStepOne")
   }
  }