// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import WelcomeScreenController, { Props, configJSON } from "./WelcomeScreenController.web";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import ContentWrapper from "./ContentWrapper.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    subtitle1: {
      fontSize: "24px",
      fontWeight: 800,
      lineHeight: 1.25,
      color: "#000",
      "@media (max-width: 600px)": {
        fontSize: "20px"
      }
    },
    subtitle2: {
      fontSize: "24px",
      lineHeight: 1.5,
      "@media (max-width: 600px)": {
        fontSize: "20px"
      }
    },
    body1: {
      fontSize: "14px",
      fontWeight: 800,
      color: "#000",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
    body2: {
      fontSize: "14px",
      "@media (max-width: 600px)": {
        fontSize: "12px"
      }
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "700",
          color: "#FFF",
          boxShadow: "none",
          padding: "10px 18px",
          maxWidth: "300px",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        contained: {
          "&:hover": {
            backgroundColor: "#23894F",
          },
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: "#1D2A6D",
          },
        },
      },
    },
  },
});
    // Customizable Area End

export default class WelcomeScreen extends WelcomeScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper data-test-id="wrapper" navigation={this.props.navigation} backButtonLabel={configJSON.backButtonLabelWebsite}>
          <BoxWrapper>
            <Container className="main__content">
              <Typography variant="subtitle1" align="center" data-test-id="register-login-label">
                {configJSON.registerOrLoginLabel}
              </Typography>

              <Box className="signup__action__wrapper">
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: configJSON.sinupActionTitle }}></Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  data-test-id="redirect-signup"
                  fullWidth
                  onClick={() => this.redirectTo("SignUpChooseRole")}
                >
                  {configJSON.signupLabel}
                </Button>
              </Box>

              <Box className="signup__action__wrapper">
                <Typography variant="body2">{configJSON.alreadyHaveAccount}</Typography>
                <Button 
                  variant="contained"  
                  data-test-id="redirect-login"
                  fullWidth
                  onClick={() => this.redirectTo("Login")}>
                  {configJSON.loginLabel}
                </Button>
              </Box>
            </Container>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  flex: 1,
  "& .main__content": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .signup__action__wrapper": {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      flexDirection: "column",
      marginTop: "38px",
      width: "100%"
    },
  },
  "@media (max-width: 600px)": {
    "& .main__content": {
      "& .signup__action__wrapper": {
        gap: "20px",
        marginTop: "28px",
      },
    },
  },
});
// Customizable Area End
