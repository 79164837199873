import React from 'react';
import { Grid, Typography, Box, styled, Button } from "@mui/material";
import CustomCheckBox2 from "./customFormComponents/CustomCheckBox2.web";
import CustomSwitch from "./customFormComponents/CustomSwitch.web";

 const RenderNotificationPrefernces = () => {
    return (
      <BoxWrapper data-test-id="notification-prefernces">
      <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: 2.5, background: "#F1F4F5", borderRadius: 2 }}>
      <Typography variant="h1" align="left" data-test-id="welcome-label">
          Notification preferences
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2" sx={{ color: '#0F172A', fontWeight: 400 }}>
          I wish to receive notifications
        </Typography>
        <CustomSwitch name="hello" checked={true} onChange={() => {}} />
          </Box>
      </Grid>
      <Box sx={{ml: 2}}>
      <Box sx={{ marginTop: 3, pr:2 }}>
        <Typography variant="body1" sx={{}}>
          Always send an email notification
        </Typography>
        <Typography variant="body2" sx={{  marginBottom: 1, fontWeight: 400, ineHeight: "21px" }}>
          Receive emails even if you are active on the app
        </Typography>
        <Grid container alignItems="center" justifyContent="space-between">
        <Box sx={{ display: 'flex', gap: 5, marginTop: 1 }}>
             <CustomCheckBox2
                  key={'New matched vacancies'} 
                  name={'New matched vacancies'} 
                  label={'New matched vacancies'} 
                  labelSize="14px"
                 />
            <CustomCheckBox2
                  name={'Vacancies requests'} 
                  label={'Vacancies requests'} 
                  labelSize="14px"
                 />
          </Box>
          <CustomSwitch name="hello" checked={true} onChange={() => {}} />
        </Grid>
      </Box>
        <Box sx={{ marginTop: 2, borderTop: "1px solid #CBD5E1", paddingTop: 2, pb: 3, pr:2, borderBottom: "1px solid #CBD5E1", }}>        
        <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: 1 }}>
          <Grid> 
        <Typography variant="body1" sx={{ fontWeight: 700,  }}>
          Security alerts
        </Typography>
        <Typography variant="body2" sx={{fontWeight: 400, lineHeight: "21px" }}>
          Get notified about important security alerts, such as password reset
        </Typography>
        </Grid>
          <CustomSwitch  name="hello"checked={true} onChange={() => {}} />
        </Grid>
      </Box>
      </Box>
      </BoxWrapper>
    )
  }
  const BoxWrapper = styled(Box)({
    width: "100% !important",
    maxWidth: "none !important",
  });
  export default RenderNotificationPrefernces;