Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.appointmentApiContentType = "application/json";

exports.appointmentAPiEndPoint = "appointment_management/availabilities";

exports.addAppointmentAPiMethod = "POST";
exports.deleteAllAppointmentsAPiMethod = "DELETE";
exports.getAppointmentListAPiMethod = "GET";

exports.format = "DD/MM/YY";
exports.serverFormat = "YY/MM/DD";
exports.webFormat = "dd/MM/yyyy";
exports.timeFormat = "HH:mm A";
exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.availableDate = "Available Date";
exports.availableFrom = "Available From";
exports.availableTo = "Available To";
exports.viewAllAppointments = "View all Appointments";
exports.addAppointment = "Add Appointment";
exports.confirm = "Confirm";
exports.cancel = "Cancel";
exports.datePickerPlaceholder = "Select Date";
exports.datePickerPlaceholderFormat = "DD/MM/YY";
exports.timePickerPlaceholder = "Select Time";
exports.getList = "Get List";
exports.slot = "Slot";

exports.headerTextList = [
  {
    label: "Basic Details",
  },
  {
    label: "Location  Details",
  },
  {
    label: "Requirements",
  },
];
exports.headerLabel1 = "New Shifts";
exports.dotLabel = ".";
exports.backLabel = "Back";

exports.dateFormate = "DD/MM/YYYY";
exports.timeFormate = "hh: mm A";

exports.addSubmitButton = "Add New Shift";
exports.editSubmitButton = "Save Changes";
exports.backButton = "Back";
exports.continueButton = "Continue";

exports.shiftTitle = "shift_title";
exports.shiftTitleLabel = "Shift Title";

exports.role = "facility_shift_role_id";
exports.roleLabel = "Role";

exports.specialty = "facility_shift_specialtie_id";
exports.specialtyLabel = "Specialty";

exports.shiftType = "shift_type";
exports.shiftTypeLabel = "Shift Type";

exports.shiftStartDate = "shift_start_date";
exports.shiftStartDateLabel = "Shift Start Date";

exports.shiftEndDate = "shift_end_date";
exports.shiftEndDateLabel = "Shift End Date";

exports.shiftDurationLabel = "Shift Duration";
exports.shiftDurationSuggestionLabel = "8 hours";
exports.shiftDurationStart = "shift_duration_start";
exports.shiftDurationEnd = "shift_duration_end";

exports.compensationLabel = "Compensation";
exports.compensationSuggestionLabel = "KES 2,000/hour";
exports.compensationType = "compensation_type";
exports.rate = "rate";
exports.currency = "currency";

exports.additionalBenefits = "additional_benefits";
exports.additionalBenefitsLabel = "Additional benefits";


exports.facilityName = "facility_name";
exports.facilityNameLabel = "Facility Name";

exports.locationType = "location_type_id";
exports.locationTypeLabel = "Location Type";

exports.facilityAddress = "facility_shift_address_id";
exports.facilityAddressLabel = "Facility Address";


exports.minimumQualifications = "facility_shift_qualification_id";
exports.minimumQualificationsLabel = "Minimum Qualifications";

exports.experienceLevel = "facility_shift_experience_level_id";
exports.experienceLevelLabel = "Experience Level";

exports.skillsNeeded = "facility_shift_skill_needed_id";
exports.skillsNeededLabel = "Skills Needed";

exports.languageProficiency = "facility_shift_language_id";
exports.languageProficiencyLabel = "Language Proficiency";

exports.contactPerson = "contact_person";
exports.contactPersonLabel = "Contact Person";

exports.preferredContactMethod = "shift_contact_method_id";
exports.preferredContactMethodLabel = "Preferred Contact Method";

exports.contactEmail = "contact_email";
exports.contactEmailLabel = "Contact Email";

exports.phoneNumber = "phone_number";
exports.phoneNumberLabel = "Phone number";

exports.dressCode = "dress_code";
exports.dressCodeLabel = "Dress Code";

exports.ShiftDescription = "shift_description";
exports.ShiftDescriptionLabel = "Shift Description";

exports.SafetyRequirements = "safety_requirementss";
exports.SafetyRequirementsLabel = "COVID-19 Safety Requirements";

exports.Attachments = "shift_photo";
exports.AttachmentsLabel = "Attachments";

exports.valid_professional_license_or_certification_mandatory = "valid_professional_license_or_certification_mandatory";
exports.valid_professional_license_or_certification_mandatory_Label = "Check if valid professional license or certification is mandatory";

// Customizable Area End
