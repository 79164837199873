import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  TextField,
  MenuItem,
  styled,
  IconButton
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import {image_plus,group_16860} from '../src/assets'
const CustomInputBase = styled(TextField)(({ multiline }) => ({
  minHeight: "44px",
  borderRadius: "8px",
  // marginTop: "30px",
  marginBottom: "12px",
  border: "1px solid #D6DEEA",
  boxShadow: "none",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    minHeight: "44px",
    height: multiline ? "auto" : "44px",
    padding: multiline ? "10px" : "0px",

    "& fieldset": {
      border: "1px solid #E2E8F0",
    },
    "&:hover fieldset": {
      border: "1px solid #E2E8F0",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E2E8F0",
    },
  },

  "& .MuiInputBase-input": {
    height: "44px",
    lineHeight: "44px",
    padding: "0 12px",
    fontSize: "14px",
    textAlign: "left", // Ensure text and placeholder align left
    "&::placeholder": {
      fontSize: "14px",
      color: "#9e9e9e",
      textAlign: "left", // Ensure placeholder aligns left
      opacity: 1, // Ensure placeholder is fully visible
    },
  },

  "& .MuiOutlinedInput-multiline": {
    minHeight: "104px",
    padding: "10px",
    textAlign: "left", // Align multiline text to the left
  },

  "& textarea": {
    textAlign: "left",
    verticalAlign: "top",
    padding: "10px",
  },

  "& .Mui-focused": {
    outline: "none",
    boxShadow: "none",
  },
}));

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { formData } = this.state;
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Support
                </Typography>
                <hr style={{ border: "1px solid #D6DEEA", margin: "10px 0",width:'120%',marginLeft:-29}} />
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom style={{marginTop:35}}>
                          Full Name
                        </Typography>
              <CustomInputBase
                data-test-id="fullName"
                name="fullName"
                id="fullName"
                value={formData.fullName || ""}
                onChange={(event) =>
                  this.onValueChange("fullName", event.target.value)
                }
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder="Enter Full Name"
              />
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                Email
              </Typography>
              <CustomInputBase
                data-test-id="email"
                name="email"
                id="email"
                value={formData.email || ""}
                onChange={(event) =>
                  this.onValueChange("email", event.target.value)
                }
                fullWidth={true}
                variant="outlined"
                type="email"
                placeholder="Enter email"
                style={{background:'#effdff'}}
              />
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                Requesting subject
              </Typography>
              <CustomInputBase
                data-test-id="Requestingsubject"
                name="Requesting subject"
                id="Requesting subject"
                value={formData.Requestingsubject || ""}
                onChange={(event) =>
                  this.onValueChange("Requestingsubject", event.target.value)
                }
                fullWidth={true}
                variant="outlined"
                type="text"
                select
                placeholder="Enter Requesting subject"
              >
                {this.state.options && this.state.options?.length > 0 ? (
                  this.state.options.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </CustomInputBase>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Request description
              </Typography>
              <CustomInputBase
                data-test-id="requestdescription"
                name="requestdescription"
                id="requestdescription"
                value={formData.requestdescription || ""}
                onChange={(event) =>
                  this.onValueChange("requestdescription", event.target.value)
                }
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder="Please provide us more details how we can support you "
                multiline
                rows={2}
                InputProps={{
                  sx: { textAlign: "left", whiteSpace: "normal", lineHeight: "12px"}, // Ensures left-aligned input text
                }}
                InputLabelProps={{
                  shrink: true, // Ensures placeholder is positioned correctly
                  sx: { textAlign: "left" },
                }}
              />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
        {this.state.selectedFile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              backgroundColor: "#F4F7FA",
              padding: "10px",
              borderRadius: "8px",
              width: "100%", // Adjust width to fit container
              maxWidth: "350px", // Set max width
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                backgroundColor: "#C7DAE5",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={group_16860}
                alt="file-icon"
                style={{ width: 24, height: 24 }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: "14px",  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",width:150, }}>
                {this.state.selectedFile}
              </Typography>
            </Box>
            <IconButton size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}
        </Box>

              <Box
                component="label"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecoration: "underline",
                  "&:hover": { color: "purple" },
                }}
              >
                <img
                  src={image_plus}
                  style={{
                    color: "purple",
                    fontSize: 20,
                    borderRadius: "50%",
                    padding: "1px",
                    marginTop: 10
                  }}
                />
                <Typography
                  component="span"
                  sx={{ textDecoration: "underline", fontWeight: "bold"}}
                  style={{marginTop:10}}
                >
                  Attach file
                </Typography>
                <input type="file" data-test-id="attachmentid" hidden onChange={(event)=>this.handleFileChange(event)}/>
              </Box>
              </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute", // Fixes it at the bottom
                bottom: 0, // Ensures it sticks to the bottom
                left: 0,
                right: 0,
                padding: "16px", // Adds some padding from bottom
                background: "#fff", // Ensure background if needed
                boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow effect
              }}
            >
              <Button
                data-test-id="cancelbutton"
                variant="outlined"
                color="primary"
                sx={{
                  width: 107,
                  height: 44,
                  background: "#F1f4f5",
                  border: "none",
                  color: "#2EAB65",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 280,
                  height: 56,
                  background: "#2EAB65",
                }}
                data-test-id="submitbutton"
              >
                Submit
              </Button>
            </Box>

          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
// Customizable Area End
