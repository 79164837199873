import React, { Component } from "react";
import { Checkbox, CheckboxProps, FormControlLabel, styled } from "@mui/material";

type CustomCheckboxProps = CheckboxProps & {
  label?: string;
  name?: string;
  labelSize? : string;
};

interface CustomCheckboxState {}

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

class CustomCheckbox extends Component<CustomCheckboxProps, CustomCheckboxState> {
  constructor(props: CustomCheckboxProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, labelSize, ...rest } = this.props;
    return <CustomFormControlLabel control={<BpCheckbox {...rest} />} label={label} labelSize={labelSize}/>;
  }
}

const CustomFormControlLabel = styled(FormControlLabel)<{ labelSize?: string }>(({labelSize})=>({
  "& .MuiFormControlLabel-label": {
    fontSize: labelSize ? labelSize : "16px",
    lineHeight: "24px",
    color: "#27292A",
    fontWeight: "400",
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: "4px",
  border: "1px solid #A9B1BC",
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    opacity: 0.5,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#73B2D1",
  borderColor: "#73B2D1",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "14px",
    display: "block",
    width: "11px",
    height: "6px",
    borderLeft: "2px solid white",
    borderBottom: "2px solid white",
    transform: "rotate(-45deg)",
  },
  "input:hover ~ &": {
    backgroundColor: "#5FA3C5",
  },
});

export default CustomCheckbox;
