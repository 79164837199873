import React, { Component } from "react";
import { Field, FieldProps } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker, DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import assets from "../../../../blocks/email-account-registration/src/assets";
import { CalenderIcon, ClockIcon } from "../assets";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

type CustomInputProps = TextFieldProps & {
  showEndAdornment?: boolean;
  optional?: boolean;
  optionalText?: string;
  dateProps?: {
    withIcon?: boolean;
    minDate?: Dayjs | string;
    maxDate?: Dayjs | string;
    format: string;
  },
  timeProps?: {
    withIcon?: boolean;
    format?: string;
  },
  isRequired?: boolean
};

interface CustomInputState {
  showPassword: boolean;
}

class CustomInput extends Component<CustomInputProps, CustomInputState> {
  constructor(props: CustomInputProps) {
    super(props);
    this.state = { showPassword: false };
  }

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  getEndAdornment = () => {
    if (this.props.type === "password") {
      return (
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleTogglePassword}
          edge="end"
        >
          <img
            src={this.state.showPassword ? assets.eyeOpen : assets.eyeClosed}
            alt="icon"
          />
        </IconButton>
      );
    }

    if (this.props.showEndAdornment) {
      return <DoneIcon color="secondary" />;
    }

    return null;
  };

  renderDatePicker = ({ field, meta, form }: FieldProps) => {
    const { label, name, dateProps, isRequired } = this.props;
    const withIcon = dateProps?.withIcon ?? false;
    const dateFormat = dateProps?.format ?? "DD/MM/YYYY";

    return (
      <InpuStyleWrapper mb={1}>
        <Typography variant="body1" mb={0.5} className="label">
          {label} {isRequired && <span className="isRequired">*</span>}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          {withIcon ? (
            <DatePicker
              {...dateProps}
              {...field}
              name={name}
              value={field.value && dayjs(field.value, dateFormat).isValid() ? dayjs(field.value, dateFormat) : ""}
              onChange={(newValue) => {
                if (newValue && dayjs(newValue).isValid()) {
                  form.setFieldValue(name ?? "", dayjs(newValue).format(dateFormat));
                }
              }}
              views={["year", "month", "day"]}
              onOpen={() => form.setTouched({ [name ?? ""]: true })}

              slots={{
                textField: TextField,
                openPickerIcon: () => (
                  <img
                    src={CalenderIcon}
                    alt="Calendar"
                  />
                ),
                toolbar: () => null,
              }}
              slotProps={{
                textField: {
                  ...field,
                  name: name,
                  fullWidth: true,
                  error: Boolean(meta.touched && meta.error),
                  helperText: meta.touched && meta.error ? meta.error : "",
                  InputProps: {
                    readOnly: true,
                    endAdornment: null,
                  },
                  placeholder: dateFormat,
                  value: field.value && dayjs(field.value, dateFormat).isValid() ? dayjs(field.value, dateFormat) : "",
                  onChange: (newValue: any) => {
                    if (newValue) {
                      form.setFieldValue(name ?? "", dayjs(newValue).format(dateFormat));
                    }
                  },
                },
              }}
            />
          ) : (
            <DesktopDatePicker
              {...dateProps}
              {...field}
              value={field.value && dayjs(field.value, dateFormat).isValid() ? dayjs(field.value, dateFormat) : ""}
              onChange={(newValue) => {
                if (newValue && dayjs(newValue).isValid()) {
                  form.setFieldValue(name ?? "", dayjs(newValue).format(dateFormat));
                }
              }}
              name={name}
              onOpen={() => form.setTouched({ [name ?? ""]: true })}
              views={["year", "month", "day"]}
              slots={{
                textField: TextField,
                openPickerIcon: () => (
                  <img
                    src={CalenderIcon}
                    alt="Calendar"
                  />
                ),
              }}
              slotProps={{
                textField: {
                  ...field,
                  name: name,
                  value: field.value && dayjs(field.value, dateFormat).isValid() ? dayjs(field.value, dateFormat) : "",
                  onChange: (newValue: any) => {
                    if (newValue) {
                      form.setFieldValue(name ?? "", dayjs(newValue).format(dateFormat));
                    }
                  },
                  fullWidth: true,
                  error: Boolean(meta.touched && meta.error),
                  helperText: meta.touched && meta.error ? meta.error : "",
                  InputProps: {
                    readOnly: true,
                  },
                  placeholder: dateFormat,
                },
              }}
            />
          )}

        </LocalizationProvider>

      </InpuStyleWrapper>
    );
  };

  renderTimePicker = ({ field, meta, form }: FieldProps) => {
    const { label, name, timeProps, isRequired } = this.props;
    const withIcon = timeProps?.withIcon ?? false;
    const timeFormat = timeProps?.format ?? "hh: mm A";

    const CustomClockIcon = () => (
      <img src={ClockIcon} alt="Clock" style={{ width: 20, height: 20, cursor: "pointer" }} />
    );

    return (
      <InpuStyleWrapper mb={1}>
        <Typography variant="body1" mb={0.5} className="label">
          {label} {isRequired && <span className="isRequired">*</span>}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DemoContainer components={['TimePicker']}>
            {withIcon ? (
              <TimePicker
                {...timeProps}
                {...field}
                name={name}
                value={field.value && dayjs(field.value, timeFormat).isValid() ? dayjs(field.value, timeFormat) : ""}
                onChange={(newValue) => {
                  if (newValue && dayjs(newValue).isValid()) {
                    form.setFieldValue(name ?? "", dayjs(newValue).format(timeFormat));
                  }
                }}
                onOpen={() => form.setTouched({ [name ?? ""]: true })}
                views={["hours", "minutes"]}
                slots={{
                  textField: TextField,
                  openPickerIcon: CustomClockIcon,
                  toolbar: () => null,
                }}
                slotProps={{
                  textField: {
                    ...field,
                    name: name,
                    fullWidth: true,
                    error: Boolean(meta.touched && meta.error),
                    helperText: meta.touched && meta.error ? meta.error : "",
                    placeholder: timeFormat,
                    value: field.value && dayjs(field.value, timeFormat).isValid() ? dayjs(field.value, timeFormat) : "",
                    onChange: (newValue: any) => {
                      if (newValue) {
                        form.setFieldValue(name ?? "", dayjs(newValue).format(timeFormat));
                      }
                    },
                  },
                }}
              />
            ) : (
              <DesktopTimePicker
                {...timeProps}
                {...field}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
                value={field.value && dayjs(field.value, timeFormat).isValid() ? dayjs(field.value, timeFormat) : ""}
                onChange={(newValue) => {
                  if (newValue && dayjs(newValue).isValid()) {
                    form.setFieldValue(name ?? "", dayjs(newValue).format(timeFormat));
                  }
                }}
                name={name}
                onOpen={() => form.setTouched({ [name ?? ""]: true })}
                views={["hours", "minutes"]}
                slots={{
                  textField: TextField,
                  openPickerIcon: CustomClockIcon,
                }}
                slotProps={{
                  textField: {
                    ...field,
                    name: name,
                    fullWidth: true,
                    error: Boolean(meta.touched && meta.error),
                    helperText: meta.touched && meta.error ? meta.error : "",
                    placeholder: timeFormat,
                    // value: field.value,
                    value: field.value && dayjs(field.value, timeFormat).isValid() ? dayjs(field.value, timeFormat) : "",
                    onChange: (newValue: any) => {
                      console.log("TextField Input:", newValue);

                      // if (!newValue) {
                      //   form.setFieldValue(name ?? "", "");
                      //   return;
                      // }

                      const parsedTime = dayjs(newValue, timeFormat, true);
                      console.log("####4", newValue, parsedTime);
                      if (parsedTime.isValid()) {
                        form.setFieldValue(name ?? "", parsedTime.format(timeFormat));
                      } else {
                        console.warn("Invalid time format entered.");
                        form.setFieldValue(name ?? "", newValue); // Allow partial input
                      }
                    },
                  },
                }}
              />
            )}
          </DemoContainer>
        </LocalizationProvider>

      </InpuStyleWrapper>
    );
  };

  renderTextField = ({ field, meta }: FieldProps) => {
    const { label, type, showEndAdornment = false, isRequired, ...rest } = this.props;
    const { showPassword } = this.state;

    return (
      <InpuStyleWrapper mb={1}>
        <Typography variant="body1" mb={0.5} className="label">
          {label} {isRequired && <span className="isRequired">*</span>} {(this.props.optional && <span style={{ fontWeight: 400 }}>{` ${this.props.optionalText ?? "(Optional)"}`}</span>)}
        </Typography>
        <TextField
          {...field}
          {...rest}
          type={type === "password" && showPassword ? "text" : type}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ""}
          InputProps={{
            ...rest.InputProps,
            endAdornment: this.getEndAdornment(),
          }}
        />
      </InpuStyleWrapper>
    );
  };

  render() {
    const { name, type } = this.props;

    return (
      <Field name={name}>
        {type === "date" ?
          this.renderDatePicker :
          type === "time" ?
            this.renderTimePicker :
            this.renderTextField
        }
      </Field>
    );
  }
}

const InpuStyleWrapper = styled(Box)({
  "& .label": {
    fontWeight: 700,
    lineHeight: "20px",
    color: "#27292A",
    "& .isRequired": {
      color: "#DC2626",
    }
  },
  "& .MuiFormControl-root": {
    minHeight: "64px",
    width: "100%",
    paddingBottom: "27px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFF",
  },
  "& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-input": {
    padding: "10px 12px 12px",
    fontSize: "16px",
    lineHeight: 1,
    color: "#0F172A",
    fontWeight: "400",
  },
  "& .MuiInputBase-inputMultiline": {
    marginBottom: "24px"
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "0",
    marginTop: "0",
  },
  "& .MuiInputBase-inputAdornedEnd": {
    marginRight: "10px",
  },

  "& .MuiPickersPopper-root .MuiToolbar-root": {
    display: "none !important"
  }
});

export default CustomInput;
