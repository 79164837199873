// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Checkbox, CircularProgress, Container, CssBaseline, FormControlLabel, Typography } from "@mui/material";
import LoginController, { Props, configJSON } from "./LoginController.web";
import ContentWrapper from "../../../../blocks/email-account-registration/src/components/ContentWrapper.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import assets from "../assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
    background: {
      default: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#000",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          fontWeight: "700",
          textTransform: "none",
          padding: "10px 18px",
          boxShadow: "none",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            padding: "8px 16px",
            fontSize: "12px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
          color: "#FFF",
            backgroundColor: "#B6D4E3"
          }
        },
        text: {
          fontWeight: "800",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Formik
              data-test-id="formik"
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, isValid, isSubmitting, dirty }) => (
                <Form className="form__wrapper">
                  <Box className="form__main__data">
                    <Box className="image__wrapper" sx={{ mt: 2.5, mb: 2.5 }}>
                      <img className="image" src={assets.profile} alt="icon" />
                    </Box>
                    <Typography variant="h1" align="center" data-test-id="welcome-label" mb={4}>
                      {configJSON.welcomeLabel}
                    </Typography>

                    <CustomInput autoFocus label="Email" name="email" fullWidth showEndAdornment={touched.email && !errors.email} />

                    {touched.email && errors.email == configJSON.emailError && (
                      <Box textAlign="end">
                        <Button variant="text" data-test-id="create-account" sx={{ pr: 0 }} onClick={() => this.redirectTo("SignUpChooseRole")}>
                          {configJSON.createAccount}
                        </Button>
                      </Box>
                    )}

                    <CustomInput label="Password" name="password" type="password" fullWidth data-test-id="password" />

                    <Box textAlign="end">
                      <Button variant="text" data-test-id="forgot-password" sx={{ pr: 0 }} onClick={() => this.redirectTo("ForgotPassword")}>
                        {configJSON.forgotPasswordLabel}
                      </Button>
                    </Box>
                  </Box>

                  <footer className="form__footer">
                    <FormControlLabel label={configJSON.rememberMeLabel} control={<Checkbox name="rememberme" />} />
                    <Button
                      data-test-id="submit"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      variant="contained"
                      fullWidth
                      sx={{ mt: { xs: 2, sm: 3 } }}
                    >
                      {isSubmitting ? <CircularProgress size="20px" color="inherit" /> : (configJSON.loginLabel)}
                    </Button>
                  </footer>
                </Form>
              )}
            </Formik>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Container)({
  flex: 1,
  overflowY: "auto",
  maxWidth: "495px !important",
  "& .form__wrapper": {
    width: "100%",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    marginBottom: 0,
  },
  "& .form__main__data": {
    flex: 1,
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "& .validation__chip__wrapper": {
      display: "flex",
      gap: "4px",
      flexWrap: "wrap",
      "& .validation__chip": {
        fontSize: "12px",
        borderColor: "#E2E8F0",
        fontWeight: 400,
      },
    },
    "& .image__wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  "& .form__footer": {
    position: "sticky",
    width: "100%",
    bottom: 0,
    "& .MuiFormControlLabel-label": {
      fontWeight: 400
    }
  },
  "@media (max-width: 600px)": {
    "& .image": {
      width: "44px"
    }
  }
});
// Customizable Area End
