// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { FormikErrors, FormikTouched } from "formik";
import { PopoverVirtualElement } from "@mui/material";
import { apiCall, convertAllFormData, formatListArray, handleApiError, handleExpiredToken, isFacilityUser } from "../../../../components/src/components/utils/commonFunctions";
import { OptionType } from "../../../../components/src/components/utils/commonTypes";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { toast } from "react-toastify";


export interface FormValues {
  [key: string]: string | null | File;
  contact_file: File | null;
}

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  children?: React.ReactNode;

  open: boolean;
  handleClose: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  fileDocument: string;
  anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined,
  isFacility: boolean;
  requestingSubjectList: OptionType[];
  openToast: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactUsModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSubjectDataApiCallId: string = "";
  addContactApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      fileDocument: "",
      anchorEl: null,
      isFacility: !isFacilityUser(),
      requestingSubjectList: [
        {
          label: "Please select an option",
          value: "",
          disable: true
        },
      ],
      openToast: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props);
      if (apiRequestCallId && resJson) {
        if (apiRequestCallId === this.getSubjectDataApiCallId) {
          handleResponseMessage({
            responseJson: resJson,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(resJson, "name", "id");
              this.setState((prevState) => ({ requestingSubjectList: [...prevState.requestingSubjectList, ...array] }));
            },
            onFail: () => {
              toast.error("Failed to fetch requesting subject list");
            }
          })
        } else if (apiRequestCallId === this.addContactApiCallId) {
          handleResponseMessage({
            responseJson: resJson,
            errorJson: null,
            onSuccess: () => {
              this.setState({
                openToast: true,
              });
              this.handleCloseModal();
            },
            onFail: () => {
              handleApiError(resJson.errors)
            }
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.props.open && this.getSubjects();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.getSubjects();
    }
  }

  initialValues: FormValues = {
    [configJSON.fullName]: "",
    [configJSON.email]: "",
    [configJSON.requestingSubject]: "",
    [configJSON.requestDescription]: "",
    contact_file: null
  };

  getSubjects = async () => {
    this.getSubjectDataApiCallId = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSubjectAPiEndPoint,
      token: true
    });
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, setTouched: (touched: FormikTouched<any>, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>) => {
    const file = event.target.files?.[0];
    const name = event.target.name
    setTouched({ [name]: true })
    if (file) {
      setFieldValue([name], file);
      this.setState({
        fileDocument: URL.createObjectURL(file),
      });
    }
  };

  handleOpenMenu = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleDeleteFile = (name: string, setFieldValue: { (field: string, value: any, shouldValidate?: boolean): Promise<void | FormikErrors<FormValues>>; (arg0: string, arg1: null): void; }) => {
    setFieldValue(name, null);
    this.setState({ fileDocument: "" });
    this.handleCloseMenu();
  };

  handleSubmitContact = async (data: Partial<FormValues> | undefined) => {
    this.addContactApiCallId = await apiCall({
      method: configJSON.httpPostMethod,
      body: convertAllFormData(data, "data"),
      endPoint: configJSON.addContactAPiEndPoint,
      token: true,
    });
  };

  handleCloseModal = () => {
    this.setState({ fileDocument: "" });
    this.props.handleClose();
  };


  handleCloseAlert = () => this.setState({ openToast: false })

  // Customizable Area End
}
