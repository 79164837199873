// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HealthCareType } from '../../components/src/components/utils/enumConstant';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import ForgotPassword from "../../blocks/forgot-password/src/components/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/components/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsWeb from "../../blocks/termsconditions/src/TermsConditions.web";
import PrivacyPolicyWeb from "../../blocks/termsconditions/src/components/PrivacyPolicy.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Maps from "../../blocks/maps/src/Maps";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Notifications from "../../blocks/notifications/src/Notifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import welcomeSignup from "../../blocks/email-account-registration/src/welcomeSignup.web"
import WelcomeScreen from "../../blocks/email-account-registration/src/components/WelcomeScreen.web";
import SignUpChooseRole from "../../blocks/email-account-registration/src/components/SignUpChooseRole.web";
import SignUpStepOne from "../../blocks/email-account-registration/src/components/SignUpStepOne.web";
import PhoneVerification from "../../blocks/email-account-registration/src/components/PhoneVerification.web";
import SignUpSuccess from "../../blocks/email-account-registration/src/components/SignUpSuccess.web";
import welcomeSignupRole from "../../blocks/email-account-registration/src/welcomeSignupRole.web"
import Login from "../../blocks/email-account-login/src/components/Login.web";
import CustomisableSettingsProfile from '../../blocks/customisableuserprofiles/src/CustomisableSettingsProfile.web'
import SidebarSettings from '../../blocks/customisableuserprofiles/src/SidebarSettings';
import SidebarSettingsweb from '../../blocks/customisableuserprofiles/src/SidebarSettings.web'
import SettingProfile from '../../blocks/customform/src/components/SettingProfile.web';
import SuccessPage from '../../blocks/customform/src/components/SuccessPage.web';
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import ViewJob from "../../blocks/dashboard/src/ViewJob.web";
import AddEditShift from "../../blocks/appointmentmanagement/src/components/AddEditShift.web";
import { getToken } from '../../components/src/components/utils/commonFunctions';
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement.web"

const routeMap = {
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Contactus:{
 component:Contactus,
  path:"/Contactus",
  allowedParms : {
    allowedUsers: [HealthCareType.Professional, HealthCareType.Facility],
  },
},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Maps:{
 component:Maps,
path:"/Maps"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
welcomeSignup:{
  component:welcomeSignup,
 path:"/welcomeSignup"},
welcomeSignupRole:{
  component:welcomeSignupRole,
 path:"/welcomeSignupRole"},

 CustomisableUserProfiles:{
  component: CustomisableSettingsProfile,
  path:"/CustomisableUserProfiles",
  allowedParms : {
    allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
  },
},

 SidebarSettings:{
  component:SidebarSettings,
 path:"/SidebarSettings",
  allowedParms : {
    allowedUsers: [HealthCareType.Professional, HealthCareType.Facility],
  },
},

 SidebarSettingsweb:{
  component:SidebarSettingsweb,
 path:"/SidebarSettingsweb",
 allowedParms : {
  allowedUsers: [HealthCareType.Professional, HealthCareType.Facility],
},
},
 
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  WelcomeScreen: {
    component: WelcomeScreen,
    path: '/WelcomeScreen',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  TermsConditionsWeb: {
    component: TermsConditionsWeb,
    path: '/TermsConditionsWeb',
    exact: true,
    allowedParms : {     
      allowedUsers: [HealthCareType.Facility, HealthCareType.Professional],
      allowedToAll : getToken() ? false : true,
    },
  },
  PrivacyPolicyWeb: {
    component: PrivacyPolicyWeb,
    path: '/PrivacyPolicyWeb',
    exact: true,
    allowedParms : {     
      allowedUsers: [HealthCareType.Facility, HealthCareType.Professional],
      allowedToAll : getToken() ? false : true,
    },
  },
  SignUpChooseRole: {
    component: SignUpChooseRole,
    path: '/SignUpChooseRole',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  SignUpStepOne: {
    component: SignUpStepOne,
    path: '/SignUpStepOne',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Facility, HealthCareType.Professional],
    },
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: '/PhoneVerification',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  SignUpSuccess: {
    component: SignUpSuccess,
    path: '/SignUpSuccess',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
    },
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/ResetPassword',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  Login: {
    component: Login,
    path: '/Login',
    exact: true,
    allowedParms : {
      allowedToAll : true,
    },
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  SettingProfile: {
    component: SettingProfile,
    path: '/SettingProfile',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
    },
  },
  SuccessPage: {
    component: SuccessPage,
    path: '/SuccessPage',
    exact: true,
    allowedParms : {
      allowedToAll : true,
      // allowedUsers: [HealthCareType.Professional, HealthCareType.Facility],
    },
  },
  ViewJob: {
    component: ViewJob,
    path: '/viewJob',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
    },
  },
  AddEditShift: {
    component: AddEditShift,
    path: '/AddEditShift',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
    },
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/AddEditShift',
    exact: true,
    allowedParms : {
      allowedUsers: [HealthCareType.Professional , HealthCareType.Facility],
    },
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    // <BuilderProvider>
      <View>
        <ToastContainer
            position="top-right"
            autoClose={3000}
            theme="light"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        {/* <TopNav /> */}
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    // </BuilderProvider>
  );
}

export default App;