Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.getUserDetailsAPiEndPoint = "account_block/accounts/show_details";
exports.getFacilityTypeAPiEndPoint = "account_block/accounts/facility_type";
exports.getProfessionalTitlesAPiEndPoint = "account_block/accounts/all_professional_title";
exports.updateUserDetailsAPiEndPoint = "account_block/accounts/update_profile";

exports.getSpecialityAPiEndPoint = "account_block/accounts/specilities";
exports.getCertificationsAPiEndPoint = "account_block/accounts/all_education_certification";
exports.getLicensingAuthorityAPiEndPoint = "account_block/accounts/all_licensing_authority";
exports.getTelehealthAvailabilityAPiEndPoint = "/account_block/accounts/all_telehealth_availability";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.logOutLabel = "Log out";
exports.headerLabel1 = "Setting up your profile";
exports.dotLabel = ".";
exports.facilityHeader1 = "Facility Identification";
exports.facilityHeader2 = "Facility’s location";
exports.facilityHeader3 = "Compliance & Quality Certifications (optional)";
exports.submitButton = "Submit";
exports.backButton = "Back";
exports.continueButton = "Continue";

exports.facilityName = "facility_name";
exports.facilityNameLabel = "Facility Name";

exports.facilityType = "facility_type_id";
exports.facilityTypeLabel = "Facility Type";

exports.facilityLevelRating = "facility_level_rating_id";
exports.facilityLevelRatingLabel = "Facility Level Rating";

exports.facilityKraNumber = "kra_pin_number";
exports.facilityKraNumberLable = "Facility KRA Pin Number";

exports.facilityKmdpCode = "kmpdc_facility_code";
exports.facilityKmdpCodeLabel = "KMPDC Facility Code";

exports.fullName = "full_name";
exports.fullNameLabel = "Full name";

exports.professionalTitle = "professional_title_id";
exports.professionalTitleLabel = "Professional title";

exports.primaryContactTitle = "Primary contact information";

exports.primaryContactType = "primary_contact_register_type";
exports.primaryContactId = "primary_contact_register";
exports.primaryContactTypeLabel = "What ID type of the Primary contact you would like to register?";


exports.primaryContactTypeList = [
  {
    label: "Facility Administrator KRA PIN",
    value: "facilityAdministratorKRAPIN",
  },
  {
    label: "National ID",
    value: "nationalID",
  },
];

exports.countryList = [
  {
    label: "India",
    value: "India",
  },
];

exports.country = "country";
exports.countryLabel = "Country";

exports.county = "county";
exports.countyLabel = "County";

exports.city = "city";
exports.cityLabel = "City";

exports.address = "address";
exports.addressLabel = "Address";

exports.accreditationStatus = "nhif_shif_accreditation_status";
exports.accreditationLabel = "NHIF/SHIF Accreditation Status";

exports.accreditationStatusList = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No (for NHIF coverage)",
    value: "No (for NHIF coverage)",
  },
];

exports.registerFacilitySafeCareCerti = "facility_safe_care_certification_level";
exports.registerFacilitySafeCareCertiLabel = "Does the facility you register has Safe Care Certification level?";

exports.registerFacilitySafeCareCertiList = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

exports.facilitySafeCareCerti = "facility_safe_care_certification_level_dropdown";
exports.facilitySafeCareCertiLabel = "Facility SafeCare Certification level";

exports.facilitySafeCareCertiList = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];

exports.successPageTitle = "Great job! Your profile is being verified!"
exports.successPageDesc = "We will process registered facility profile information <b>within 24 hours.</b><br />You will be notified by primary contact’s email once complete<br />and you will be able to use the application. "
exports.successBtnLabel = "Sign out for now";


exports.pHeader = [
  {
    label: "Basic information",
  },
  {
    label: "Licensing & Credentials",
  },
  {
    label: "Professional Body Memberships",
    optional: true,
    optionalText: "We are almost finished. You <span> can skip</span> this step. There is only one more step left.",
  },
  {
    label: "Availability & Work Preferences"
  }
];
exports.uploadImageText = "Upload picture";
exports.pHeader1 = "Basic information";
exports.pHeader2 = "Licensing & Credentials";
exports.pHeader3 = "Professional Body Memberships";
exports.pHeader4 = "Availability & Work Preferences";

exports.dateOfBirth = "date_of_birth";
exports.dateOfBirthLabel = "Date of birth";
exports.dateOfBirthPlaceholder = "DD/MM/YYYY";
exports.dateFormate = "DD/MM/YYYY";


exports.gender = "gender";
exports.genderLabel = "Gender";

exports.nationality = "nationality";
exports.nationalityLabel = "Nationality";

exports.current_availability_statusList = [
  {
    label: "Available",
    value: "Available",
    style: {minWidth: "152px"},
  },
  {
    label: "Not available",
    value: "Not available",
    style: {minWidth: "152px"},
  },
  {
    label: "On leave",
    value: "On leave",
    style: {minWidth: "152px"},
  },
];

exports.shift_type_availabilityList = [
  {
    label: "Full-time",
    value: "shift_type_full_time",
    style: {minWidth: "152px"},
  },
  {
    label: "Part-time",
    value: "shift_type_part_time",
    style: {minWidth: "152px"},
  },
  {
    label: "Contract",
    value: "shift_type_contract",
    style: {minWidth: "152px"},
  },
];

exports.Preferred_work_settingsList = [
  {
    label: "Hospitals",
    value: "work_location_hospitals",
    style: {minWidth: "152px"},
  },
  {
    label: "Clinics",
    value: "work_location_clinics",
    style: {minWidth: "152px"},
  },
  {
    label: "Home-Based Care",
    value: "work_location_home_based_care",
    style: {minWidth: "208px"},
  },
  {
    label: "Telehealth",
    value: "work_location_home_telehealth",
  },
];

exports.shift_type_preferenceList = [
  {
    label: "Day",
    value: "shift_type_day",
    style: {minWidth: "152px"},
  },
  {
    label: "Night",
    value: "shift_type_night",
    style: {minWidth: "152px"},
  },
  {
    label: "Rotational",
    value: "shift_type_rotational",
    style: {minWidth: "208px"},
  },
];

exports.profile_status = "true";
exports.profilePictureError = "The image exceeded the maximum size.";
// Customizable Area End