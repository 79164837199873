// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, Button, CircularProgress, Container, CssBaseline, IconButton, Snackbar, Typography } from "@mui/material";
import ForgotPasswordController, { Props, configJSON } from "./ForgotPasswordController.web";
import ContentWrapper from "../../../../blocks/email-account-registration/src/components/ContentWrapper.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";
import assets from "../../../../blocks/email-account-login/src/assets";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
        fontSize: "16px",
        color: "#27292A",
        fontWeight: 400,
        "@media (max-width: 600px)": {
          fontSize: "12px",
        },
      },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          borderRadius: "8px",
          textTransform: "none",
          boxShadow: "none",
          fontWeight: "700",
          lineHeight: "1.4",
          padding: "10px 18px",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            padding: "8px 16px",
            fontSize: "12px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
            color: "#FFF",
          }
        },
        text: {
          fontWeight: "800",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  toastAction() {
    return (
      <>
        <IconButton 
          size="small" 
          aria-label="close" 
          color="inherit" 
          onClick={this.closeToastNotification}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <CustomizedSnackbar 
          anchorOrigin={
            { vertical: "top", horizontal: "center" }
          }
          autoHideDuration={4000} 
          open={this.state.openToast} 
          onClose={this.closeToastNotification} 
          message={this.state.message}
          action={this.toastAction()} 
        />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Formik data-test-id="formik" initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.handleSubmit}>
              {({ errors, touched, isValid, isSubmitting, dirty }) => (
                <Form className="form__wrapper">
                  <Box className="form__main__data">
                    
                    <Box className="image__wrapper" sx={{ mb: 2.5 }}>
                      <img className="image" src={assets.security} alt="icon" />
                    </Box>

                    <Typography variant="h1" align="center" data-test-id="page-title" mb={1.5}>
                      {configJSON.forgotPasswordTitle}
                    </Typography>

                    <Typography variant="body2" align="center" mb={3}>
                      {configJSON.forgotPasswordDesc}
                    </Typography>

                    <CustomInput autoFocus label="Email" name="email" fullWidth showEndAdornment={touched.email && !errors.email} /> 

                    <Button
                      data-test-id="submit"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      variant="contained"
                      fullWidth
                      sx={{ mt: { xs: 2, sm: 5 } }}
                    >
                      {isSubmitting ? <CircularProgress color="inherit" size="20px" /> : (configJSON.btnLabel)}
                    </Button>
                </Box>
                </Form>
              )}
            </Formik>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomizedSnackbar = styled(Snackbar)({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    background: "#73B2D1",
    color: "#FFF",
    border: "none",
    boxShadow: "0px 4px 12px 0px #3B7E9F59",
    maxHeight: "44px",
    lineHeight: 1,
  },
  "& .MuiSnackbarContent-message": {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "14px",
    fontWeight: 700,
  }
});


const BoxWrapper = styled(Container)({
  flex: 1,
  overflowY: "auto",
  maxWidth: "495px !important",
  "& .form__wrapper": {
    width: "100%",
    display: "flex",
    height: "100%",
    marginBottom: 0,
    flexDirection: "column",
  },
  "& .form__main__data": {
    flex: 1,
    overflowY: "auto",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "80px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "& .image__wrapper": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  },
  "@media (max-width: 600px)": {
    "& .image": {
      width: "44px"
    }
  }
});
// Customizable Area End
