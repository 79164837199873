import { createTheme } from "@mui/material";
import {yearsOfExperienceList} from "../../../components/src/components/utils/constant"
export const selectFields = [
    {
      name: "professional_title_id",
      label: "Professional title",
      options: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
      ],
    },
    {
      name: "speciality",
      label: "Speciality (Optional)",
      options: [
        { label: "Speciality", value: "Speciality" },
        { label: "Speciality2", value: "Speciality2" },
      ],
    },
    {
      name: "Select Education / Certifications",
      label: "Education / Certifications",
      options: [
        { label: "CERT1", value: "CERT1" },
        { label: "CERT2", value: "CERT2" },
      ],
    },
    {
      name: "Select Years of experience",
      label: yearsOfExperienceList,
    },
  ];
  export const genderList = [
    {
        label: "Male",
        value: "Male",
    },
    {
        label: "Female",
        value: "Female",
    },
    {
        label: "Prefer not to say",
        value: "Prefer not to say",
    },
];

export const telehealthAvailabilitytList = [
    {
        label: "9 AM - 6 PM",
        value: "9 AM - 6 PM",
      },
      {
        label: "9 PM - 6 AM",
        value: "9 PM - 6 AM",
      },
];
export const SettingsTheme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#27292A",
    },
    
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "28px",
      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "left",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
  
});

export const DashboardTheme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#73B2D1",
    },
    text: {
      primary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "28px",
      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "left",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          "@media (min-width: 960px)": {
            paddingLeft: "40px",
            paddingRight: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "none",
          fontSize: "18px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          color: "#0F172A",
          "&:hover": {
            color: "#2EAB65",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#27292A",
          "&:hover": {
            color: "#2EAB65",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: "#FFF",
          minWidth: "unset",
          width: "14px",
          height: "14px",
          fontSize: "10px",
          transform: "scale(1) translate(25%, -25%)",
        },
      },
    },
  },
});