import { FormikProps } from "formik";
import React from 'react';
import { Box, Avatar, Typography, IconButton, Grid, Divider, MenuItem, Popover } from "@mui/material";
import { facilityIcon, settingIcon } from "../../blocks/customisableuserprofiles/src/assets";
import CustomSelect from "./components/customFormComponents/CustomSelect.web";
import CustomInput from "./components/customFormComponents/CustomInput.web";
import { configJSON } from "../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import { facilityLevelRatingList } from "./components/utils/constant";

interface ProfileSectionProps {
  formikProps: FormikProps<any>;
  avatarSrc?: string;
  facilityOptions: { label: string; value: string }[];
  formFields?: { label: string; name: string; type?: string; options?: { label: string; value: string }[] }[];
  countryList: { label: string; value: string }[];
  countyList: { label: string; value: string }[]; 
  handleOpenSettings: any;
  handleClose: any;  
  anchorEl: any
}

const FacilityProfileSection: React.FC<ProfileSectionProps> = ({
  formikProps,
  avatarSrc,
  facilityOptions,
  countryList,
  countyList,
  anchorEl, 
  handleClose, 
  handleOpenSettings
}) => {
  const { values, setFieldValue } = formikProps;
  const nonKenya = values.country !== 'Kenya'
  return (
    <>
      <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: '#fff', minHeight: '88vh', }}>
        <Box
          sx={{
            backgroundColor: '#D1E1E9',
            padding: 2,
            borderRadius: 3,
            display: 'flex',
            justifyContent: 'space-between',
            height: 136
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={facilityIcon}
              alt="Profile Picture"
              sx={{ width: { sm: 96, xs: 50 }, height: { sm: 96, xs: 50 }, marginRight: 2 }}
            />
            <Box>
              <Typography variant="h6" fontWeight={700} fontSize={"28px"} fontFamily={"Manrope"} lineHeight={"44px"}>
                {formikProps.values?.facility_name}
              </Typography>
              <Typography variant="h6" color="#30353B;">
                Primary contact: Christina Theodoridou
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <IconButton aria-label="delete" color="primary" >
              <img src={settingIcon} onClick={handleOpenSettings} />
            </IconButton>
          </Box>
          <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: "#ffffff", 
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", 
                    borderRadius: "8px", 
                    minWidth: "160px", 
                  },
                }}
              >
                <Box sx={{ p: 1 }}>
                  <MenuItem onClick={handleClose} sx={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px", color: "red" }}>
                    Delete an account
                  </MenuItem>
                </Box>
              </Popover>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={configJSON.facilityTypeLabel}
                name={configJSON.facilityType}
                fullWidth
                options={facilityOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={configJSON.facilityLevelRatingLabel}
                name={configJSON.facilityLevelRating}
                fullWidth
                options={facilityLevelRatingList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label={configJSON.facilityKraNumberLable}
                name={configJSON.facilityKraNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label={configJSON.facilityKmdpCodeLabel}
                name={configJSON.facilityKmdpCode}
                fullWidth
              />
            </Grid>
            <Grid sx={{ my: 1, mb: 5, ml: -5, mr: -5 }} xs={14}>
              <Divider />
            </Grid>
            <Typography variant="h5" fontWeight={400} sx={{ ml: 3 }}>
              Facility’s Location
            </Typography>

            <Grid container spacing={2} style={{
              marginTop: '10px'
            }} sx={{ ml: 1 }}>
              <Grid item xs={12} sm={6} >
                <CustomSelect
                  fullWidth
                  name={configJSON.country}
                  label={configJSON.countryLabel}
                  options={countryList}
                  isAutocomplete={true}
                  onChange={(event: any) => {
                    const country = event?.value;
                    if (country !== 'Kenya') {
                      setFieldValue('county', '');
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} >
                <CustomSelect
                  name={configJSON.county}
                  label={configJSON.countyLabel}
                  options={countyList}
                  isAutocomplete={true}
                  disabled={nonKenya}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <CustomInput
                  fullWidth
                  name={configJSON.city}
                  label={configJSON.cityLabel}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                fullWidth
                name={configJSON.address}
                label={configJSON.addressLabel}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}
export default FacilityProfileSection;