Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiFormContentType = "multipart/form-data";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";

exports.getTermsCondsApiEndPoint = "terms_and_conditions/latest_record";
exports.getAllTermsCondsApiEndPoint =
  "terms_and_conditions/terms_and_conditions";
exports.createTermsCondsApiEndPoint =
  "terms_and_conditions/terms_and_conditions";
exports.getAccountGroupsApiEndPoint = "account_groups/groups";
exports.setTermsCondsAcceptanceApiEndPoint =
  "terms_and_conditions/accept_and_reject";

exports.createTermsConds = "Create New";
exports.created = "Created";
exports.tickAccept = "Please Tick To Accept";
exports.updateTermsConds = "Update";
exports.acceptedUsers = "Accepted Users";
exports.saveTermsConds = "Save";
exports.termsCondsUpdatedMassage = "Terms and Conditions Updated Successfully";
exports.termsCondsList = "Terms & Conditions List";
exports.termsConds = "Terms & Conditions";
exports.termsCondsUserList = "Users That Accepted List";

exports.dateFormat = "MM-DD-YYYY";

exports.termsAndConditions = "Terms and Conditions";
exports.privPolicy = "Privacy Policy";
exports.download = "Download PDF";
exports.backTo = "Back to Sign Up";
exports.goBack = "Go Back";
exports.title = "Terms and conditions";
exports.intro = "Introduction";
exports.welcomeToAfya = `Welcome to Afya Matoh Community! <br />
              The Afya Matoh Community Site (hereinafter referred to as
              <strong> “ Community Site ” </strong>) is a space for mutual assistance and the
              exchange of information intended for Users and Visitors of the
              services provided by Afya Matoh. <br />
              It allows Afya Matoh Customers to obtain information, ask
              questions and share their advice in order to facilitate and
              optimize the use of Afya Matoh services. It also allows each
              Internet user who is a Visitor to the Community Site to consult
              its content.`;
exports.purpose = "1. Purpose";
exports.purposeDescription = `The purpose of these Terms of Use (hereinafter referred to as
              <strong> “ TOS ” </strong> ) is to define (i) the conditions of use
              of the Afya Matoh Community Site and (ii) the rights and
              obligations of Users and Visitors.`;

exports.definitions = "2. Definitions";
exports.afya = "“ Afya Matoh Subscriber ”";
exports.afyaMeaning = `means indifferently (i) any natural person Healthcare
                Professional practicing as a liberal, (ii) any legal entity
                under private law bringing together Healthcare Professionals for
                the exercise of their profession and (iii) any legal entity
                under public law bringing together Healthcare Professionals for
                the exercise of their profession having subscribed to the
                services offered by Afya Matoh, and this in accordance with the
                conditions of use and subscription of the Afya Matoh services.`;

exports.health = "“ Healthcare Specialist ”";
exports.healthMeaning = `means professionals working in the healthcare sector, and who
                are eligible to use the services offered by Afya Matoh, in
                accordance with the conditions of use and subscription of Afya
                Matoh services.`;

exports.assistant = "“ Assistant ”";
exports.assistanMeaning = `means any type of personnel (i) assisting a Afya Matoh Client
                and (ii) performing actions in the name and on behalf of said
                Client, such as administrative staff, medical secretaries,
                medical or dental assistants and medical auxiliaries.`;
exports.client = "“ Afya Matoh Client ”";
exports.clientMeaning = `means any type of personnel (i) assisting a Afya Matoh Client
                and (ii) performing actions in the name and on behalf of said
                Client, such as administrative staff, medical secretaries,
                medical or dental assistants and medical auxiliaries.`;
exports.community = "“ Afya Matoh Community Account ”";
exports.communityMeaning = `means the free space, accessible from the URL
                address community.doctolib.fr , and/or current and future Afya
                Matoh applications, which any Afya Matoh Client activates during
                their first visit to the Community Site in accordance with these
                TOS, and from which they can (i) distribute and view their
                Publications and comments and (ii) view and react to the
                Publications and comments of other Users.`;
// Customizable Area End