import React, { ReactNode } from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Container,
  createTheme,
  CSSObject,
  styled,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import assets from "./../assets";
// Customizable Area End

interface S {}
interface Props {
  navigation: any;
  redirectTo: any;
  downloadPDF: any;
  isLoading: boolean;
  textDetails: string;
  configJSON: any;
  isTerms: boolean;
  isUserLoggedIn?: any;
  onBackPress?: any;
}

export default class MainLayout extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <BoxWrapper data-test-id="wrapper">
          <nav>
            <div className="header">
              <img className="logo" src={assets.logo1}></img>
              <label className="hamburger">
                <input type="checkbox" name="hamburger" id="hamburger" />
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <div className="actions">
              <div className="links">
                <Button
                  variant={this.props.isTerms ? "outlined" : "text"}
                  sx={{
                    justifyContent: "start",
                    backgroundColor: this.props.isTerms ? "#CDE7EA" : "",
                  }}
                  onClick={() => this.props.redirectTo("TermsConditionsWeb")}
                >
                  {this.props.configJSON.termsAndConditions}
                </Button>
                <Button
                  variant={!this.props.isTerms ? "outlined" : "text"}
                  sx={{
                    justifyContent: "start",
                    backgroundColor: !this.props.isTerms ? "#CDE7EA" : "",
                  }}
                  onClick={() => this.props.redirectTo("PrivacyPolicyWeb")}
                >
                  {this.props.configJSON.privPolicy}
                </Button>
              </div>
              <div className="buttons">
                <Button
                  variant="outlined"
                  onClick={this.props.downloadPDF}
                  data-test-id="download"
                >
                  <Box component="img" src={assets.download} mr={1}></Box>
                  {this.props.configJSON.download}
                </Button>
                 <Button
                  variant="contained"
                  onClick={this.props.onBackPress}
                  data-test-id="goBack"
                >
                  <Box
                    component="img"
                    src={assets.arrowLeft}
                    sx={{ marginRight: "8px" }}
                  ></Box>
                  {this.props.isUserLoggedIn ? this.props.configJSON.goBack :this.props.configJSON.backTo}
                </Button>
              </div>
            </div>
          </nav>
          <section className="details" data-text-pdf>
            <Container className="details__container">
            <Typography variant="h1" className="defTitle">
              {this.props.isTerms ? "TERMS AND CONDITIONS" : "PRIVACY POLICY"}
            </Typography>
            {this.props.isLoading ? (
              <Typography variant="body1">Loading...</Typography>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: this.props.textDetails }}
              ></div>
            )}
            </Container>
          </section>
        </BoxWrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
      light: "#4DD8B5",
      dark: "#007A5E",
    },
    text: {
      primary: "#27292A",
      secondary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "48px",
      fontWeight: 500,
      lineHeight: "65.57px",
      textTransform: "uppercase",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "36px",
      },
    },
    h2: {
      fontSize: "16px",
      fontWeight: 800,
      lineHeight: "21.86px",
      textTransform: "uppercase",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "22px",
      },
    },
    body1: {
      fontSize: "16px",
      lineHeight: 1.25,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
      color: "#27292A",
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "700",
          padding: "10px 16px",
          boxShadow: "none",
          maxHeight: "44px",
          "&:disabled": {
            backgroundColor: "#A8D5B4",
            color: "#8a8a8a",
          },
          "@media (max-width: 600px)": {
            fontSize: "14px",
            padding: "8px 16px",
          },
        },
        contained: {
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#23894F",
          },
        },
      },
      defaultProps: {
        disableElevation: true,
        size: "medium",
      },
    },
  },
});

type CSSNested = CSSObject & Record<string, CSSObject | string | unknown>;


const BoxWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "365px 1fr",
  minHeight: "100vh",
  overflow: "hidden", //hide the mobile menu

  "& nav": {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    backgroundColor: "#E2E8F0",
    padding: "52px 40px",
    ".header": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "36px",
      "& .logo": {
        maxWidth: "140px",
      },

      "& .hamburger": {
        display: "none",
        alignContent: "space-evenly",
        span: {
          backgroundColor: "gray",
          height: "4px",
          width: "32px",
          borderRadius: "4px",
        },
        input: { display: "none" },
      },
    },
    "& .actions": {
      display: "grid",
      gap: "16px",
      height: "100%",
      alignContent: "space-between",
      "& .links": { display: "grid", gap: "16px" },
      "& .buttons": { display: "grid", gap: "24px" },
    },
  },
  "& .details": {
    fontFamily: "Manrope",
    overflowY: "scroll",
    padding: "52px 0",
    "& .details__container": {
      maxWidth: "875px",
    },
    "& .defTitle": {
      fontWeight: 500,
      marginBottom: "32px",
    },
  },

  "@media (width < 900px)": {
    gridTemplateColumns: "290px 1fr",
  },

  "@media (width < 768px)": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",

    "nav:has(#hamburger:checked) .actions": {
      transform: "translateX(0)",
    },
    "nav:has(#hamburger:checked) .hamburger span": {
      backgroundColor: "#c5c5c5",
    },

    "& nav": {
      padding: "16px 32px",
      ".header": {
        marginBottom: 0,
        "& .logo": { width: "64px" },
        "& .hamburger": {
          display: "grid",
        },
      },
      "& .actions": {
        boxSizing: "border-box",
        position: "fixed",
        left: 0,
        top: "68px",
        width: "100%",
        height: "calc(100% - 68px)",
        padding: "32px",
        backgroundColor: "#E2E8F0",
        display: "grid",
        transform: "translateX(100%)",
        transition: "0.1s all ease-out",
        button: {
          padding: "8px 16px",
        },
      },
    },
    "& .details": {
      overflowY: "visible",
    },
  },
} as CSSNested);
// Customizable Area End
