// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, 
{ getName } from "../../../../framework/src/Messages/MessageEnum";


// Customizable Area End

export const configJSON = require("../config");
// Interface for a single service item
interface ServiceItem {
  name: string;
  id: number;
}

// Interface for the list of services
interface ServiceListProps {
  filtredServicesList: ServiceItem[];
  handleSetService: (service_name: string, service_id: string) => void;
  selectedService: SelectedService;
}

// Interface for selected service
interface SelectedService {
  name: string;
  service_id: string;
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  children: React.ReactNode
  filtredServicesList?: ServiceItem[];
  handleSetService?: (service_name: string, service_id: string) => void;
  selectedService?: SelectedService;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  openContactUs: boolean;
  settingSideBarDrawer: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardLayoutController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      openContactUs: false,
      settingSideBarDrawer: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    // const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  }
  redirectTo = (screen: string) => this.props.navigation?.navigate(screen)

  handleDrawerToggle = () => this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }))
  updateScreenSize = () => {
    const isMobile = window.innerWidth < 900;
    if (isMobile) {
      this.setState({ mobileOpen: false, settingSideBarDrawer: false });
    }
  };

  handleCloseSettingsContactUs = () => this.setState({ settingSideBarDrawer: false })
  handleOpenContactUs = () => this.setState({ openContactUs: true })
  handleCloseContactUs = () => this.setState({ openContactUs: false })
  handleSettingsDrawerToggle = () => this.setState((prevState) => ({ settingSideBarDrawer: !prevState.settingSideBarDrawer }))
  // Customizable Area End
}
