export const countries = [
    { name: "Afghanistan", code: "AF", phoneCode: "+93", value: "Afghanistan", nationality: "Afghan" },
    { name: "Albania", code: "AL", phoneCode: "+355", value: "Albania", nationality: "Albanian" },
    { name: "Algeria", code: "DZ", phoneCode: "+213", value: "Algeria", nationality: "Algerian" },
    { name: "Andorra", code: "AD", phoneCode: "+376", value: "Andorra", nationality: "Andorran" },
    { name: "Angola", code: "AO", phoneCode: "+244", value: "Angola", nationality: "Angolan" },
    { name: "Argentina", code: "AR", phoneCode: "+54", value: "Argentina", nationality: "Argentine" },
    { name: "Australia", code: "AU", phoneCode: "+61", value: "Australia", nationality: "Australian" },
    { name: "Austria", code: "AT", phoneCode: "+43", value: "Austria", nationality: "Austrian" },
    { name: "Bangladesh", code: "BD", phoneCode: "+880", value: "Bangladesh", nationality: "Bangladeshi" },
    { name: "Belgium", code: "BE", phoneCode: "+32", value: "Belgium", nationality: "Belgian" },
    { name: "Brazil", code: "BR", phoneCode: "+55", value: "Brazil", nationality: "Brazilian" },
    { name: "Canada", code: "CA", phoneCode: "+1", value: "Canada", nationality: "Canadian" },
    { name: "China", code: "CN", phoneCode: "+86", value: "China", nationality: "Chinese" },
    { name: "Colombia", code: "CO", phoneCode: "+57", value: "Colombia", nationality: "Colombian" },
    { name: "Denmark", code: "DK", phoneCode: "+45", value: "Denmark", nationality: "Danish" },
    { name: "Egypt", code: "EG", phoneCode: "+20", value: "Egypt", nationality: "Egyptian" },
    { name: "Finland", code: "FI", phoneCode: "+358", value: "Finland", nationality: "Finnish" },
    { name: "France", code: "FR", phoneCode: "+33", value: "France", nationality: "French" },
    { name: "Germany", code: "DE", phoneCode: "+49", value: "Germany", nationality: "German" },
    { name: "Greece", code: "GR", phoneCode: "+30", value: "Greece", nationality: "Greek" },
    { name: "India", code: "IN", phoneCode: "+91", value: "India", nationality: "Indian" },
    { name: "Indonesia", code: "ID", phoneCode: "+62", value: "Indonesia", nationality: "Indonesian" },
    { name: "Ireland", code: "IE", phoneCode: "+353", value: "Ireland", nationality: "Irish" },
    { name: "Italy", code: "IT", phoneCode: "+39", value: "Italy", nationality: "Italian" },
    { name: "Japan", code: "JP", phoneCode: "+81", value: "Japan", nationality: "Japanese" },
    { name: "Kenya", code: "KE", phoneCode: "+254", value: "Kenya", nationality: "Kenyan" },
    { name: "Malaysia", code: "MY", phoneCode: "+60", value: "Malaysia", nationality: "Malaysian" },
    { name: "Mexico", code: "MX", phoneCode: "+52", value: "Mexico", nationality: "Mexican" },
    { name: "Netherlands", code: "NL", phoneCode: "+31", value: "Netherlands", nationality: "Dutch" },
    { name: "New Zealand", code: "NZ", phoneCode: "+64", value: "New Zealand", nationality: "New Zealander" },
    { name: "Nigeria", code: "NG", phoneCode: "+234", value: "Nigeria", nationality: "Nigerian" },
    { name: "Norway", code: "NO", phoneCode: "+47", value: "Norway", nationality: "Norwegian" },
    { name: "Pakistan", code: "PK", phoneCode: "+92", value: "Pakistan", nationality: "Pakistani" },
    { name: "Philippines", code: "PH", phoneCode: "+63", value: "Philippines", nationality: "Filipino" },
    { name: "Poland", code: "PL", phoneCode: "+48", value: "Poland", nationality: "Polish" },
    { name: "Portugal", code: "PT", phoneCode: "+351", value: "Portugal", nationality: "Portuguese" },
    { name: "Russia", code: "RU", phoneCode: "+7", value: "Russia", nationality: "Russian" },
    { name: "Saudi Arabia", code: "SA", phoneCode: "+966", value: "Saudi Arabia", nationality: "Saudi" },
    { name: "South Africa", code: "ZA", phoneCode: "+27", value: "South Africa", nationality: "South African" },
    { name: "South Korea", code: "KR", phoneCode: "+82", value: "South Korea", nationality: "South Korean" },
    { name: "Spain", code: "ES", phoneCode: "+34", value: "Spain", nationality: "Spanish" },
    { name: "Sweden", code: "SE", phoneCode: "+46", value: "Sweden", nationality: "Swedish" },
    { name: "Switzerland", code: "CH", phoneCode: "+41", value: "Switzerland", nationality: "Swiss" },
    { name: "Thailand", code: "TH", phoneCode: "+66", value: "Thailand", nationality: "Thai" },
    { name: "Turkey", code: "TR", phoneCode: "+90", value: "Turkey", nationality: "Turkish" },
    { name: "Ukraine", code: "UA", phoneCode: "+380", value: "Ukraine", nationality: "Ukrainian" },
    { name: "United Arab Emirates", code: "AE", phoneCode: "+971", value: "United Arab Emirates", nationality: "Emirati" },
    { name: "United Kingdom", code: "GB", phoneCode: "+44", value: "United Kingdom", nationality: "British" },
    { name: "United States", code: "US", phoneCode: "+1", value: "United States", nationality: "American" },
    { name: "Vietnam", code: "VN", phoneCode: "+84", value: "Vietnam", nationality: "Vietnamese" }
];

export const kenyaCounties = [
    { name: "Baringo", code: "KE-01", phoneCode: "+254", value: "Baringo" },
    { name: "Bomet", code: "KE-02", phoneCode: "+254", value: "Bomet" },
    { name: "Bungoma", code: "KE-03", phoneCode: "+254", value: "Bungoma" },
    { name: "Busia", code: "KE-04", phoneCode: "+254", value: "Busia" },
    { name: "Elgeyo Marakwet", code: "KE-05", phoneCode: "+254", value: "Elgeyo Marakwet" },
    { name: "Embu", code: "KE-06", phoneCode: "+254", value: "Embu" },
    { name: "Garissa", code: "KE-07", phoneCode: "+254", value: "Garissa" },
    { name: "Homa Bay", code: "KE-08", phoneCode: "+254", value: "Homa Bay" },
    { name: "Isiolo", code: "KE-09", phoneCode: "+254", value: "Isiolo" },
    { name: "Kajiado", code: "KE-10", phoneCode: "+254", value: "Kajiado" },
    { name: "Kakamega", code: "KE-11", phoneCode: "+254", value: "Kakamega" },
    { name: "Kericho", code: "KE-12", phoneCode: "+254", value: "Kericho" },
    { name: "Kiambu", code: "KE-13", phoneCode: "+254", value: "Kiambu" },
    { name: "Kilifi", code: "KE-14", phoneCode: "+254", value: "Kilifi" },
    { name: "Kirinyaga", code: "KE-15", phoneCode: "+254", value: "Kirinyaga" },
    { name: "Kisii", code: "KE-16", phoneCode: "+254", value: "Kisii" },
    { name: "Kisumu", code: "KE-17", phoneCode: "+254", value: "Kisumu" },
    { name: "Kitui", code: "KE-18", phoneCode: "+254", value: "Kitui" },
    { name: "Kwale", code: "KE-19", phoneCode: "+254", value: "Kwale" },
    { name: "Laikipia", code: "KE-20", phoneCode: "+254", value: "Laikipia" },
    { name: "Lamu", code: "KE-21", phoneCode: "+254", value: "Lamu" },
    { name: "Machakos", code: "KE-22", phoneCode: "+254", value: "Machakos" },
    { name: "Makueni", code: "KE-23", phoneCode: "+254", value: "Makueni" },
    { name: "Mandera", code: "KE-24", phoneCode: "+254", value: "Mandera" },
    { name: "Marsabit", code: "KE-25", phoneCode: "+254", value: "Marsabit" },
    { name: "Meru", code: "KE-26", phoneCode: "+254", value: "Meru" },
    { name: "Migori", code: "KE-27", phoneCode: "+254", value: "Migori" },
    { name: "Mombasa", code: "KE-28", phoneCode: "+254", value: "Mombasa" },
    { name: "Murang'a", code: "KE-29", phoneCode: "+254", value: "Murang'a" },
    { name: "Nairobi", code: "KE-30", phoneCode: "+254", value: "Nairobi" },
    { name: "Nakuru", code: "KE-31", phoneCode: "+254", value: "Nakuru" },
    { name: "Nandi", code: "KE-32", phoneCode: "+254", value: "Nandi" },
    { name: "Narok", code: "KE-33", phoneCode: "+254", value: "Narok" },
    { name: "Nyamira", code: "KE-34", phoneCode: "+254", value: "Nyamira" },
    { name: "Nyandarua", code: "KE-35", phoneCode: "+254", value: "Nyandarua" },
    { name: "Nyeri", code: "KE-36", phoneCode: "+254", value: "Nyeri" },
    { name: "Samburu", code: "KE-37", phoneCode: "+254", value: "Samburu" },
    { name: "Siaya", code: "KE-38", phoneCode: "+254", value: "Siaya" },
    { name: "Taita Taveta", code: "KE-39", phoneCode: "+254", value: "Taita Taveta" },
    { name: "Tana River", code: "KE-40", phoneCode: "+254", value: "Tana River" },
    { name: "Tharaka Nithi", code: "KE-41", phoneCode: "+254", value: "Tharaka Nithi" },
    { name: "Trans Nzoia", code: "KE-42", phoneCode: "+254", value: "Trans Nzoia" },
    { name: "Turkana", code: "KE-43", phoneCode: "+254", value: "Turkana" },
    { name: "Uasin Gishu", code: "KE-44", phoneCode: "+254", value: "Uasin Gishu" },
    { name: "Vihiga", code: "KE-45", phoneCode: "+254", value: "Vihiga" },
    { name: "Wajir", code: "KE-46", phoneCode: "+254", value: "Wajir" },
    { name: "West Pokot", code: "KE-47", phoneCode: "+254", value: "West Pokot" }
  ];
  
  