import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export type ServiceIcons = {
  black_icon: any | string;
  blue_icon: any | string;
  label: any | string;
};

import SidebarSettingsController, {
  Props,
} from "./FacilitySettingsController.web";
import {logout,view_} from './assets'

// Customizable Area End

export default class SidebarSettings extends SidebarSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  
  
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <>
        <MainWrapper>
        </MainWrapper>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const styles = {
  sidebar: {
    width: 385,
    height: "80vh", // Full height
    padding: "32px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid #ccc",
    backgroundColor: "#f0f4f7",
    scrollbarWidth:'none',
    
  },
  mobileSidebar: {
    width: 300,
    padding: "24px 20px",
  },
  menuButton: {
    position: "absolute",
    top: 16,
    left: 16,
    zIndex: 1300,
  },
};
const ListWrapper = styled(List)({
  padding: "0px !important",
  scrollbarWidth:'none',
  "&.settings-txt": {
    fontSize:28,
    fontWeight:700,
    color:'0F172A'
  }
});

const MainWrapper = styled(Box)({
  padding: "10px !important",
  "@media (max-width: 400px)": {
    width: "200px",
  },
});

const StyledList = styled(ListItem) ({
  height: 48,
  padding: "12px !important",
  marginBottom: "20px",
  borderRadius: "8px !important",
  cursor: 'pointer',
 
  "&.settings-txt": {
    fontSize:28,
    fontWeight:700,
    color:'0F172A'
  },
})

const EstablishmentTab = styled(ListItem)({
  height: 48,
  padding: "12px !important",
  borderRadius: "8px !important",
});

const ListIcon = styled(ListItemIcon)({
  minWidth: 0,
  marginRight: 12,
});
const ListLabel = styled(ListItemText)({
  fontFamily: "Manrope",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#0F172A",
  "& span": {
    fontFamily: "Manrope",
  },
  "&.blue-font": {
    color: "#30353B !important",
    fontSize:16 ,
    fontWeight:700

  },
  "&.black-font": {
    color: "#000 !important",
  },
  "&.blue-font-bold": {
    color: "#30353B !important",
    fontSize:16,
    fontWeight: 400,
    "& span": {
      fontWeight: 400,
    },
  },
 
  
});

const style = {
  listMargin: {
    marginTop: 0,
  } as React.CSSProperties,
  blueBtn: {
    color: "#6F05EA",
  } as React.CSSProperties,
  blackBtn: {
    color: "#4B4B4B",
  } as React.CSSProperties,
  establishmentWrapper: {
    marginTop: 100,
  } as React.CSSProperties,
  scrollbarWidth:'none',
  titleN:{
    color: "#30353B !important",
    fontSize:16 ,
    fontWeight:700
  } as React.CSSProperties,
  profileT:{
    color:'#2EAB65',
    fontSize:16,
    fontWeight:700
  }
};
// Customizable Area End
