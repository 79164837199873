export const logo1 = require("../assets/logo1.png");
export const arrowLeft = require("../assets/arrow-left.png");
export const download = require("../assets/download.png");

const assets = {
  logo1,
  arrowLeft,
  download,
};

export default assets;
