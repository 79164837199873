// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import createRequestMessage from "../../../../blocks/utilities/src/create-request-message";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { ResetPasswordErrorResponse, ResetPasswordFormValues } from "./types";
import { isLoggedIn } from "../../../../components/src/components/utils/commonFunctions";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  message: string;
  openToast: boolean;
  token: string | null;
  email: string | null;
  newPasswordRules: {
    hasCapital: boolean | null;
    hasNumber: boolean | null;
    hasSpecial: boolean | null;
    hasMinLength: boolean | null;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  formikHelpersRef: Partial<FormikHelpers<ResetPasswordFormValues>> = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openToast: false,
      message: "",
      token: null,
      email: null,
      newPasswordRules: {
        hasSpecial: null,
        hasCapital: null,
        hasMinLength: null,
        hasNumber: null,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.resetPasswordApiCallId) {
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          this.props.navigation.navigate("Login");
        },
        onFail: () => {

          const errorResponse = (responseJson as ResetPasswordErrorResponse);

            // if(errorResponse.errors[0].token) {

            // }
            if (errorResponse.errors[0].password && this.formikHelpersRef.setErrors && this.formikHelpersRef.setFieldValue) {
              this.formikHelpersRef.setErrors({
                new_password: errorResponse.errors[0].new_password,
              });
              this.formikHelpersRef.setFieldValue("confirm_new_password", "", false)
            }
            if (this.formikHelpersRef.setSubmitting) this.formikHelpersRef.setSubmitting(false);
        },
      });
    }

    // Customizable Area End
  }

  // Customizable Area Start
  initialValues = {
    new_password: "",
    confirm_new_password: "",
  } as ResetPasswordFormValues;

  validationSchema = Yup.object({
    new_password: Yup.string()
      .matches(/[@$!%*?&]/, "Password does not match the requirements")
      .matches(/[A-Z]/, "Password does not match the requirements")
      .min(8, "Password does not match the requirements")
      .matches(/\d/, "Password does not match the requirements")
      .required("Create new password is required"),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref("new_password")], "Create Password does not match Password confirmation")
      .required("New password confirmation is required"),
  });

  closeToastNotification = () => this.setState({ message: "", openToast: false });

  async componentDidMount() {
    isLoggedIn(this.props)
    const params = new URLSearchParams(window.location.search);

    this.setState({ email: params.get("email"), token: params.get("token")});
    
  }

  handleSubmit = (values: ResetPasswordFormValues, formikHelpers: FormikHelpers<ResetPasswordFormValues>) => {
    this.formikHelpersRef = formikHelpers;

    const payload = {
      data: {
        new_password: values.new_password,
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resetPasswordApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.resetPasswordApiEndPoint,
      method: "POST",
      body: JSON.stringify(payload),
      header: {
        token: this.state.token,
      },
    });
  };

  changePassword = (password: string) => {
    this.setState((prevState) => ({
      ...prevState,
      newPasswordRules: {
        hasCapital: /[A-Z]/.test(password),
        hasSpecial: /[@$!%*?&]/.test(password),
        hasNumber: /\d/.test(password),
        hasMinLength: password.length >= 8,
      },
    }));
  };
  // Customizable Area End
}
