import React from "react";
// Customizable Area Start
import {
  Box, 
  styled,  
 Typography,  
 Grid,
  Button,
  FormControl,
  RadioGroup,
  FormLabel,
  Paper
} from "@mui/material";
import {
  ThemeProvider,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import { 
  Formik, 
  Form, 
  FormikProps, 
  Field 
} from 'formik';

const MainContent = styled("main")(({ theme }) => ({
  background:'#fff',
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: -88 
}));
// Customizable Area End

import {
  configJSON,
} from "./CustomisableUserProfilesController";
import CustomSelect from "../../../components/src/components/customFormComponents/CustomSelect.web";
import { 
    facilitySafeCareCertiList,
} from "../../../components/src/components/utils/constant";
import { FacilitySettingsSchema } from "../../../components/src/components/utils/validationSchema";
import FacilitySettingsController, 
{ Props } from "./FacilitySettingsController.web";
import CustomRadio from "../../../components/src/components/customFormComponents/CustomRadio.web";
import { SettingsTheme } from "../../../components/src/components/GlobalData";
import RenderNotificationPrefernces from "../../../components/src/components/NotificationPreferences.web";
import FacilityProfileSection from "../../../components/src/ProfileSection.web";
import DashboardLayout from "../../../blocks/dashboard/src/components/DashboardLayout.web";
import ContactDetailsCard from "../../../components/src/components/ContactDetailsCard.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
const ContactCard = styled(Box)(({ theme }) => 
  ({
  backgroundColor: "#D1E1E9",
  height: "56px",
  padding: theme.spacing(2),
  borderRadius: "8px 8px 0 0",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
// Customizable Area End

export default class FacilitySettings extends FacilitySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  
 RenderLicensingCredentials = (formikProps: FormikProps<any>) => {
  return (    
      <BoxWrapper data-test-id="licensing-credentials">

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h1" align="left" data-test-id="welcome-label">
                  Contact details
              </Typography>
              <Button variant="text" sx={{ color: "#2EAB65", fontWeight: "800", textTransform: "none", textAlign: 'center',  fontSize: "16px" }}>
                  Add facility contact
              </Button>
          </Box>
          <ContactDetailsCard
              name="Christina Theodoridou "
              phone="+254 0645533111"
              email="christina.theodoridou@example.com"
              kraPin="567899954"
              primary
          />
          <ContactDetailsCard
              name="Alexander Lumier"
              phone="+254 0746677899"
              email="alexander.lumier@example.com"
              kraPin="5600119288"
          />
      </BoxWrapper>
  )
} 
 RenderProfessionalBodyMemberships = () => {
  return (    
      <BoxWrapper>
               <Typography variant="h5" fontWeight="bold">
      Professional body memberships
    </Typography>

    {/* NHIF Accreditation Section */}
    <Box sx={{ backgroundColor: "#F1F4F5", borderRadius: 2, padding: 3, mt: 3 }}>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioFormControl>
              <FormLabel id={configJSON.accreditationStatus}>{configJSON.accreditationLabel}</FormLabel>
              <Field name={configJSON.accreditationStatus} data-test-id="professional-field">
                {({ field, form }: any) => (
                  <RadioGroup
                    {...field}
                    onChange={(event) => {
                      form.setFieldValue(configJSON.accreditationStatus, event.target.value);
                    }}
                    aria-labelledby={configJSON.accreditationStatus}
                    name={configJSON.accreditationStatus}
                    sx={{ gap: { xs: 0, sm: "98px" } }}
                  >
                    {configJSON.accreditationStatusList.map((option: { label: string, value: string | number }) => (
                      <CustomRadio key={option.value} value={option.value} label={option.label} />
                    ))}
                  </RadioGroup>
                )}
              </Field>
            </RadioFormControl>
          </Grid>
        </Grid>
    </Box>

    {/* Safe Care Certification Section */}
    <Box sx={{ backgroundColor: "#F1F4F5", borderRadius: 2, padding: 3, mt: 3 }}>
    <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: { xs: 0.5, md: 1 } }}>
            <RadioFormControl className="safe_care_certi">
              <FormLabel id={configJSON.registerFacilitySafeCareCerti}>{configJSON.registerFacilitySafeCareCertiLabel}</FormLabel>
              <Field name={configJSON.registerFacilitySafeCareCerti} data-test-id="selfcare-field">
                {({ field, form }: any) => (
                  <RadioGroup
                    {...field}
                    onChange={(event) => {
                      form.setFieldValue(configJSON.registerFacilitySafeCareCerti, event.target.value);
                      if (event.target.value === configJSON.registerFacilitySafeCareCertiList[1].value) {
                        form.setFieldValue(configJSON.facilitySafeCareCerti, "");
                      }
                    }}
                    aria-labelledby={configJSON.registerFacilitySafeCareCerti}
                    name={configJSON.registerFacilitySafeCareCerti}
                  >
                    {configJSON.registerFacilitySafeCareCertiList.map((option: { label: string, value: string | number }) => (
                      <CustomRadio key={option.value} value={option.value} label={option.label} />
                    ))}
                  </RadioGroup>
                )}
              </Field>
            </RadioFormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field name={configJSON.registerFacilitySafeCareCerti}>
              {({ field, form }: any) => (
                <CustomSelect
                  key={String(new Date())}
                  name={configJSON.facilitySafeCareCerti}
                  label={configJSON.facilitySafeCareCertiLabel}
                  fullWidth
                  options={facilitySafeCareCertiList}
                  disabled={form.values[configJSON.registerFacilitySafeCareCerti] ? form.values[configJSON.registerFacilitySafeCareCerti] === configJSON.registerFacilitySafeCareCertiList[1].value : true}
                />
              )}
            </Field>
          </Grid>
          </Grid>
          </Box>
    </BoxWrapper>
  )
}
 RenderSecurity = () => {
   return (
    <BoxWrapper data-test-id="security">
              <Typography variant="h1" align="left" data-test-id="welcome-label" mb={4}>
              Security
            </Typography>
            <Box sx={{ backgroundColor: "#F1F4F5", borderRadius: 2, my: 2 , pt:0.8, pl:0.8, pr:0.8, pb: 2}}>
           <ContactCard>
           <Grid container alignItems="center" justifyContent="space-between">
        <Typography fontWeight={700} fontSize={16}>Primary contact</Typography>
        <Button sx={{color: "#2EAB65", fontWeight: "800", textTransform: "none", fontSize: "16px" }}>Modify</Button>
      </Grid>
           </ContactCard>
           <Grid container spacing={2} sx={{ marginTop: 0.9, ml:0.1 }}>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontWeight={700} display="block" fontSize={14}>
            Name
          </Typography>
          <Typography fontSize={14} display="block" fontWeight={400}>Christina Theodoridou</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontSize={14} fontWeight={700} display="block">
            Phone number
          </Typography>
          <Typography fontSize={14} display="block" fontWeight={400}>+254 064553111</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontSize={14} fontWeight={700} display="block">
            Facility Administrator KRA PIN
          </Typography>
          <Typography fontSize={14} display="block" fontWeight={400}>567899954</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontSize={14} fontWeight={700} display="block">
            Email
          </Typography>
          <Typography fontSize={14}
            sx={{
              display: "block",
              wordBreak: "break-word",
              whiteSpace: "wrap",
              fontWeight: "400"
            }}
          >
            christina.theodoridou@example.com
          </Typography>
        </Grid>
      </Grid>
           </Box>
           <Paper
      elevation={0}
      sx={{
        backgroundColor: "#F1F4F5",
        borderRadius: 2,
        padding: "16px 20px",
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Box>
          <Typography fontWeight={700} fontSize={16}>Password</Typography>
          <Typography sx={{ color: "#5E6671" }} fontSize={14} fontWeight={400} >Last update 12/09/2024</Typography>
        </Box>
        <Button sx={{ color: "#2EAB65", fontWeight: "bold", textTransform: "none", fontSize: "16px" }}>
            Reset</Button>
      </Grid>
    </Paper>
    </BoxWrapper>
  )
}
  render() {
    // Customizable Area Start
    
   
    const {currentSettingsTab}=this.state

    return (
      <>   
        <Formik
            data-test-id="formik"
            initialValues={this.state.formData}
            validationSchema={FacilitySettingsSchema}
            enableReinitialize
            onSubmit={this.handleSubmit}
          >
            {(formikProps) => {
           
            return(
              <Form className="form__wrapper">
                <ThemeProvider theme={SettingsTheme}>
                  <DashboardLayout
                    data-test-id="dashboard-layout"
                    navigation={this.props.navigation}
                    filtredServicesList={this.state.filtredServicesList}
                    selectedService={this.state.selectedService}
                    handleSetService={this.handleSetService}
                  >
                    <Box sx={{ display: 'flex', flex: 1, }}>
                      <MainContent >
                        {currentSettingsTab === 0 &&
                          <FacilityProfileSection
                          data-test-id="facility-profile-section"
                            formikProps={formikProps}
                            countryList={this.state.countryList}
                            countyList={this.state.countyList}
                            facilityOptions={this.state.facilityTypeList}
                            handleOpenSettings={this.handleOpenSettings}
                            handleClose={this.handleClose}
                            anchorEl={this.state.anchorEl}  
                          />
                        }
                        {currentSettingsTab === 1 && this.RenderLicensingCredentials(formikProps)}
                        {currentSettingsTab === 2 && this.RenderProfessionalBodyMemberships()}
                        {currentSettingsTab === 4 && RenderNotificationPrefernces()}
                        {currentSettingsTab === 5 && this.RenderSecurity()}
                      </MainContent>
                    </Box>
                  </DashboardLayout>
                </ThemeProvider>
              </Form>
            )}}
        </Formik>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  width: "100% !important",
  maxWidth: "none !important",
});
const RadioFormControl = styled(FormControl)({
    width: "100%",
    "& .MuiFormGroup-root	": {
      flexDirection: "row",
      marginTop: "8px",
    },
    "& .MuiFormLabel-root": {
      color: "#27292A !important",
      fontWeight: 700,
    },
    "& .primaryContactType_number": {
      flex: 1,
      "& div	": {
        marginBottom: "0px",
        minHeight: "auto",
      },
    }
  });
// Customizable Area End
