import React from "react";

// Customizable Area Start

import MainLayout from "./MainLayout.web";

// Customizable Area End

import PrivacyPolicyController, {
  Props,
  configJSON,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainLayout
       data-test-id="main-layout"
        downloadPDF={this.pdfDownload}
        redirectTo={this.redirect}
        textDetails={this.state.textDetails}
        isLoading={this.state.isLoading}
        navigation={this.props.navigation}
        configJSON={configJSON}
        isTerms={false}
        isUserLoggedIn={this.isLoggedIn}
        onBackPress ={this.handleBackPress}
      ></MainLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
