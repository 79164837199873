import React, { Component } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Container,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Badge,
  Avatar,
  Typography,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NotificationsNone, SupportAgent } from "@mui/icons-material";
import { getUserProfilePhoto, handleClearStorageAndRedirectToPage, isFacilityUser } from "./utils/commonFunctions";
import { logout, view_ } from "../../../blocks/customisableuserprofiles/src/assets";
export const HeaderLogo = require("../../../components/src/components/logo.png");
const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#73B2D1",
    },
    text: {
      primary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "28px",
      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "left",
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: "32px",
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          "@media (min-width: 960px)": {
            paddingLeft: "40px",
            paddingRight: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "none",
          fontSize: "18px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          color: "#0F172A",
          "&:hover": {
            color: "#2EAB65",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#27292A",
          "&:hover": {
            color: "#2EAB65",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: "#FFF",
          minWidth: "unset",
          width: "14px",
          height: "14px",
          fontSize: "10px",
          transform: "scale(1) translate(25%, -25%)",
        },
      },
    },
  },
});
const navProfessionalItem = ["Dashboard", "My shifts", "My appointments"]
const navFacilityItem = ["Dashboard", "Matched shifts"] 

const navItems = isFacilityUser() ? navFacilityItem : navProfessionalItem;

type AppHeaderProps = {
  handleDrawerToggle: any;
  onProfileClick?: any;
  mobileOpen: boolean;
  children?: React.ReactNode
  navigation: any;
  handleOpenContactUs: any;
  filtredServicesList: any;
  selectedService: any;
  handleSetService: any
};

interface AppHeaderState {
  drawerWidth: string;
}
const drawerWidth = window.innerWidth < 900 ? 285 :  385;
const innerWidth = window.innerWidth < 900 ? 235 : 305;
export default class AppTopHeader extends Component<AppHeaderProps, AppHeaderState> {
  constructor(props: AppHeaderProps) {
    super(props);
    this.state = {
      drawerWidth:"0px"
    }
  }
async componentDidMount(){
  this.updateScreenSize();
  window.addEventListener("resize", this.updateScreenSize);
}
updateScreenSize = () => {
  const isMobile = window.innerWidth < 900;
  if(isMobile){
    this.setState({drawerWidth: "285px"})
  }
};

  navigateToScreens = (screen: string) => {
    switch (screen) {
      case "Dashboard":
        return "Dashboard";
      case "My shifts":
        return "Dashboard";
      case "My appointments":
        return "Dashboard";
      case "Matched shifts":
        return "Dashboard"
         case "TermsConditionsWeb":
        return "TermsConditionsWeb"
    }
  }
  handleNavigation = (screen: string) => {
    const route = this.navigateToScreens(screen);
    this.props.navigation?.navigate(route as never); // `as never` avoids TypeScript errors
  };
  container = window !== undefined ? () => window.document.body : undefined;

  handleSetService = (serviceName: any, index: any) => {
    this.props.handleSetService(serviceName.name, index)
  };
    handleLogOut = () => {
      handleClearStorageAndRedirectToPage(this.props, "/", "reload")
    };

  renderDynamicMenus = () => {
    const serviceNameList = this.props.filtredServicesList?.map((serviceName: any) => serviceName.name)
    return (
      <>
        <Box className="no-scrollbar" style={this.props.mobileOpen ? styles.mobileSidebar : styles.sidebar}>
          <StyledList style={{ marginTop: 60 }}>
            <Typography style={{
              fontSize: 28,
              fontWeight: 700,
              color: '0F172A'
            }}>Settings</Typography>
          </StyledList>
          <ListWrapper style={styles.listMargin} className="no-scrollbar">
            <StyledList data-testid="add-service-tab"
              className={this.props.selectedService.name === "Profile" || serviceNameList.includes(this.props.selectedService.name) ? "mainOptions" : ""} 
              onClick={() => this.handleSetService({ name: serviceNameList[0] }, 0)}>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: this.props.selectedService.name === "Profile" || serviceNameList.includes(this.props.selectedService.name) ? "#2EAB65" : "#30353B"
                }}
              >Profile</Typography>
            </StyledList>
            <Box style={{ marginLeft: 15 }}>
              {this.props.filtredServicesList.map(
                (service: { name: string }, index: any) => {
                  const isSelected = this.props.selectedService?.name === service.name;
                  return (
                    <StyledList key={index} data-testid={`menu-${index}`}
                      onClick={() => this.handleSetService(service, index)} >
                      <Typography style={{
                        color: this.props.selectedService.name === service.name ? "#2EAB65" : "#30353B",
                        fontWeight: isSelected ? "700" : "400"
                      }} >{service.name}</Typography>
                    </StyledList>

                  );
                }
              )}
            </Box>
            <StyledList data-testid="add-service-tab"
              className={this.props.selectedService.name == "Notification Preferences" ? 'mainOptions' : ''}
              onClick={() => this.handleSetService({ name: 'Notification Preferences' }, "4")} sx={{ mt: 3 }}>
              <Typography style={{
                fontSize: "16px", fontWeight: 700,
                color: this.props.selectedService.name === "Notification Preferences" ? "#2EAB65" : "#30353B"
              }}>Notification Preferences</Typography>
            </StyledList>
            <StyledList data-testid="add-service-tab" sx={{ mb: 10 }}
              className={this.props.selectedService.name == "Security" ? 'mainOptions' : ''}
              onClick={() => this.handleSetService({ name: 'Security' }, "5")}>
              <Typography style={{ fontSize: "16px", fontWeight: 700, color: this.props.selectedService.name === "Security" ? "#2EAB65" : "#30353B" }} >Security</Typography>
            </StyledList>
            <ListWrapper style={styles.establishmentWrapper}>
              <EstablishmentTab
                id="establishment-setting"
                data-test-id="navigation-Button"
                onClick={()=>this.handleNavigation("TermsConditionsWeb")}
              >
                <ListIcon><img src={view_} /></ListIcon>
                <Typography className="blue-font-bold">
                  Terms and Conditions
                </Typography>
              </EstablishmentTab>
              <EstablishmentTab onClick={this.handleLogOut}
                id="establishment-setting"
                data-test-id="navigation-Button"
              >
                <ListIcon><img src={logout} /></ListIcon>
                <Typography className="blue-font-bold" style={{ color: '#DC2626' }}>
                  Log out
                </Typography>
              </EstablishmentTab>
            </ListWrapper>
          </ListWrapper>
        </Box>
      </>
    );
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBarWrapper elevation={0} data-test-id="header" position="sticky">
          <Container className="container">
            <Toolbar className="toolbar">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={this.props.handleDrawerToggle}
                className="menuBtn"
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon color="primary" />
              </IconButton>
              <img className="header__logo" src={HeaderLogo} />

              <Box sx={{ display: { xs: "none", sm: "flex" } }} flex={1} gap={2}>
                {navItems.map((item) => (
                  <Button key={item} variant="text" onClick={()=> this.handleNavigation(item)}>
                    {item}
                  </Button>
                ))}
              </Box>

              <Box display="flex" gap={0.5}>
                <IconButton onClick={this.props.handleOpenContactUs}>
                  <SupportAgent />
                </IconButton>
                <IconButton>
                  <Badge badgeContent={1} color="secondary">
                    <NotificationsNone />
                  </Badge>
                </IconButton>
                <Box ml={0.75} data-test-id="profile-pic" onClick={this.props.onProfileClick}>
                  <Avatar alt="Remy Sharp" src={getUserProfilePhoto()} style={{cursor: "pointer"}} />
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBarWrapper>
        {(this.props.navigation?.state?.routeName !== "/CustomisableUserProfiles") ? <nav>
          <Drawer
            container={this.container}
            variant="temporary"
            open={this.props.mobileOpen}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "250px" },
            }}
          >
            <Box onClick={this.props.handleDrawerToggle}>
              <List>
                {navItems.map((item) => (
                  <ListItem key={item} disablePadding>
                    <ListItemButton>
                      <ListItemText primary={item} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </nav>:
        <Drawer
          variant={window.innerWidth < 900 ? "temporary":"permanent"}
          className="no-scrollbar"
          anchor="left"
          open={this.props.mobileOpen}
          onClose={this.props.handleDrawerToggle}
          PaperProps={{
            style: {
              backgroundColor: "#f0f4f7",
              scrollbarWidth: 'none',
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              // width: drawerWidth-100,
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        > {this.renderDynamicMenus()}
        </Drawer>}
      </ThemeProvider>

    );
  }
}

const AppBarWrapper = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  zIndex: theme.zIndex.drawer + 1,
  "& .container": {
    paddingTop: "16px",
    paddingBottom: "16px",
    maxWidth: "100%",
    "& .toolbar": {
      padding: "0"
    },
    "& .header__logo": {
      paddingRight: "32px",
    },
    "& .active": {
      color: "#2EAB65",
    },
  },
}));

const styles = {
  sidebar: {
    width: 385,
    height: "80vh", // Full height
    padding: "32px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid #ccc",
    backgroundColor: "#f0f4f7",
    scrollbarWidth: 'none',

  },
  mobileSidebar: {
    width: 300,
    padding: "24px 20px",
  },
  menuButton: {
    position: "absolute",
    top: 16,
    left: 16,
    zIndex: 1300,
  },
  listMargin: {
    marginTop: 0,
  } as React.CSSProperties,
  establishmentWrapper: {
    marginTop: 200,
  } as React.CSSProperties,
  scrollbarWidth: 'none',
};
const ListWrapper = styled(List)({
  padding: "10px !important",
  scrollbarWidth: 'none',
  "&.settings-txt": {
    fontSize: 28,
    fontWeight: 700,
    color: '0F172A'
  }
});

const MainWrapper = styled(Box)({
  padding: "10px !important",
  "@media (max-width: 400px)": {
    width: "200px",
  },
});

const StyledList = styled(ListItem)({
  height: 48,
  padding: "12px !important",
  borderRadius: "8px !important",
  cursor: 'pointer',

  "&.settings-txt": {
    fontSize: 28,
    fontWeight: 700,
    color: '0F172A'
  },
  "&.mainOptions": {
    border: "1px solid #D6DEEA",
    padding: 12,
    borderRadius: 12,
    background: '#EFFDFF',
    width: innerWidth,
    color: 'green'
  }
})

const EstablishmentTab = styled(ListItem)({
  height: 48,
  padding: "12px !important",
  borderRadius: "8px !important",
  cursor: "pointer"
});

const ListIcon = styled(ListItemIcon)({
  minWidth: 0,
  marginRight: 12,
  cursor: 'pointer'
});


