export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");

export const profile = require("../assets/profile.png");
export const security = require("../assets/security.png");

const assets = {
    profile,
    security
}

export default assets;