import { createTheme, Theme } from "@mui/material/styles";

export const facilityLevelRatingList = [
    {
        label: 1,
        value: 1,
    },
    {
        label: 2,
        value: 2,
    },
    {
        label: 3,
        value: 3,
    },
    {
        label: 4,
        value: 4,
    },
    {
        label: 5,
        value: 5,
    },
];

export const facilitySafeCareCertiList = [
    {
        label: 1,
        value: 1,
    },
    {
        label: 2,
        value: 2,
    },
    {
        label: 3,
        value: 3,
    },
    {
        label: 4,
        value: 4,
    },
    {
        label: 5,
        value: 5,
    },
];

export const genderList = [
    {
        label: "Male",
        value: "Male",
    },
    {
        label: "Female",
        value: "Female",
    },
    {
        label: "Prefer not to say",
        value: "Other",
    },
];

export const yearsOfExperienceList = [
    {
        label: "0-2 years",
        value: "0-2 years",
    },
    {
        label: "2-5 years",
        value: "2-5 years",
    },
    {
        label: "5-7 years",
        value: "5-7 years",
    },
    {
        label: "7-10 years",
        value: "7-10 years",
    },
    {
        label: "10-15 years",
        value: "10-15 years",
    },
    {
        label: "15-20 years",
        value: "15-20 years",
    },
    {
        label: "20+ years",
        value: "20+ years",
    },
];

export const telehealthAvailabilitytList = [
    {
        label: "9 AM - 6 PM",
        value: "9 AM - 6 PM",
    },
    {
        label: "9 PM - 6 AM",
        value: "9 PM - 6 AM",
    },
];

export const globalTheme: Theme = createTheme({
    palette: {
        primary: {
            main: "#2EAB65",
        },
        secondary: {
            main: "#73B2D1",
        },
        text: {
            primary: "#27292A",
        },
    },
    typography: {
        fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: "26px",
            lineHeight: 1.25,
            fontWeight: 800,
            color: "#27292A",
            "@media (max-width: 600px)": {
                fontSize: "20px",
            },
        },
        h2: {
            fontSize: "28px",
            color: "#0F172A",
        },
        h3: {
            fontSize: "18px",
            fontWeight: 700,
            color: "#0F172A",
        },
        h4: {
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "24px",
        },
        h5: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
        },
        h6: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            color: "#30353B",
            "@media (max-width: 600px)": {
                fontSize: "14px",
            },
        },
        subtitle1: {
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 800,
            color: "#27292A",
            "@media (max-width: 600px)": {
                fontSize: "20px",
            },
        },
        subtitle2: {
            lineHeight: "32px",
            fontSize: "24px",
            "@media (max-width: 600px)": {
                fontSize: "20px",
            },
        },
        body1: {
            fontSize: "14px",
            color: "#27292A",
            fontWeight: 800,
            "@media (max-width: 600px)": {
                fontSize: "12px",
            },
        },
        body2: {
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "20px",
            "@media (max-width: 600px)": {
                fontSize: "12px",
            },
        },
        caption: {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: 400,
            "@media (max-width: 600px)": {
                fontSize: "10px",
            },
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    "@media (min-width: 960px)": {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    fontSize: "14px",
                    textTransform: "none",
                    fontWeight: "700",
                    color: "#FFF",
                    boxShadow: "none",
                    padding: "10px 18px",
                    lineHeight: "24px",
                    "&:hover": {
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "12px",
                        padding: "8px 16px",
                    },
                },
                outlined: {
                    color: "#2EAB65",
                },
                text: {
                    color: "#2EAB65",
                    "&:hover": {
                        boxShadow: "none"
                    },
                },
                contained: {
                    color: "#FFF",
                    "&:hover": {
                        backgroundColor: "#23894F",
                    },
                    "&:disabled": {
                        color: "#FFF",
                        backgroundColor: "#B6D4E3",
                    }
                },
                containedSecondary: {
                    color: "#FFF",
                    "&:hover": {
                        backgroundColor: "#23894F",
                    },
                    "&:disabled": {
                        color: "#FFF",
                        backgroundColor: "#B6D4E3",
                    }
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#27292A",
                    "&:hover": {
                        color: "#2EAB65",
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    color: "#FFF",
                    minWidth: "unset",
                    width: "14px",
                    height: "14px",
                    fontSize: "10px",
                    transform: "scale(1) translate(25%, -25%)",
                },
            },
        },
    },
})

export const safetyRequirementsList = [
    {
        label: "Vaccination",
        value: "Vaccination",
        style: { minWidth: "152px" },
    },
    {
        label: "PPE usage",
        value: "PPE usage",
        style: { minWidth: "152px" },
    },
    {
        label: "Social Distance",
        value: "Social Distance",
        style: { minWidth: "208px" },
    },
    {
        label: "Filtration",
        value: "Filtration",
        style: { minWidth: "136px" },
    },
];