import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, 
{ getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createTheme } from "@mui/material/styles";
import { SyntheticEvent } from "react";
import * as Yup from "yup";
import { apiCall, 
  convertAllFormData, 
  formatListArray, 
  handleExpiredToken} from "../../../components/src/components/utils/commonFunctions";
import { handleResponseMessage } from "../../../blocks/utilities/src/handle-response-message";
import { toast } from "react-toastify";
import { 
  countries, 
  kenyaCounties } from "../../../components/src/components/utils/countryCounty";
import { OptionType } from "../../../components/src/components/utils/commonTypes";
import { FormikErrors,  
  FormikTouched } from "formik";
let theme = createTheme();
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
export interface IFilteredService{
  name:string,
  service_id:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  //Side bar
  isSideBarOpen:boolean;
  selectedService: IFilteredService;
  currentSettingsTab:any;
  isMobile:boolean;
  mobileOpen: boolean;
  professional_title: string,
  formData:{
      full_name: string,
      date_of_birth:string,
      gender: string,
      nationality:string,
      country:string,
      county:string,
      address:string,
      professional_title_id: string,
      speciality_id: string,
      education_certification_id:string,
      year_of_expirience: string,
      professional_indemnity:string,
      professional_licence_number:string,
      licensing_authority_id:string,
      licence_ex_date:string,
      kra_pin_number: string,
      nssf_number: string,
      professional_body_membership:string,
      shif_number: string,
      current_availability_status: boolean | string,
      shift_type_full_time:boolean,
      shift_type_part_time: boolean,
      shift_type_contract: boolean,
      work_location_hospitals: boolean,
      work_location_clinics: boolean,
      work_location_home_based_care: boolean,
      work_location_home_telehealth: boolean,
      county_preferences: string[],
      shift_type_day: boolean,
      shift_type_night: boolean,
      shift_type_rotational: boolean,
      desired_hourly_shift_rate: string,
      shift_type_preferences: string,
      city: string,
      profile_photo: string,
      telehealth_availability_id: string
  };
  isContactModal: boolean;
  isDataSaved: boolean;
  isAvailable: boolean,
  selectedWorkPreferences: any,
  selectedWorkLocations: any,
  county: any,
  telehealthAvailability: any,
  professionalTitleList: any,
  eduCertiList: any,
  licensingAuthList: any,
  specialityList: any,
  shiftPreference: any,
  hourlyRate: any,
  filtredServicesList: any
  anchorEl: any,
  countryList: OptionType[];
  countyList: OptionType[];
  nationalityList: OptionType[];
  showSaveModal: boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  getLicenseAuthorityID: string ="";
  getEducationCertificationID: string ="";
  getprofessionalTitlesID: string ="";
  getSpecialitiesID: string ="";
  getTelehealthAvailabilityID: string ="";
  updateUserProfileApiCallId:string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getUserDataApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      //Side bar
      isSideBarOpen:true,
      selectedService: {
        name: "",
        service_id: "",
      },
      currentSettingsTab:0,
      isMobile:false,
      mobileOpen: false,
      isAvailable: false,
      selectedWorkPreferences: [],
      selectedWorkLocations: [],
      county: "Nairobi, Nyamira",
      telehealthAvailability: [],
      professionalTitleList: [],
      eduCertiList: [],
      licensingAuthList: [],
      specialityList: [],
      shiftPreference: [],
      hourlyRate: "250",
      filtredServicesList: [
        { name: "General Settings", id: 1 },
        { name: "Licensing & Credentials", id: 2 },
        { name: "Professional Body Memberships", id: 3 },
        { name: "Availability & Work Preferences", id: 4 },
      ],
      anchorEl: null,
      showSaveModal: false,
      professional_title: "",
      formData: {
        full_name: "",    
        date_of_birth: "",
        gender: "",
        nationality: "",
        country: "",
        county: "",
        city: "",
        address: "",
        professional_title_id: "",
        speciality_id: "",
        education_certification_id: "",
        year_of_expirience: "",
        professional_indemnity: "",
        professional_licence_number: "",
        licensing_authority_id: "",
        licence_ex_date: "",
        kra_pin_number: "",
        nssf_number: "",
        professional_body_membership: "",
        shif_number: "",
        current_availability_status: false,
        shift_type_full_time: false,
        shift_type_part_time: false,
        shift_type_contract: false,
        work_location_hospitals: false,
        work_location_clinics: false,
        work_location_home_based_care: false,
        work_location_home_telehealth: false,
        county_preferences: [],
        shift_type_day: false,
        shift_type_night: false,
        shift_type_rotational: false,
        desired_hourly_shift_rate: "",
        shift_type_preferences: "",
        profile_photo: "",
        telehealth_availability_id: ""
      },
      countyList: [],
      countryList: [],
      nationalityList: [],
      isDataSaved: false,
      isContactModal:false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    this.handleSetService('General Settings', '0')
    this.getUserDetails()
    const countryList = formatListArray(countries, "name", "value");
    const countyList = formatListArray(kenyaCounties, "name", "value");
    const nationalityList = formatListArray(countries, "nationality", "value");
    this.setState({ countryList, countyList, nationalityList });
    // Customizable Area End
  }

  handleCloseModal = () => {
    this.setState({ showSaveModal: false });
  };

  handleOpenSettings = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseSettings = () => {
    this.setState({ anchorEl: null });
  };

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    } else {
      this.handleApiCallResponses(apiRequestCallId, responseJson)
    }
  };

  handleApiCallResponses=(apiRequestCallId: string, responseJson: any)=>{
    if( apiRequestCallId === this.getUserDataApiCallId){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const res = responseJson?.data?.attributes;
          this.setState({
            formData: {
              full_name: res.full_name,
              date_of_birth: res.date_of_birth,
              gender: res.gender,
              nationality: res.nationality,
              country: res.country,
              county: res.county,
              city: res.city,
              address: res.address,
              professional_title_id: res.professional_title_id,
              speciality_id: res.speciality_id,
              education_certification_id: res.education_certification_id,
              year_of_expirience: res.year_of_expirience,
              professional_indemnity: res.professional_indemnity,
              professional_licence_number: res.professional_licence_number,
              licensing_authority_id: res.licensing_authority_id,
              licence_ex_date: res.licence_ex_date,
              kra_pin_number: res.kra_pin_number,
              nssf_number: res.nssf_number,
              professional_body_membership: res.professional_body_membership,
              shif_number: res.shif_number,
              current_availability_status: res.current_availability_status,
              shift_type_full_time: res.shift_type_full_time  ,
              shift_type_part_time: res.shift_type_part_time ,
              shift_type_contract: res.shift_type_contract ,
              work_location_hospitals: res.work_location_hospitals ,
              work_location_clinics: res.work_location_clinics ,
              work_location_home_based_care: res.work_location_home_based_care ,
              work_location_home_telehealth: res.work_location_home_telehealth ,
              county_preferences: res.county_preferences,
              shift_type_day: res.shift_type_day ,
              shift_type_night: res.shift_type_night,
              shift_type_rotational: res.shift_type_rotational  ,
              desired_hourly_shift_rate: res.desired_hourly_shift_rate,
              shift_type_preferences: res.shift_type_preferences,
              profile_photo: res.profile_photo,
              telehealth_availability_id: res.telehealth_availability_id
            },
            profilePicture: res.profile_photo,
            professional_title: res.professional_title?.name,
          });
          localStorage.setItem("profile_photo", res.profile_photo)
        },
        onFail: () => {
          console.error("Failed to fetch User data");
        }
      })
    } else if( apiRequestCallId === this.getLicenseAuthorityID){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const array = this.formatArray(responseJson)
          this.setState({licensingAuthList : array});
        },
        onFail: () => {
          console.error("Failed to fetch license authority");
        }
      })
    }else if( apiRequestCallId === this.getSpecialitiesID){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const array = this.formatArray(responseJson)
          this.setState({specialityList: array });
        },
        onFail: () => {
          console.error("Failed to fetch Speciality");
        }
      })
    }else if( apiRequestCallId === this.getEducationCertificationID){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const array = this.formatArray(responseJson)
          this.setState({eduCertiList : array});
        },
        onFail: () => {
          console.error("Failed to fetch Education Certication");
        }
      })
    }else if( apiRequestCallId === this.getprofessionalTitlesID){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const array = this.formatArray(responseJson)
          this.setState({ professionalTitleList: array });
        },
        onFail: () => {
          toast.error("Failed to fetch Professional Titles");
        }
      })
    }else if( apiRequestCallId === this.getTelehealthAvailabilityID){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
          const array = this.formatArray(responseJson)
          this.setState({ telehealthAvailability: array });
        },
        onFail: () => {
          console.error("Failed to fetch Telehealth Availability");
        }
      })
    }else if(apiRequestCallId === this.updateUserProfileApiCallId){
      handleResponseMessage({
        responseJson: responseJson,
        errorJson: null,
        onSuccess: () => {
        if(responseJson.success && responseJson.data){
          this.setState({isDataSaved: true, showSaveModal: false})
          localStorage.setItem("profile_photo",responseJson.data?.attributes?.profile_photo )
        }
        },
        onFail: () => {
          toast.error("Failed to update data");
        }
      })
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      handleExpiredToken(responseJson, this.props);
      if (responseJson) {
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };
  handleSubmit = async (data: any, resetForm: any) => {
    let updatedData = { ...data };
  if ( typeof data.profile_photo === "string") {
    const { profile_photo, ...rest } = updatedData;
    updatedData = rest; // Assign the new object without `profile_photo`
  }
    this.updateUserProfileApiCallId = await apiCall({
      method: configJSON.httpPutMethod,
      body: convertAllFormData(updatedData, "account"),
      endPoint: configJSON.updateUserDetailsAPiEndPoint,
      token: true,
    });
    this.setState({ showSaveModal: false })
    resetForm({values: data,dirty: false})
  };
  handleDiscardChanges = (resetForm: (nextState?: any) => void) => {
    resetForm({ values: this.state.formData }); 
    this.setState({showSaveModal: false})
  };
  formatArray(options: any) {
    return options.map((item: any) => ({
      label: item.name,  // Set name as the display text
      value: String(item.id) // Convert id to string to avoid type issues
    }));
  }
  handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, setTouched: (touched: FormikTouched<any>, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>) => {
    const file = event.target.files?.[0];
    const name = event.target.name
    if (!file) return;
    setTouched({ [name]: true })
    if (file) {
      let allowFile = (file.size <= (1024 * 1024));
      if (allowFile) {
        const fileType = file.type;
        if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
          setFieldValue([name], file);
          this.setState({profilePicture: URL.createObjectURL(file)})
        }
      }
    }
  };

triggerFileInput = () => {
  document.getElementById('profile-image-input')?.click();
};
  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };
  getUserDetails = async () => {
    this.getUserDataApiCallId = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getUserDetailsAPiEndPoint,
      token: true
    });
    this.getSpecialitiesID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSpecialitiesEndPoint,
      token: true
    });
    this.getEducationCertificationID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getEducationCertificationEndPoint,
      token: true
    });
    this.getLicenseAuthorityID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getLicenseAuthorityEndPoint,
      token: true
    });
    this.getTelehealthAvailabilityID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getTelehealthAvailabilityEndPoint,
      token: true
    });
    this.getprofessionalTitlesID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getprofessionalTitlesEndPoint,
      token: true
    });
  }
  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    this.setState({mobileOpen:open });
  };
  handleSetService = (service_name: string, service_id: string) => {
    this.setState({
      selectedService: {
        name: service_name,
        service_id: service_id,
      }
    })
    
    this.setTabId(Number(service_id));
  }
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }
  handleTabChange = ( event: SyntheticEvent<Element, Event>, newValue: { currentSettingsTab: number; }) => {
    this.setState({currentSettingsTab:newValue})
  };
  updateScreenSize = () => {
    const isMobile = window.innerWidth < 900;
    this.setState({ isMobile });

    // Close the sidebar automatically when switching to mobile view
    if (isMobile) {
      this.setState({ mobileOpen: false });
    }
   
  };
  handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;

    // Update state for the form field
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value, // Dynamically update the key
      },
    }));
  };
  onValueChange = (field: string, value: string) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };
  setnewEndDate(date:any){
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        dateOfBirth: date, // Update dateOfBirth
      },
    }));
  }
  toggleModal(){
    this.setState({
      isContactModal:!this.state.isContactModal
    })
  }
  // Customizable Area End
}
