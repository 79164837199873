Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

exports.facilityDashboardTitle = "Welcome back! ";
exports.facilityDashboardSubTitle = "There are new applicants matched your request";
exports.sidebardCardTitle = "Your posted shifts";
exports.addNewShiftLabel = "Add new";
exports.reviewBtnLabel = "Review";
exports.greetings = "Hello ";
exports.professtionalTitle = ", there are new shifts matched your profile";
exports.applyBtnLabel = "Apply";
exports.editLabel = "Edit";
exports.workPreferencesLabel = "Your work preferences";
exports.yourLocation = "Your location";
exports.availibilityStatus = "Current availability status";
exports.shiftAvailibilityStatus = "Shift type availability";
exports.preferredWork = "Preferred work location settings";
exports.shiftPreferences = "Shift type preference";
exports.shiftAvailibility = "Shift type availability";
exports.travelRadius = "Travel radius: ";
exports.telehealthAvailibility = "Telehealth availability: ";
exports.rateLabel = "Desired Hourly/Shift Rate ";
// Customizable Area End