// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, 
{ getName } from "../../../framework/src/Messages/MessageEnum";
import { OptionType } from "../../../components/src/components/utils/commonTypes";
import { apiCall, formatListArray, handleExpiredToken} from "../../../components/src/components/utils/commonFunctions";
import { 
  countries, 
  kenyaCounties 
} from "../../../components/src/components/utils/countryCounty";
import { configJSON } from "./CustomisableUserProfilesController";
import { handleResponseMessage } from "../../../blocks/utilities/src/handle-response-message";
import { toast } from "react-toastify";

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    addServicesList: any;
    selectedAddService: string;
    isFacilitySelected: boolean;
    filtredServicesList:any
    facilityTypeList: OptionType[];
    anchorEl: any,
    anchorEl2: any;
    profilePicture: string;
    currentSettingsTab: any;
    selectedService: IFilteredService;
    formData:{
        nationality:string,
        country:string,
        county:string,
        address:string,
        kra_pin_number: string,
        nssf_number: string,
        shif_number: string,
        profile_photo: string,
        city: string;
        facility_type_id: string;
        facility_level_rating_id: string;
        kmpdc_facility_code: string;
        nhif_shif_accreditation_status: string;
        facility_name: string;
    };
  isMobile: boolean;
  mobileOpen: boolean;
  countryList: OptionType[];
  countyList: OptionType[];
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
export interface IFilteredService{
    name:string,
    service_id:string
  }
// Customizable Area End

export default class FacilitySettingsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getFacilityUserDataApiCallId: string="";
    facilityTypeApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            addServicesList: [],
            selectedAddService: "",
            isFacilitySelected: false,
            filtredServicesList: [
                { name: "General", id: 1 },
                { name: "Contact Details", id: 2 },
                { name: "Professional Body Memberships", id: 3 },
              ],
              facilityTypeList: [],
              anchorEl: null,
              anchorEl2: null,
              profilePicture: "",
              currentSettingsTab: 0,
              selectedService: {
                name: "",
                service_id: "",
              },
              formData: {
                nationality: "",
                country: "",
                county: "",
                city: "",
                address: "",
                kra_pin_number: "",
                nssf_number: "",
                shif_number: "",
                profile_photo: "",
                facility_type_id: "",
                facility_level_rating_id: "",
                kmpdc_facility_code: "",
                nhif_shif_accreditation_status: "",
                facility_name: "",
              },
              isMobile:true,
              mobileOpen: true,
              countryList: [],
              countyList: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

   async componentDidMount(){
       this.getFacilityTypes();
       this.getFacilityUserDetails()
       this.handleSetService('General', '0')
       const countryList = formatListArray(countries, "name", "value");
       const countyList = formatListArray(kenyaCounties, "name", "value");
       this.setState({ countryList, countyList })
       this.updateScreenSize();
       window.addEventListener("resize", this.updateScreenSize);
   }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let resJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          handleExpiredToken(resJson, this.props);
          if (apiRequestCallId && resJson) {
            if (apiRequestCallId === this.facilityTypeApiCallId) {
              handleResponseMessage({
                responseJson: resJson,
                errorJson: null,
                onSuccess: () => {
                  const array = formatListArray(resJson, "name", "id");
                  this.setState({ facilityTypeList: array });
                },
                onFail: () => {
                  toast.error("Failed to fetch facility type list");
                }
              })
            } else if (apiRequestCallId === this.getFacilityUserDataApiCallId) {
              handleResponseMessage({
                responseJson: resJson,
                errorJson: null,
                onSuccess: () => {
                  const res = resJson?.data?.attributes;
                  this.setState({
                    formData: {
                      nationality: resJson.nationality,
                      country: res.country,
                      county: res.county,
                      city: res.city,
                      address: res.address,
                      kra_pin_number: res.kra_pin_number,
                      nssf_number: res.nssf_number,
                      shif_number: res.shif_number,
                      profile_photo: res.profile_photo,
                      facility_type_id: res.facility_type_id,
                      facility_level_rating_id: res.facility_level_rating_id,
                      kmpdc_facility_code: res.kmpdc_facility_code,
                      nhif_shif_accreditation_status: res.nhif_shif_accreditation_status,
                      facility_name: res.facility_name,
                    }
                  });
                },
                onFail: () => {
                  toast.error("Failed to fetch user details");
                }
              })
            }
          }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    
  handleOpenSettings = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  updateScreenSize = () => {
    const isMobile = window.innerWidth < 900;
    this.setState({ isMobile });
    if (isMobile) {
      this.setState({ mobileOpen: false });
    }
  };
  getFacilityTypes = async () => {
    this.facilityTypeApiCallId = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getFacilityTypeAPiEndPoint,
      token: true
    });
  }
  getFacilityUserDetails = async () => {
    this.getFacilityUserDataApiCallId = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getUserDetailsAPiEndPoint,
      token: true
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

    handleSubmit =()=>{}
    handleSetService = (service_name: string, service_id: string) => {
        this.setState({
          selectedService: {
            name: service_name,
            service_id: service_id,
          }
        })
        this.setTabId(Number(service_id));
    }
    setTabId=(id:number)=>{
        this.setState({ currentSettingsTab: id })
      }
    // Customizable Area End
}

