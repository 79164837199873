import React, { Component } from "react";
import { SnackbarContent, Button, Box } from "@mui/material";

// Define Props Type
interface SaveConfirmationProps {
  open: boolean;         // Controls modal visibility
  onClose: () => void;   // Function to close modal
  formikVal: any;
  handleSave: () => void;
  onReset: ()=> void;
  successSnackbarOpenProps : boolean;
}

interface SaveConfirmationState {
  successSnackbarOpen: boolean;
  changesSavedPopup: boolean;
}

class SaveConfirmation extends Component<SaveConfirmationProps, SaveConfirmationState> {
  constructor(props: SaveConfirmationProps) {
    super(props);
    this.state = {
      successSnackbarOpen: false,
      changesSavedPopup: false,
    };
    
  }

  handleSave = () => {
    this.props.handleSave()
    this.props.onClose()

    // Show success message after a short delay
    setTimeout(() => {
      this.setState({ successSnackbarOpen: true });

      // Auto-hide success message after 3 seconds
        setTimeout(() => {
          this.setState({ successSnackbarOpen: false });
        }, 3000);
    }, 300);
  };
  OnReset=()=>{
    this.props.onReset()
    this.setState({successSnackbarOpen: false})
  }

  render() {
    const { open, onClose, onReset } = this.props;
    const { successSnackbarOpen, changesSavedPopup } = this.state;

    return (
      <>
        {/* Confirmation SnackbarContent */}
        {open && (
          <Box
            sx={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              maxWidth: "1014px",
              zIndex: 1400,
            }}
          >
            <SnackbarContent
              sx={{
                backgroundColor: "#73B2D1",
                borderRadius: "8px",
                padding: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              message={
                <Box sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "400", flex: 1 }}>
                  You have made changes within Profile settings. Would you like to save?
                </Box>
              }
              action={
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    onClick={this.OnReset}
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "600",
                      textTransform: "none",
                    }}
                  >
                    No, discard changes
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    onClick={this.handleSave}
                    sx={{
                      backgroundColor: "#FFFF",
                      color: "#2EAB65",
                      fontWeight: "600",
                      textTransform: "none",
                      borderRadius: "8px",
                      padding: "6px 16px",
                    }}
                  >
                    Yes, save
                  </Button>
                </Box>
              }
            />
          </Box>
        )}

        {/* Success SnackbarContent (Properly Centered) */}
        {successSnackbarOpen && (
          <Box
            sx={{
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: { xs: "70vw", sm: "40vw", md: "15vw", lg: "15vw" }, // Adjusted width
              maxWidth: "400px",
              zIndex: 1400,
              height: "28px"
            }}
          >
            <SnackbarContent
              sx={{
                backgroundColor: "#73B2D1",
                color: "#FFFFFF",
                borderRadius: "8px",
                //padding: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Center aligns the text
                fontSize: "14px",
                fontWeight: "700",
              }}
              message={
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}> {/* Adjusted gap */}
                  Changes have been saved
                  <Button
                    onClick={() => this.setState({ successSnackbarOpen: false })}
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "700",
                      textTransform: "none",
                      fontSize: "14px",
                      minWidth: "auto",
                      padding: "0 4px",
                      ml: "2vw"
                    }}
                  >
                    x
                  </Button>
                </Box>
              }
            />
          </Box>
        )}
      </>
    );
  }
}

export default SaveConfirmation;
