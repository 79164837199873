// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { apiCall, convertAllFormData, createLinkAndRedirect, formatListArray, getUser, handleApiError, handleClearStorage, handleClearStorageAndRedirectToPage, handleExpiredToken } from "../../../../components/src/components/utils/commonFunctions";
import { OptionType, UserAttributes } from "../../../../components/src/components/utils/commonTypes";
import { handleResponseMessage } from "../../../../blocks/utilities/src/handle-response-message";
import { toast } from "react-toastify";
import { FacilitySettingSchema1, FacilitySettingSchema2, FacilitySettingSchema3 } from "../../../../components/src/components/utils/validationSchema";
import { ProfileStatus } from "../../../../components/src/components/utils/enumConstant";
import { countries, kenyaCounties } from "../../../../components/src/components/utils/countryCounty";

export interface Step1Values {
  facility_name: string;
  facility_type_id: string;
  facility_level_rating_id: string;
  kra_pin_number: string;
  kmpdc_facility_code: string;
  full_name: string;
  professional_title_id: string;
  primary_contact_register_type: string;
  primary_contact_register: string;
}

export interface Step2Values {
  country: string;
  county: string;
  city: string;
  address: string;
}

export interface Step3Values {
  nhif_shif_accreditation_status: string;
  facility_safe_care_certification_level_dropdown: string;
  facility_safe_care_certification_level: string;
}

export interface FormValues extends Step1Values, Step2Values, Step3Values { }

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  loading: boolean;
  userData: UserAttributes | null;
  facilityTypeList: OptionType[];
  professionalTitleList: OptionType[];
  formValues: FormValues;
  countryList: OptionType[];
  countyList: OptionType[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FacilitySettingProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserDataApiCallId: string = "";
  facilityTypeApiCallId: string = "";
  professionalTitleApiCallId: string = "";
  updateUserProfileApiCallId: string = "";

  initialValues1 = {
    facility_name: "",
    facility_type_id: "",
    facility_level_rating_id: "",
    kra_pin_number: "",
    kmpdc_facility_code: "",
    full_name: "",
    professional_title_id: "",
    primary_contact_register_type: "",
    primary_contact_register: "",
  };

  initialValues2 = {
    country: "",
    county: "",
    city: "",
    address: "",
  };
  initialValues3 = {
    nhif_shif_accreditation_status: "",
    facility_safe_care_certification_level_dropdown: "",
    facility_safe_care_certification_level: "",
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      loading: true,
      userData: null,
      facilityTypeList: [],
      professionalTitleList: [],
      formValues: {
        ...this.initialValues1,
        ...this.initialValues2,
        ...this.initialValues3,
      },
      countyList: [],
      countryList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      handleExpiredToken(resJson, this.props);
      this.setState({ loading: false });
      if (apiRequestCallId && resJson) {
        this.setState({ loading: false });
        if (apiRequestCallId === this.facilityTypeApiCallId) {
          handleResponseMessage({
            responseJson: resJson,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(resJson, "name", "id");
              this.setState({ facilityTypeList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch facility type list");
            }
          })
        } else if (apiRequestCallId === this.professionalTitleApiCallId) {
          handleResponseMessage({
            responseJson: resJson,
            errorJson: null,
            onSuccess: () => {
              const array = formatListArray(resJson, "name", "id");
              this.setState({ professionalTitleList: array });
            },
            onFail: () => {
              toast.error("Failed to fetch professional titles");
            }
          })
        } else if (apiRequestCallId === this.updateUserProfileApiCallId) {
          handleResponseMessage({
            responseJson: resJson,
            errorJson: null,
            onSuccess: () => {
              handleClearStorage();
              createLinkAndRedirect("/SuccessPage")
            },
            onFail: () => {
              handleApiError(resJson.errors)
            }
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserDetails();
    this.getFacilityTypes();
    this.getProfessionalTitles();

    const countryList = formatListArray(countries, "name", "value");
    const countyList = formatListArray(kenyaCounties, "name", "value");
    this.setState({ countryList, countyList });

    const user = getUser();
    this.setState({ userData: user?.data.attributes });
  }

  getValidationSchema = () => {
    if (this.state.activeStep === 0) {
      return FacilitySettingSchema1;
    } else if (this.state.activeStep === 1) {
      return FacilitySettingSchema2;
    } else {
      return FacilitySettingSchema3;
    }
  };

  getUserDetails = async () => {
    this.getUserDataApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getUserDetailsAPiEndPoint,
      token: true
    });
  }

  getFacilityTypes = async () => {
    this.facilityTypeApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getFacilityTypeAPiEndPoint,
      token: true
    });
  }

  getProfessionalTitles = async () => {
    this.professionalTitleApiCallId = await apiCall({
      method: configJSON.fetchFormDataMethod,
      endPoint: configJSON.getProfessionalTitlesAPiEndPoint,
      token: true
    });
  }

  getSteps = () => {
    const steps = [
      {
        label: 'Facility',
        description: 'Identification'

      },
      {
        label: 'Facility’s',
        description: 'location'

      },
      {
        label: 'Compliance & Quality',
        description: 'Certifications (optional)'

      }];
    return steps;
  }

  isLastStep = () => {
    const { activeStep } = this.state;
    return activeStep === this.getSteps().length - 1;
  }

  handleNext = (formData: Partial<FormValues> | undefined) => {
    const { activeStep } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      formValues: { ...prevState.formValues, ...formData }
    }));

    if (activeStep < this.getSteps().length - 1) {
      this.setState((prevState) => ({
        ...prevState,
        activeStep: prevState.activeStep + 1,
      }));
    } else {
      this.handleSubmit(formData)
    }
  };

  getFacilityHeading2 = () => {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      return configJSON.facilityHeader1
    } else if (activeStep === 1) {
      return configJSON.facilityHeader2
    } else {
      return configJSON.facilityHeader3
    }
  };

  handleBack = (formData: Partial<FormValues>) => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
      formValues: { ...prevState.formValues, ...formData }
    }));
  };

  handleSubmit = async (data: Partial<FormValues> | undefined) => {
    this.updateUserProfileApiCallId = await apiCall({
      method: configJSON.updateFormAPiMethod,
      body: convertAllFormData({...data, profile_status: ProfileStatus.InProgress}, "account"),
      endPoint: configJSON.updateUserDetailsAPiEndPoint,
      token: true,
    });
  };

  handleLogOut = () => {
    handleClearStorageAndRedirectToPage(this.props, "/", "reload")
  };

  // Customizable Area End
}
