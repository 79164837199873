// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box, CssBaseline, Container, Button, Typography, Chip, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ResetPasswordController, { Props, configJSON } from "./ResetPasswordController.web";
import { Form, Formik } from "formik";
import ContentWrapper from "../../../../blocks/email-account-registration/src/components/ContentWrapper.web";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#4768BE",
    },
    primary: {
      main: "#2EAB65",
    },
    text: {
      primary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Arial", "Helvetica", sans-serif',
    h1: {
      lineHeight: 1.25,
      fontSize: "26px",
      color: "#27292A",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#000",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "700",
          boxShadow: "none",
          lineHeight: "1.4",
          padding: "10px 18px",
          "@media (max-width: 600px)": {
              fontSize: "12px",
              padding: "8px 16px",
            },
            "&:hover": {
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            },
        },
        outlined: {
          color: "#2EAB65",
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
          color: "#FFF",
            backgroundColor: "#B6D4E3"
          }
        }
      },
    },
  },
});
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Formik
              data-test-id="formik"
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ setFieldValue, touched, isValid, isSubmitting, dirty }) => (
                <Form className="form__wrapper">
                  <Box className="form__main__data">
                    <Typography variant="h1" align="center" data-test-id="reset-password" sx={{ mb: 1.5 }}>
                      {configJSON.resetPasswordTitle}
                    </Typography>

                    <Typography variant="h6" align="center">
                      {configJSON.resetPasswordSubtitle}
                    </Typography>

                    <Typography variant="h6" fontWeight="700" align="center" sx={{ mb: 5 }}>
                      {this.state.email}
                    </Typography>

                    <CustomInput
                      label="Create new password"
                      name="new_password"
                      autoFocus
                      type="password"
                      fullWidth
                      data-test-id="password"
                      onChange={(event) => {
                        setFieldValue("new_password", event.target.value);
                        this.changePassword(event.target.value);
                      }}
                    />

                    <CustomInput label="New password confirmation" name="confirm_new_password" type="password" fullWidth />

                    <Box>
                      <Typography variant="h6" sx={{ mb: { xs: 1, sm: 1.5 } }}>
                        Your password must contain
                      </Typography>

                      <Box className="validation__chip__wrapper">
                        {Object.entries(this.state.newPasswordRules).map(([rule, met]) => {
                          let chipType: "success" | "default" | "error";
                          if (met) {
                            chipType = "success";
                          } else if (touched.new_password) {
                            chipType = "error";
                          } else {
                            chipType = "default";
                          }

                          return (
                            <Chip
                              key={rule}
                              className="validation__chip"
                              size="small"
                              color={chipType}
                              icon={<CheckCircleIcon />}
                              label={configJSON[rule]}
                              variant="outlined"
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>

                  <footer className="form__footer">
                    <Button
                      type="submit"
                      data-test-id="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      variant="contained"
                      fullWidth
                      sx={{ mt: { xs: 2, sm: 3 } }}
                    >
                      {isSubmitting ? <CircularProgress color="inherit" size="20px" /> : configJSON.submitBtnLabel}
                    </Button>
                  </footer>
                </Form>
              )}
            </Formik>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Container)({
  flex: 1,
  overflowY: "auto",
  maxWidth: "495px !important",
  "& .form__wrapper": {
    height: "100%",
    width: "100%",
    display: "flex",
    marginBottom: 0,
    flexDirection: "column",
  },
  "& .form__main__data": {
    flexDirection: "column",
    flex: 1,
    overflowY: "auto",
    scrollbarWidth: "none",
    display: "flex",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .validation__chip__wrapper": {
      display: "flex",
      gap: "4px",
      flexWrap: "wrap",
      "& .validation__chip": {
        fontSize: "12px",
        borderColor: "#E2E8F0",
        fontWeight: 400,
      },
    },
  },
  "& .form__footer": {
    position: "sticky",
    bottom: 0,
    width: "100%",
    "& .terms__wrapper": {
      alignItems: "center",
      display: "flex",
      gap: "8px",
      "& .MuiCheckbox-root": {
        padding: 0,
      },
      "& .footer__link": {
        fontWeight: 800,
        cursor: "pointer",
        color: "#2EAB65",
      },
    },
  },
  "& .error__message": {
    fontWeight: 400,
    fontSize: "12px",
    margin: "2px 3px 0",
    color: "red",
  },
  "& .redirect__message": {
    color: "#2EAB65",
    cursor: "pointer",
    textAlign: "end",
    fontSize: "12px"
  },
  "& .react-tel-input": {
    "& .flag-dropdown ": {
      borderRadius: "8px",
      border: "solid 1px #E2E8F0",
      width: "62px",
      "& .selected-flag": {
        width: "100%",
        background: "#FFF",
        borderRadius: "8px",
        zoom: 1.25,
        padding: "14px 13px",
      },
    },
    "& .form-control": {
      padding: "10px 12px 12px",
      fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
      height: "unset",
      lineHeight: "unset",
      width: "calc(100% - 82px)",
      border: "solid 1px #E2E8F0",
      marginLeft: "82px",
      borderRadius: "8px"
    }
  },
});
// Customizable Area End
