// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box, CssBaseline, Container, Button, Checkbox, Typography, Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SignUpStepOneController, { Props, configJSON } from "./SignUpStepOneController.web";
import ContentWrapper from "./ContentWrapper.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../../components/src/components/customFormComponents/CustomInput.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#27292A",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: 1.5,
      fontWeight: 400,
      color: "#30353B",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      },
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#000",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#000",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          padding: "10px 18px",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
            padding: "8px 16px",
          },
        },
        outlined: {
          color: "#2EAB65",
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
          color: "#FFF",
            backgroundColor: "#B6D4E3"
          }
        }
      },
    },
  },
});
// Customizable Area End

export default class SignUpStepOne extends SignUpStepOneController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Formik
              data-test-id="formik-wrapper"
              initialValues={this.initialValues}
              validationSchema={this.state.isFacilitySelected ? this.facilitySignupSchema : this.professionalSignupSchema}
              onSubmit={this.handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors, touched, isValid, isSubmitting,dirty }) => (
                <Form className="form__wrapper">
                  <Box className="form__main__data">
                    <Box sx={{ mb: { xs: 2, sm: 5 } }}>
                      <Typography variant="h1" align="center" data-test-id="create-account">
                        {configJSON.createYourAccount}
                      </Typography>
                      {this.state.isFacilitySelected && (
                        <Typography variant="h6" align="center" sx={{ mt: 1.25 }}>
                          {configJSON.facilitySignupLabel}
                        </Typography>
                      )}
                    </Box>

                    <CustomInput autoFocus label="Email" name="email" fullWidth showEndAdornment={touched.email && !errors.email}  />

                    {touched.email && errors.email == configJSON.emailError && <Typography className="redirect__message" onClick={this.redirectToLogin}>{configJSON.wantToLogin}</Typography>}

                    {this.state.isFacilitySelected && <CustomInput label="Full Name" name="fullName" fullWidth />}

                    <Typography mb={0.5}>{configJSON.phoneLabel}</Typography>

                    <Box mb={1}>
                      <ReactPhoneInput
                        data-test-id="phone-number"
                        value={values.phone}
                        isValid={!errors.phone}
                        onlyCountries={['in', 'lk', 'ae', 'ke']}
                        country="ke"
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        onChange={(phone, country: CountryData) => {
                          setFieldValue("phone", phone);
                          this.handlePhoneChange(phone, country)
                        }}
                        enableSearch
                        onBlur={() => setFieldTouched("phone", true)}
                        masks={{
                          ae: ".. ... ....",
                          ke: "... ......",
                          lk: ".. ... ....",
                          in: "..... ....."
                        }}
                      />

                      {touched.phone && errors.phone && <Typography className="error__message">{errors.phone}</Typography>}
                    </Box>

                    <CustomInput
                      label="Create Password"
                      name="password"
                      type="password"
                      fullWidth
                      data-test-id="password"
                      onChange={(event) => {
                        setFieldValue("password", event.target.value);
                        this.handlePasswordChange(event.target.value);
                      }}
                    />
                    <CustomInput label="Password Confirmation" name="confirmPassword" type="password" fullWidth />

                    <Box>
                      <Typography variant="h6" sx={{ mb: { xs: 1, sm: 1.5 } }}>
                        Your password must contain
                      </Typography>

                      <Box className="validation__chip__wrapper">
                        {Object.entries(this.state.passwordRules).map(([rule, met]) => {
                          let chipColor: "success" | "error" | "default";
                          if (met) {
                            chipColor = "success";
                          } else if (touched.password) {
                            chipColor = "error";
                          } else {
                            chipColor = "default";
                          }

                          return (
                            <Chip
                              className="validation__chip"
                              size="small"
                              color={chipColor}
                              icon={<CheckCircleIcon />}
                              label={configJSON[rule]}
                              variant="outlined"
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>

                  <footer className="form__footer">
                    <Box className="terms__wrapper" mt={0.5}>
                      <Checkbox
                        size="small"
                        data-test-id="terms-accepted"
                        name="termsAccepted"
                        value={values.termsAccepted}
                        onChange={(e) => setFieldValue("termsAccepted", e.target.checked)}
                      />
                      <Typography variant="caption">
                        {configJSON.IAgreeWith}
                        <span data-test-id="terms-and-conditions" className="footer__link" onClick={() => this.redirectTo("TermsConditionsWeb")}>{configJSON.termsAndConditions}</span>
                        {configJSON.and}
                        <span data-test-id="privacy-policy" className="footer__link" onClick={() => this.redirectTo("PrivacyPolicyWeb")}>{configJSON.privacyPolicy}</span>
                      </Typography>
                    </Box>

                    {touched.termsAccepted && errors.termsAccepted && <Typography className="error__message">{errors.termsAccepted}</Typography>}

                    <Button
                      data-test-id="submit"
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      variant="contained"
                      fullWidth
                      sx={{ mt: { xs: 2, sm: 3 } }}
                    >
                      {configJSON.btnContinueLabel}
                    </Button>
                  </footer>
                </Form>
              )}
            </Formik>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Container)({
  flex: 1,
  maxWidth: "495px !important",
  overflowY: "auto",
  "& .form__wrapper": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 0,
  },
  "& .form__main__data": {
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .validation__chip__wrapper": {
      display: "flex",
      gap: "4px",
      flexWrap: "wrap",
      "& .validation__chip": {
        fontSize: "12px",
        borderColor: "#E2E8F0",
        fontWeight: 400,
      },
    },
  },
  "& .form__footer": {
    position: "sticky",
    bottom: 0,
    width: "100%",
    "& .terms__wrapper": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& .MuiCheckbox-root": {
        padding: 0,
      },
      "& .footer__link": {
        fontWeight: 800,
        color: "#2EAB65",
        cursor: "pointer",
      },
    },
  },
  "& .error__message": {
    fontSize: "12px",
    fontWeight: 400,
    color: "red",
    margin: "2px 3px 0",
  },
  "& .redirect__message": {
    color: "#2EAB65",
    textAlign: "end",
    cursor: "pointer",
    fontSize: "12px"
  },
  "& .react-tel-input": {
    "& .flag-dropdown ": {
      border: "solid 1px #E2E8F0",
      borderRadius: "8px",
      width: "62px",
      "& .selected-flag": {
        background: "#FFF",
        width: "100%",
        borderRadius: "8px",
        padding: "14px 13px",
        zoom: 1.25
      },
    },
    "& .form-control": {
      fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
      padding: "10px 12px 12px",
      lineHeight: "unset",
      height: "unset",
      width: "calc(100% - 82px)",
      marginLeft: "82px",
      border: "solid 1px #E2E8F0",
      borderRadius: "8px"
    }
  },
});
// Customizable Area End
